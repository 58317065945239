import {Wrapper} from "@googlemaps/react-wrapper";
import {useRef, useEffect, useState, useContext} from "react";
import {createRoot} from "react-dom/client";
import PropertyContext from "../../Context/PropertyContext";
import AuthContext from "../../Context/AuthContext";
import ajaxProperty from "../../util/remote/ajaxProperty";
import functions from "../../util/functions";

export default function PropertyLocation() {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_KEY}
      version="beta"
      libraries={["marker"]}>
      <MyMap />
    </Wrapper>
  );
}

const mapOptions = {
  mapId: process.env.REACT_APP_MAP_ID,
  zoom: 8,
  center: {lat: 1.4655479547610135, lng: 32.31979217652423},
  disableDefaultUI: true,
};

function MyMap() {
  const [map, setMap] = useState();
  const ref = useRef();

  useEffect(() => {
    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, []);

  return (
    <>
      <div ref={ref} style={{height: "100vh", width: "100%"}} />
      {map && <CMap map={map} />}
    </>
  );
}

function CMap({map}) {
  const {user} = useContext(AuthContext);

  // const {propertyList} = useContext(PropertyContext);
  const [secondPropertyList, setSecondPropertyList] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPropertyByLandlord();
  }, [
    user.landlord_id
      ? user.landlord_id.id
      : user.role?.role_name == "Landlord"
      ? user.id
      : "",
  ]);

  const getPropertyByLandlord = async () => {
    const server_response = await ajaxProperty.fetchPropertyList(
      functions.sessionGuard(),
      page
    );
    console.log(server_response.details.list);
    if (server_response.status === "OK") {
      setSecondPropertyList(server_response.details.list);
    }
  };

  return (
    <>
      {Array.isArray(secondPropertyList) &&
        secondPropertyList.map((item, key) => (
          <Marker
            key={key}
            map={map}
            position={{lat: item.lat * 1, lng: item.longitude * 1}}>
            <div id="pin-div" style={{position: "relative", padding: "0.5rem"}}>
              <svg
                fill="red"
                xmlns="http://www.w3.org/2000/svg"
                height="2.5em"
                viewBox="0 0 384 512">
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
              {/* <i class="fa fa-location-dot fa-3x" style={{color:'black'}} aria-hidden="true"></i> */}
              <p style={{color: "black"}}>{item.name}</p>
            </div>
          </Marker>
        ))}
    </>
  );
}

function Marker({map, children, position}) {
  const markerRef = useRef();
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
  }, [map, position, children]);
}
