import React, {useEffect, useState} from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxUnit from "../../util/remote/ajaxUnit";
import {Link} from "react-router-dom";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function ListUnits() {
  const [unitList, setUnitList] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  useEffect(() => {
    getUnits();
  }, [page]);

  const user = functions.sessionGuard();
  let data = {
    user_id: user,
    page: page,
  };
  const getUnits = async () => {
    const server_response = await ajaxUnit.fetchUnitList(data);
    if (server_response.status === "OK") {
      // setUnitList(server_response.details);
      setMeta(server_response.details.meta.list_of_pages);
      setUnitList(server_response.details.list);
    }
  };

  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };
  console.log(unitList);
  return (
    <AppContainer title="Units">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
          <div className="row">
            {Array.isArray(unitList) &&
              unitList.map((item, key) => (
                <div class="col-lg-4 col-md-4 col-xl-4">
                  <div
                    class="card card-aside custom-card"
                    style={{width: "100%", height: "40rem !important"}}>
                    {Array.isArray(item.images) &&
                      item.images.map((item2, key) => (
                        // console.log(item2)
                        <a
                          href="#"
                          class="card-aside-column  cover-image"
                          data-image-src={dictionary.apiHost + item2.photo}
                          style={{
                            background: `url(${
                              dictionary.apiHost + item2.photo
                            }) center center`,
                          }}></a>
                      ))}
                    <div class="card-body">
                      <h4>
                        <Link
                          to={`/properties/property-profile/${item.property.id}/unit-profile/${item.id}`}>
                          {item.name}
                        </Link>
                        {item.status === "0" ? (
                          <span
                            className="badge badge-pill badge-success"
                            style={{float: "right", fontSize: "12px"}}>
                            Vacant
                          </span>
                        ) : (
                          <span
                            className="badge badge-pill badge-danger
                            "
                            style={{float: "right", fontSize: "12px"}}>
                            Ocuupied
                          </span>
                        )}
                      </h4>
                      <div class="">{item.description}</div>
                      <div class="d-flex align-items-center pt-3 mt-auto">
                        <div style={{color: "#8760FB"}}>
                          <small class="d-block mb-1">
                            <i className="fa fa-money-bill"></i> Rent:{" "}
                            <span>UGX {item.rent_amount}</span>
                          </small>
                          <small class="d-block mb-1">
                            <i className="fa fa-bed"></i> Bedrooms:{" "}
                            <span>{item.bedrooms_no}</span>
                          </small>
                          <small class="d-block">
                            <i className="fa fa-shower"></i> Bathrooms:{" "}
                            <span>{item.baths_no}</span>
                          </small>
                        </div>
                        <div class="ml-auto text-muted">
                          {/* <a href="#" class="icon d-none d-md-inline-block ml-3">View Location</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12 align-items-center">
          <div className="pos-absolute " style={{left: "50%"}}>
            <button
              className="btn btn-dark"
              style={{borderRight: "1px solid yellow"}}
              onClick={setPreviousPageNumber}>
              <i className="fa fa-angle-left mr-2"></i> Prev
            </button>
            {Array.isArray(meta) &&
              meta.map((item) =>
                page === item ? (
                  <button
                    style={{borderRight: "1px solid yellow"}}
                    className="btn btn-primary">
                    {item}
                  </button>
                ) : (
                  <button
                    onClick={(e) => setPageNumber(e, item)}
                    style={{borderRight: "1px solid yellow"}}
                    className="btn btn-dark">
                    {item}
                  </button>
                )
              )}

            <button
              style={{borderRight: "1px solid yellow"}}
              className="btn btn-dark"
              onClick={setNextPageNumber}>
              Next<i className="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
