import React, {useState} from "react";
import AppContainer from "../Structure/AppContainer";
import {useEffect} from "react";
import ajaxUser from "../../util/remote/ajaxUser";
import {Link} from "react-router-dom";
import dictionary from "../../util/dictionary";

export default function ListLandlords() {
  const [landlordList, setLandlordList] = useState(false);

  useEffect(() => {
    getLandlord();
  }, []);

  const getLandlord = async () => {
    const server_response = await ajaxUser.fetchLandlords();
    if (server_response.status === "OK") {
      setLandlordList(server_response.details);
    }
  };
  // console.log(landlordList);
  return (
    <AppContainer title="Landlords">
      <div class="row">
        {!Array.isArray(landlordList) ? (
          <h6>No data</h6>
        ) : (
          landlordList.map((item, key) => (
            <div class="col-xl-4  col-xxl-4 col-lg-4 col-md-6">
              <div
                class="card card-aside custom-card"
                style={{height: "12rem"}}>
                {item.main_pic === "upload/" ? (
                  <a
                    href="#"
                    class="card-aside-column  cover-image"
                    data-image-src={dictionary.apiHost + "/img/avatar.png"}
                    style={{
                      background: `url(${
                        dictionary.apiHost + "/img/avatar.png"
                      }) center center`,
                    }}></a>
                ) : (
                  <a
                    href="#"
                    class="card-aside-column  cover-image"
                    data-image-src={dictionary.apiHost + item.main_pic}
                    style={{
                      background: `url(${
                        dictionary.apiHost + item.photo
                      }) center center`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}></a>
                )}
                <div class="card-body" style={{color: "white !important"}}>
                  <h5>
                    <Link>{item.first_name + " " + item.last_name}</Link>
                  </h5>
                  <div class="d-flex align-items-center pt-3 mt-auto">
                    <div style={{color: "#03C895"}}>
                      <small class="d-block mb-1">
                        <i className="fa fa-phone"></i> Contact:{" "}
                        <span style={{color: "white"}}>
                          {item.phone_number}
                        </span>
                      </small>
                      <small class="d-block">
                        <i className="fa fa-envelope"></i> Email:{" "}
                        <span style={{color: "white"}}>{item.email}</span>
                      </small>
                      <small>
                        <i className="fa fa-home"></i> Properties:{" "}
                        <span style={{color: "white"}}>
                          {item.property.total}
                        </span>
                      </small>
                    </div>
                    <div class="ml-auto text-muted">
                      {/* <a href="#" class="icon d-none d-md-inline-block ml-3">View Location</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </AppContainer>
  );
}
