import {ajax} from "jquery";
import React, {useContext, useEffect, useState} from "react";
import CreateAnything from "../CreateAnything/CreateAnything";
import {Link} from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Alert from "../Common/Alert";

export default function PageHeader(props) {
  return (
    <>
      <div class="page-header">
        <div className="col-md-6">
          <h2 class="main-content-title tx-24 mg-b-5">{props.title}</h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {props.title}
            </li>
            <li style={{float: "right"}}></li>
          </ol>
        </div>
        <div></div>
      </div>
    </>
  );
}
