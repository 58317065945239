import React, {useEffect, useState} from "react";
import ajaxUnit from "../../util/remote/ajaxUnit";
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UploadImage from "./UploadImage";
import useStateCallback from "../../util/customHooks/useStateCallback";
import UpdateImageModal from "./UpdateImageModal";
import dictionary from "../../util/dictionary";
export default function UnitSlider(props) {
  useEffect(() => {
    getUnitImage();
  }, [props.id]);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [modal, setModal] = useStateCallback(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const toggleModal = (e, item) => {
    setModalShow(true);
    setModalImage(dictionary.apiHost + item.photo);
  };

  const [unitImage, setUnitImage] = useState(false);

  const getUnitImage = async () => {
    const server_response = await ajaxUnit.fetchUnitImage(props.id);

    if (server_response.status === "OK") {
      //store results
      setUnitImage(server_response.details);
    } else {
      //communicate error
      setUnitImage("404");
    }
  };
  const handleModalUploadImage = () => {
    setModal(false, () =>
      setModal(<UploadImage id={props.id} isOpen={true} />)
    );
  };

  const handleModalUpdateImage = (item) => {
    setModal(false, () =>
      setModal(
        <UpdateImageModal
          getUnitImage={getUnitImage}
          description={item.description}
          id={item.id}
          unit_id={props.id}
          isOpen={true}
        />
      )
    );
  };

  return (
    <>
      {modal}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}>
        {/* <Modal.Title style={{opacity:'-0.1'}}>
								<Button onClick={() => setModalShow(false)}>Close</Button>

									</Modal.Title> */}
        <Modal.Body
          style={{
            backgroundImage: `url(${modalImage})`,
            backgroundRepeat: "no-repeat",
            height: "500px",
            backgroundColor: "white",
            backgroundPosition: "center",
            backgroundSize: "100% 100%",
            position: "relative",
          }}></Modal.Body>
      </Modal>
      {/* <div className="container" id="sliderr" > */}
      <Slider {...settings}>
        {!Array.isArray(unitImage) && (
          <div className="unit-pic">
            <img
              alt="Responsive image"
              class="img-thumbnail  w-100"
              style={{height: "400px", width: "400px"}}
              src={dictionary.apiHost + "img/house.png"}
            />
            <div class="middle">
              <div class="text">
                <button
                  style={{color: "black", fontSize: "15px"}}
                  className="btn btn-primary btn-sm mr-3 text-center align-items-center align-content-center"
                  onClick={handleModalUploadImage}>
                  <i
                    className="fa fa-upload"
                    style={{color: "black", fontSize: "18px"}}></i>{" "}
                  Upload Unit Images
                </button>
                {/* <UploadImage /> */}
              </div>
            </div>
          </div>
        )}

        {Array.isArray(unitImage) &&
          unitImage.map((item, key) => (
            <div className="unit-pic">
              <img
                onClick={(e) => toggleModal(e, item)}
                alt="Responsive image"
                class="img-thumbnail  w-100"
                style={{height: "400px", width: "400px"}}
                src={dictionary.apiHost + item.photo}
              />
              <div class="middle">
                <div class="text">
                  <button
                    style={{opacity: "0.2 !important"}}
                    className="btn btn-primary btn-sm mr-3 text-center align-items-center align-content-center">
                    <i
                      onClick={(e) => toggleModal(e, item)}
                      style={{color: "black", fontSize: "18px"}}
                      className="fa fa-eye"></i>
                  </button>

                  <button
                    onClick={() => handleModalUpdateImage(item)}
                    className="btn btn-primary btn-sm mr-3 text-center align-items-center align-content-center">
                    <i
                      className="fa fa-edit"
                      style={{color: "black", fontSize: "18px"}}></i>
                  </button>
                  <button
                    className="btn btn-primary btn-sm mr-3 text-center align-items-center align-content-center"
                    onClick={handleModalUploadImage}>
                    <i
                      className="fa fa-upload"
                      style={{color: "black", fontSize: "18px"}}></i>
                  </button>
                  {/* <UploadImage /> */}
                </div>
              </div>
            </div>
          ))}
      </Slider>{" "}
      <i class="task-icon bg-secondary"></i>
      {/* </div> */}
    </>
  );
}
