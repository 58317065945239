import React, {useEffect, useState} from "react";

import ajaxUser from "../../util/remote/ajaxUser";
import Card from "../Common/Card";

export default function LandlordsCard() {
  useEffect(() => {
    landlordCount();
  }, []);

  const [countLandlord, setCountLandlord] = useState(false);

  const landlordCount = async () => {
    const server_response = await ajaxUser.countLandlord();
    if (server_response.status === "OK") {
      setCountLandlord(server_response.details);
    }
  };
  return (
    <Card
      title="Total Landlords"
      value={countLandlord ? countLandlord.total_p : "..."}
      icon="fa fa-user-tie"
      color="#8760FB"
      link="#"
    />
  );
}
