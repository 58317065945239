import React, {useEffect, useState} from "react";
import {Toaster, toast} from "react-hot-toast";
import Select from "react-select";

import AppContainer from "../Structure/AppContainer";
import Loader from "../Common/Loader";
import SystemModal from "../Common/SystemModal";
import ajaxUser from "../../util/remote/ajaxUser";
import functions from "../../util/functions";
import ajaxPropertyManager from "../../util/remote/ajaxPropertyManager";

const UpdatePropertyManager = (props) => {
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState(null);
  const user = functions.sessionGuard();
  const property_id = props.property_id;

  useEffect(() => {
    getManagers();
  }, []);

  const getManagers = async () => {
    try {
      const serverResponse = await ajaxUser.fetchManagers(user);
      if (serverResponse.status === "OK") {
        setManagers(serverResponse.details);
      }
    } catch (error) {
      console.error("Error fetching managers:", error);
    }
  };

  const UpdatePropertyManager = async () => {
    if (property_id && manager) {
      try {
        setLoading(true);
        const data = {
          property_id: property_id,
          manager_id: manager,
        };
        const server_response = await ajaxPropertyManager.UpdatePropertyManager(
          data
        );
        if (server_response.status === "OK") {
          toast.success(server_response.message);
        }
      } catch (error) {
        console.error("Error updating property manager:", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  const handleAdd = (e) => {
    e.preventDefault();
    UpdatePropertyManager();
  };

  const RenderFooter = (controls) => {
    return (
      <>
        <button className="btn btn-success" onClick={UpdatePropertyManager}>
          Save
        </button>
        <button className="btn btn-secondary" onClick={controls.close}>
          Close
        </button>
      </>
    );
  };

  return (
    <>
      <SystemModal
        id="model-new-region"
        size="md"
        footer={RenderFooter}
        title={`Assign manager to ${props.property_name}`}>
        <div>
          <Toaster />
        </div>

        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-4">Update Property Manager</h6>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="">
                  {loading && <Loader />}
                  <form onSubmit={handleAdd}>
                    <div className="form-group">
                      <div className="col-md-12 mt-1 mb-3">
                        <label htmlFor="">
                          Name of Manager
                          <span className="tx-danger">*</span>
                        </label>
                        <Select
                          onChange={(selectedOption) =>
                            setManager(selectedOption.id)
                          }
                          getOptionLabel={(option) =>
                            `${option.first_name + " " + option.last_name}`
                          }
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={managers}
                          styles={customStyles}
                          value={managers.find((value) => value.id === manager)}
                        />
                      </div>
                    </div>
                    <button
                      disabled={loading}
                      className="btn ripple btn-main-primary btn-block">
                      {loading
                        ? "Updating Property Manager...."
                        : "Update Property Manager"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SystemModal>
    </>
  );
};

export default UpdatePropertyManager;
