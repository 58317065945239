import React, {useState } from 'react'
import ModalComponent from '../Common/Modal';
import ajaxProperty from '../../util/remote/ajaxProperty';
import Alert from '../Common/Alert';
import Loader from '../Common/Loader';

export default function CreatePropertyImage(props) {
    
 const [loading,setLoading] = useState(false)
 const [info,setInfo] = useState("")

    const [photo,setPhoto] = useState("")
    const [photoName, setPhotoName] = useState("Choose Picture...")
    const [description,setDescription] = useState("")
    
    

   

  
      const addImage = async(e) =>{
		e.preventDefault()
		if(props.property_id>0 || photo.length>0 || description.length>0){
			const server_response = await ajaxProperty.uploadPropertyImage(props.property_id,photo,description);
			// console.log(server_response)
			if(server_response.status==="OK"){
				setPhoto('')
				setDescription('')
				setInfo(<Alert type="success" message={server_response.message}/>) 
                setPhotoName("Choose picture...")
				
			}
			else{
				setInfo(<Alert type="error" message={server_response.message}/>)
	
			}
		}
		else{
			setInfo(<Alert type="error" message="Complete all fields and try again"/>)

		}
	}

	const changeImage = (e) =>{
		e.preventDefault()
        setPhotoName(e.target.value)
		let files = e.target.files;
		let reader = new FileReader();
		console.log(files.length)
		reader.readAsDataURL(files[0])
	
		reader.onload = (e) => {
			const newItem = { file: e.target.result };
	
			setPhoto(e.target.result)
			
			
		}
		
		
	}
    return (
        <ModalComponent 
        color={props.color}
        name="Upload Property Image"
        title="New Property Image Upload"
        id={props.id} 
        icon="fa fa-upload"
        size="sm"
        style={{float:'right'}}
        

        >
                                        {loading ? <Loader /> :info}

            <form onSubmit={(e)=>addImage(e)} method="post">
                    <div className="mb-3">
                        <label htmlFor="">Property Image:<span class="tx-danger">*</span></label>
                        <div class="row row-sm">
										<div class="col-sm-12 col-md-12 col-lg-12">
											<div class="custom-file">
												<input class="custom-file-input" onChange={(e)=>changeImage(e)} id="customFile" type="file"/> <label class="custom-file-label" for="customFile">{photoName}</label>
											</div>
										</div>
									</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="">Image Description:<span class="tx-danger">*</span></label>
                        <textarea class="form-control" value={description} onChange={(e)=>setDescription(e.target.value)} aria-label="With textarea"></textarea>
                    </div>
                   
                    
                    <div className="mb-3">
                        <input type="submit" style={{float:"right"}} className="btn btn-success" value="Upload"/>
                    </div>
                </form>

        </ModalComponent>
    )
}
