import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async fetchStepsList(data) {
    //   let data ={
    //     'page':page
    //   }
        let response = await apiCall("steps/list", data)
        return response;
       
      },

      async fetchMaterialCategoryList(data) {
 
          let response = await apiCall("material/category/create", data)
          return response;
         
        },

        async fetchMaterialList(data) {

              let response = await apiCall("material/list", data)
              return response;
             
            },

      async fetchEquipmentCategoryList(data) {

        let response = await apiCall("equipment/category/list", data)
        return response;
        
      },

  async fetchEquipmentList(data) {
  
        let response = await apiCall("equipment/list", data)
        return response;
       
      },

      // async fetchMaterialCategory(data) {
 
      //     let response = await apiCall("material/category/create", data)
      //     return response;
         
      //   },

      //   async fetchMaterial(data) {

      //         let response = await apiCall("material/list", data)
      //         return response;
             
      //       },

        

//       <div className='row'>
//       <div className='col-md-9'>
//       <div className='card custom-card'>
//   {Array.isArray(steps) && steps.map((item)=>
//   <>
//   <h4 className='card-header'>{item.name}</h4>
//   <div className='card-body' style={{borderBottom:'1px solid  red'}}>
//     <button onClick={setMaterialModal} className='btn btn-primary'>Add material</button>
//   </div>
//   </>
//   )}


// {/* <div className='card-body' style={{borderBottom:'1px solid  red'}}>
// Hello
// </div> */}

// </div>
//       </div>
//     </div>




}