import React, {useEffect, useState} from "react";
import SystemModal from "../Common/SystemModal";
import ajaxUser from "../../util/remote/ajaxUser";
import TextInput from "../Common/TextInput";
import {Link, useParams} from "react-router-dom";
import ajaxPropertyManager from "../../util/remote/ajaxPropertyManager";
import {toast} from "react-hot-toast";
import functions from "../../util/functions";

export default function NewPropertyManager(props) {
  const {id} = useParams();
  const [managerListing, setManagerListing] = useState(false);
  const [manager, setManager] = useState("");
  const user = functions.sessionGuard();
  const getManagers = async () => {
    const server_response = await ajaxUser.fetchManagers(user);

    if (server_response.status === "OK") {
      setManagerListing(server_response.details);
    }
  };

  useEffect(() => {
    getManagers();
  }, []);

  const addPropertyManager = async () => {
    if (id > 0 && manager > 0) {
      const server_response = await ajaxPropertyManager.addPropertyManager(
        id,
        manager
      );
      if (server_response.status === "OK") {
        toast.success(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    return (
      <>
        <button className="btn btn-success" onClick={addPropertyManager}>
          Save
        </button>
        <button className="btn btn-secondary" onClick={controls.close}>
          Close
        </button>
      </>
    );
  };

  const RenderManagers = () => {
    const optionManagers = [
      {
        label: (
          <Link
            className="btn btn-success"
            to="/manager/create"
            style={{color: "black", width: "100%"}}
            target="_blank">
            Add New Manager
          </Link>
        ),
        value: "button",
      },
    ];

    //   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
    // </div>

    if (Array.isArray(managerListing)) {
      managerListing.map((managerInfo, index) =>
        optionManagers.push({
          label: managerInfo.first_name + " " + managerInfo.last_name,
          value: managerInfo.id,
        })
      );
    }
    console.log(optionManagers);
    return (
      <TextInput
        // label="JHEFJWEJK"
        type="select"
        value={
          Array.isArray(optionManagers) &&
          optionManagers.find((item) => item.value === manager)
        }
        placeholder="Select manager...."
        options={optionManagers}
        onChange={(e) => setManager(e.value)}
      />
    );
  };

  const addManager = () => {};

  return (
    <SystemModal
      id="model-new-region"
      size="md"
      footer={RenderFooter}
      title={`Assign manager to ${props.propertyDetails}`}>
      <form onSubmit={addManager}>
        <RenderManagers />
      </form>
    </SystemModal>
  );
}
