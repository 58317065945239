import React from "react";
import {Link} from "react-router-dom";
import CountUp from "react-countup";

const Card = (props) => {
  return (
    <div class="card custom-card">
      <div class="card-body">
        <div>
          <h6>
            {props.title}
            {/* <span style={{float:'right'}} class="badge badge-pill badge-success mr-2">{props.vacant}</span>
                    <span style={{float:'right'}}  class="badge badge-pill badge-danger">{props.occupied}</span> */}
          </h6>

          <i
            style={{float: "right", fontSize: "xx-large", color: props.color}}
            class={`${props.icon}`}></i>

          <h6>
            <span class="fs-30 mr-2">{props.value}</span>
          </h6>

          <br />
          {/* <span class="badge badge-pill badge-success mr-2">{props.vacant}</span>
                    <span class="badge badge-pill badge-danger">{props.occupied}</span> */}
          {/* <span class="text-muted">Estimated daily unique views  through conversions per visitor on the ads.</span> */}
        </div>
        {/* <div ><i className={`${props.icon} mr-1 dash-icon `}></i></div>
									<p class="mb-1 tx-inverse">{props.title}</p>
									<div>
										<h4 class="dash-25 mb-2"> <CountUp
                      start={0}
                      end={props.value}
                    /></h4>
									</div>
									 <div class="expansion-value d-flex tx-inverse">
										{/* <strong>  {props.value1}</strong> */}
        {/* <strong class="ml-auto"> {props.value2}</strong>
									</div> 
									<div class="progress">
                    {props.progress} */}
        {/* <div aria-valuemax="100" aria-valuemin="0" aria-valuenow={props.percentage} class={`progress-bar progress-bar-xs wd-${props.progress}p`} role="progressbar"></div> */}
        {/* </div>
                  <div class="mb-2">
												<span class="peity-donut" data-peity='{ "fill": ["#eb6f33", "#77778e33"], "innerRadius": 14, "radius": 20 }'>4/7</span>
											</div>
                  <div class="expansion-label d-flex text-muted mt-1"> */}

        {/* <span class="ml-auto">{props.bottomValue} </span>
									</div> */}
      </div>
    </div>
  );
};

export default Card;
