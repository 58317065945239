import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchTenantList(data) {
    let response = await apiCall("tenant/list", data);
    return response;
  },
  async fetchTenantListAll(data) {
    let response = await apiCall("tenant/list_all", data);
    return response;
  },
  async fetchRecentTenantList(data) {
    let response = await apiCall("tenant/list/recent", data);
    return response;
  },

  async createTenant(name, DOB, email, nin, phone_number, picture, manager_id) {
    let data = {
      name: name,
      DOB: DOB,
      email: email,
      nin: nin,
      phone_number: phone_number,
      picture: picture,
      manager_id: manager_id,
    };

    let response = await apiCall("tenant/create", data);
    return response;
  },
  async fetchTenantNumber(manager_id) {
    let data = {
      manager_id: manager_id,
    };
    let response = await apiCall("tenant/all/count", data);
    return response;
  },

  async fetchTenantProfile(tenant_id) {
    let data = {
      tenant_id: tenant_id,
    };
    let response = await apiCall("tenant/getinfo", data);

    return response;
  },

  async updateTenant(
    tenant_id,
    name,
    DOB,
    email,
    nin,
    phone_number,
    manager_id
  ) {
    let data = {
      tenant_id: tenant_id,
      name: name,
      DOB: DOB,
      email: email,
      nin: nin,
      phone_number: phone_number,
      manager_id: manager_id,
    };

    let response = await apiCall("tenant/update", data);
    return response;
  },

  async updateTenantImage(tenant_id, picture) {
    let data = {
      tenant_id: tenant_id,
      picture: picture,
    };

    let response = await apiCall("tenant/update/picture", data);
    return response;
  },

  async searchTenantDate(startDate, endDate) {
    let data = {
      from_date: startDate,
      to_date: endDate,
    };
    let response = await apiCall("tenant/search/date", data);

    return response;
  },
};
