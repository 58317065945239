import React, {useEffect, useState} from "react";
import {Toaster, toast} from "react-hot-toast";
import Select from "react-select";
import ajaxUnit from "../../util/remote/ajaxUnit";
import ajaxProperty from "../../util/remote/ajaxProperty";
import functions from "../../util/functions";
import AppContainer from "../Structure/AppContainer";

function CreateMultiUnits() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [bedroomsNo, setBedroomsNo] = useState("");
  const [kitchenNo, setKitchenNo] = useState("");
  const [bathsNo, setBathsNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const id = functions.sessionGuard();
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    fetchLandlordProperties();
  }, []);

  const fetchLandlordProperties = async () => {
    try {
      const data = {landlord_id: id};
      const response = await ajaxProperty.fetchAllLandloadProperties(data);
      if (response.status === "OK") {
        setProperties(response.details);
      } else {
        setProperties([]);
        toast.error("Error fetching properties");
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      toast.error("Error fetching properties");
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      name &&
      description &&
      rentAmount &&
      propertyId &&
      bedroomsNo &&
      kitchenNo &&
      bathsNo
    ) {
      setLoading(true);
      const data = {
        name,
        description,
        rent_amount: rentAmount,
        property_id: propertyId,
        bedrooms_no: bedroomsNo,
        baths_no: bathsNo,
        kitchen_no: kitchenNo,
      };
      try {
        const response = await ajaxUnit.createUnit(data);
        if (response.status === "OK") {
          toast.success(response.message);
          setName("");
          setDescription("");
          setRentAmount("");
          setBedroomsNo("");
          setKitchenNo("");
          setBathsNo("");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error adding unit:", error);
        toast.error("Error adding unit");
      }
      setLoading(false);
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (provided) => ({...provided, color: "#fff"}),
  };

  return (
    <div>
      {" "}
      <AppContainer title="Create Multi New Units">
        <div className="card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-3">Add New Unit</h6>
            </div>
            <Toaster />
            <form onSubmit={handleAdd}>
              <div className="form-group">
                <div className="row row-sm">
                  <div className="col-sm-12 mt-2">
                    <label htmlFor="property">
                      Property:<span className="tx-danger">*</span>
                    </label>
                    <Select
                      onChange={(selectedOption) =>
                        setPropertyId(selectedOption.id)
                      }
                      options={properties}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={customStyles}
                      isSearchable
                    />
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label htmlFor="unitName">
                      Unit Name:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label htmlFor="unitDescription">
                      Unit Description:
                      <span className="tx-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      aria-label="Unit Description"></textarea>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row row-sm">
                  <div className="col-sm-6 mt-2">
                    <label htmlFor="rentAmount">
                      Rent Amount:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={rentAmount}
                      min={0}
                      onChange={(e) => setRentAmount(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 mt-2">
                    <label htmlFor="bedroomsNo">
                      Number of Bedrooms:
                      <span className="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={bedroomsNo}
                      min={0}
                      onChange={(e) => setBedroomsNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 mt-2">
                    <label htmlFor="kitchenNo">
                      Number of Kitchens:
                      <span className="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={kitchenNo}
                      min={0}
                      onChange={(e) => setKitchenNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 mt-2">
                    <label htmlFor="bathsNo">
                      Number of Baths:
                      <span className="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={bathsNo}
                      min={0}
                      onChange={(e) => setBathsNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <button
                disabled={loading}
                type="submit"
                className="btn ripple btn-main-primary btn-block">
                {loading ? "Creating..." : "Create Unit"}
              </button>
            </form>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default CreateMultiUnits;
