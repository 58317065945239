import React from 'react'

export default function Alert(props) {
  return (
    <div className={`alert alert-${props.type}`} role="alert" style={props.style}>
	<span class="alert-inner--icon"><i class={`fe ${props.icon}`}></i></span> 
	<span class="alert-inner--text"><strong>{props.message}</strong></span>
</div>
  )
}
