import React, {useState} from "react";
import TextInput from "../Common/TextInput";
import SystemModal from "../Common/SystemModal";
import ActivityLoader from "../Common/ActivityLoader";
import {Toaster, toast} from "react-hot-toast";
import ajaxUnit from "../../util/remote/ajaxUnit";
import ajaxTenantUnit from "../../util/remote/ajaxTenantUnit";

export default function UpdateTenancy(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitImage, setUnitImage] = useState("");
  const [description, setDescription] = useState(props.description);
  const [location, setLocation] = useState("");

  const updateTenancy = async () => {
    // console.log(props.unit_id);
    if (props.id > 0) {
      const server_response = await ajaxTenantUnit.updateTenancy(
        props.id,
        props.unit_id,
        0
      );
      // console.log(server_response)
      if (server_response.details) {
        props.getUnitHistory();
        toast.success(server_response.message);
      }
    } else {
      toast.success("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <ActivityLoader />;
    } else {
      return (
        <>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={updateTenancy}>
            Yes
          </button>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            No
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <SystemModal
        title={`Unit ${props.unit} tenancy`}
        id="model-new-property"
        size="md"
        footer={RenderFooter}>
        Are you sure you want to remove <b>{props.name}</b> from unit{" "}
        {props.unit} tenancy?
        {/* {RenderLocation()} */}
      </SystemModal>
    </>
  );
}
