import React, {useContext, useEffect, useState} from "react";
import functions from "../util/functions";
import ajaxTenant from "../util/remote/ajaxTenant";
import AuthContext from "./AuthContext";

const TenantContext = React.createContext();

export const TenantConsumer = TenantContext.Consumer;

export const TenantProvider = (props) => {
  //  const [userId, setUserId] = useState();
  const [tenantLists, setTenantList] = useState(false);

  const {user, userId} = useContext(AuthContext);
  const [data, setData] = useState({page: "1"});
  const id = functions.sessionGuard();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  useEffect(() => {
    getTenants();
  }, [page]);

  const getTenants = async () => {
    let data = {
      user_id: id,
      page: page,
    };
    const server_response = await ajaxTenant.fetchTenantList(data);

    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setTenantList(server_response.details.list);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  return (
    <TenantContext.Provider
      value={{
        tenantLists,
        getTenants,
        // setData,
        page,
        setPage,
        meta,
        setMeta,
      }}>
      {props.children}
    </TenantContext.Provider>
  );
};

export default TenantContext;
