import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxProperty from "../../util/remote/ajaxProperty";
import ajaxUnit from "../../util/remote/ajaxUnit";
import TenantContext from "../../Context/TenantContext";
import Select from "react-select";
import ajaxTenantUnit from "../../util/remote/ajaxTenantUnit";
import {Toaster, toast} from "react-hot-toast";
import functions from "../../util/functions";
import ajaxTenant from "../../util/remote/ajaxTenant";

export default function AssignUnit() {
  // option sets
  const [propertyList, setPropertyList] = useState("");
  const [unitList, setUnitList] = useState("");
  const [tenantLists, setTenantList] = useState("");

  // form data states
  const [tenant, setTenant] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [billing_date, setBillingDate] = useState("");
  const [agreed_amount, setAgreedAmount] = useState("");
  const [page, setPage] = useState(1);

  const id = functions.sessionGuard();

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = async () => {
    let data = {
      user_id: id,
    };
    const server_response = await ajaxTenant.fetchTenantListAll(data);
    if (server_response.status === "OK") {
      setTenantList(server_response.details);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  useEffect(() => {
    getPropertyList();
  }, [id, page]);

  useEffect(() => {
    getUnits();
  }, [propertyId]);

  const getPropertyList = async () => {
    const server_response = await ajaxProperty.fetchPropertyList(id, page);
    if (server_response.status === "OK") {
      setPropertyList(server_response.details.list);
    }
  };

  const getUnits = async () => {
    let data = {
      property_id: propertyId,
    };
    const server_response = await ajaxUnit.fetchAllPropperty_units(data);
    if (server_response.status === "OK") {
      setUnitList(server_response.details);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (unitId > 0 && agreed_amount.length > 0 && tenant > 0) {
      const server_response = await ajaxTenantUnit.createTenantUnit(
        tenant,
        unitId,
        agreed_amount,
        billing_date
      );

      if (server_response.status === "OK") {
        window.location.reload();
        setTenant("");
        setAgreedAmount("");
        setBillingDate("");
        setUnitId("");
        setPropertyId("");
        setTenant("");
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    }
  };

  return (
    <AppContainer title="Assign Tenant to unit">
      <Toaster />
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleAdd}>
            <div className="form-group">
              <div className="mb-3">
                <label htmlFor="">
                  Select Property:<span class="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setPropertyId(e.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  styles={customStyles}
                  options={Array.isArray(propertyList) ? propertyList : []}
                  value={
                    Array.isArray(propertyList) &&
                    propertyList.find((value) => value.id === propertyId)
                  }
                />
              </div>
              {propertyId === "" ? (
                ""
              ) : (
                <div className="mb-3">
                  <label htmlFor="">
                    Select Unit:<span class="tx-danger">*</span>
                  </label>

                  <Select
                    onChange={(e) => setUnitId(e.id)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    styles={customStyles}
                    options={Array.isArray(unitList) ? unitList : []}
                    value={
                      Array.isArray(unitList) &&
                      unitList.find((value) => value.id === unitId)
                    }
                  />

                  {/* } */}
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="">
                  Select Tenant:<span class="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setTenant(e.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  styles={customStyles}
                  options={Array.isArray(tenantLists) ? tenantLists : []}
                  value={
                    Array.isArray(tenantLists) &&
                    tenantLists.find((value) => value.id === tenant)
                  }
                />
              </div>

              <div className="mb-3">
                <label htmlFor="">
                  Agreed Amount:<span class="tx-danger">*</span>
                </label>
                <input
                  type="number"
                  value={agreed_amount}
                  onChange={(e) => setAgreedAmount(e.target.value)}
                  className="form-control"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="">
                  Billing Date:<span class="tx-danger">*</span>
                </label>
                <input
                  value={billing_date}
                  onChange={(e) => setBillingDate(e.target.value)}
                  class="form-control"
                  required=""
                  type="date"
                />
              </div>
            </div>

            <button className="btn btn-success" type="submit">
              Assign Unit
            </button>
          </form>
        </div>
      </div>
    </AppContainer>
  );
}
