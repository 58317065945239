import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalComponent(props) {
  

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
      <Button variant={`btn ${props.color}  mr-2 ml-2`} style={props.style} id={props.id} size={props.size} onClick={handleShow}>
        <i onClick={props.func} className={`${props.icon} mr-1`}></i> {props.name}
      </Button>

      <Modal show={show} onHide={handleClose}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {props.children}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default ModalComponent;