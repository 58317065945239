import React from "react";
import AppContainer from "../Structure/AppContainer";
import AddUnit from "./AddUnit";

export default function CreateUnit() {
  return (
    <AppContainer title="Create New Unit">
      <div className="card">
        <div className="card-body">
          <AddUnit />
        </div>
      </div>
    </AppContainer>
  );
}
