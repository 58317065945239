import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchUnitList(data) {
    let response = await apiCall("unit/list", data);

    return response;
  },
  async fetchUnitListUnOccupied(data) {
    let response = await apiCall("unit/list/unoccupied", data);

    return response;
  },
  async fetchUnitListOccupied(data) {
    let response = await apiCall("unit/list/occupied", data);

    return response;
  },
  async fetchAllPropperty_units(data) {
    let response = await apiCall("unit/list/property", data);

    return response;
  },
  async fetchTenantsUnits(data) {
    let response = await apiCall("tenant/unit/list", data);

    return response;
  },
  async createUnit(data) {
    let response = await apiCall("unit/create", data);
    return response;
  },
  async updateUnit(
    unit_id,
    name,
    description,
    rent_amount,
    bedrooms_no,
    baths_no,
    kitchen_no
  ) {
    let data = {
      unit_id: unit_id,
      name: name,
      description: description,
      rent_amount: rent_amount,
      bedrooms_no: bedrooms_no,
      baths_no: baths_no,
      kitchen_no: kitchen_no,
    };

    let response = await apiCall("unit/update", data);
    return response;
  },
  async fetchUnitProfile(unit_id) {
    let data = {
      id: unit_id,
    };
    let response = await apiCall("unit/profile", data);

    return response;
  },
  async fetchUnitImage(unit_id) {
    let data = {
      unit_id: unit_id,
    };
    let response = await apiCall("unit/image/list", data);

    return response;
  },
  async uploadUnitImage(unit_id, photo, description) {
    let data = {
      unit_id: unit_id,
      photo: photo,
      description: description,
    };
    let response = await apiCall("unit/image/create", data);

    return response;
  },

  async updateUnitImage(unit_image_id, unit_id, photo, description) {
    let data = {
      unit_image_id: unit_image_id,
      unit_id: unit_id,
      photo: photo,
      description: description,
    };
    let response = await apiCall("unit/image/update", data);

    return response;
  },
  async countUnit(data) {
    let response = await apiCall("unit/count/all", data);
    return response;
  },
  async unitOccupancy(data) {
    let response = await apiCall("unit/occupancy", data);
    return response;
  },
  async unitVacant(data) {
    let response = await apiCall("unit/count/vacant", data);
    return response;
  },
  async unitOccupied(data) {
    let response = await apiCall("unit/count/occupied", data);
    return response;
  },
};
