import React, {useEffect, useState} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import {useParams} from "react-router-dom";
import ajaxUnit from "../util/remote/ajaxUnit";
// import Carousel from "react-bootstrap/Carousel";
import {Toaster, toast} from "react-hot-toast";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import ajaxTenantUnit from "../util/remote/ajaxTenantUnit";

import UnitSlider from "../Components/Units/UnitSlider";
import TenantHistory from "../Components/Units/TenantHistory";

export default function UnitProfile() {
  const {id} = useParams();
  const [unitProfile, setUnitProfile] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [desc, setDesc] = useState("");

  const unitid = id;
  const [unitID, setUnitID] = useState("");
  const [rent_amount, setRentAmount] = useState("");
  const [bedrooms_no, setBedroomsNo] = useState("");
  const [kitchen_no, setKitchenNo] = useState("");
  const [baths_no, setBathsNo] = useState("");
  const [status, setStatus] = useState("");
  const [v, setV] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    getUnitProfile();
    getUnitPastTenants();
  }, [id]);

  const [unitPastTenants, setUnitPastTenants] = useState(false);

  const getUnitPastTenants = async () => {
    const server_response = await ajaxTenantUnit.fetchPastTenants(id);

    if (server_response.status === "OK") {
      //store results
      setUnitPastTenants(server_response.details);
    } else {
      //communicate error
      setUnitPastTenants("404");
    }
  };

  const modal = (e) => {
    e.preventDefault();
    const mod = document.getElementById("modaldemo8");
    console.log(mod.classList);
    mod.classList.add("effect-slide-in-right");
    mod.classList.add("show");
    // mod
    mod.setAttribute("style", "display:block");
    console.log(mod);

    // var effect = $(this).attr('data-effect');
    // $('#modaldemo8').addClass(effect);
  };

  // showing modal with effect
  // $('.modal-effect').on('click', function(e) {
  // 	e.preventDefault();
  // 	var effect = $(this).attr('data-effect');
  // 	$('#modaldemo8').addClass(effect);
  // });

  // hide modal with effect
  // $('#modaldemo8').on('hidden.bs.modal', function(e) {
  // 	$(this).removeClass(function(index, className) {
  // 		return (className.match(/(^|\s)effect-\S+/g) || []).join(' ');
  // 	});
  // });
  const setUnitUpdate = (e, item) => {
    handleShow1();

    setUnitID(unitProfile.id);
    setName(unitProfile.name);
    setDescription(unitProfile.description);
    setRentAmount(unitProfile.rent_amount);
    setBathsNo(unitProfile.baths_no);
    setBedroomsNo(unitProfile.bedrooms_no);
    setKitchenNo(unitProfile.kitchen_no);
  };

  const handleUnitUpdate = async (e) => {
    e.preventDefault();

    if (
      unitID > 0 ||
      name.length > 0 ||
      description.length > 0 ||
      rent_amount > 0 ||
      bedrooms_no > 0 ||
      kitchen_no > 0 ||
      baths_no > 0
    ) {
      setLoading2(true);

      const server_response = await ajaxUnit.updateUnit(
        unitID,
        name,
        description,
        rent_amount,
        bedrooms_no,
        baths_no,
        kitchen_no
      );

      setTimeout(() => setLoading2(false), 1000);
      if (server_response.status === "OK") {
        setUnitID("");
        setName("");
        setDescription("");
        setRentAmount("");
        setBedroomsNo("");
        setBathsNo("");
        setKitchenNo("");
        getUnitProfile(id);
        toast.success(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const getUnitProfile = async () => {
    const server_response = await ajaxUnit.fetchUnitProfile(id);

    if (server_response.status === "OK") {
      //store results
      setUnitProfile(server_response.details);
    } else {
      //communicate error
      setUnitProfile("404");
    }
  };

  const nextImage = (e) => {
    console.log(
      Object.values(e.target.offsetParent.firstElementChild.children[0])
    );
    setV(e.target.offsetParent.firstElementChild.children);
    console.log(v);

    Array.isArray(v) &&
      v.map((item) =>
        item[0].classList.contains("active")
          ? console.log("Yes")
          : console.log("no")
      );
  };

  const prevImage = (e) => {
    console.log(e.target.previousSibling);
    console.log(e.target.previousSibling.firstElementChild);
    if (
      e.target.previousSibling.firstElementChild.previousElementSibling === null
    ) {
      console.log("none");
    }
  };

  const changeImage = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    console.log(files.length);
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setImage(e.target.result);
      console.log(e.target.result);
    };
    console.log(image);
  };
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const handleClose = () => setActive(false);
  const handleShow = () => setActive(true);
  const [loading2, setLoading2] = useState(false);
  const [active1, setActive1] = useState(false);
  const handleClose1 = () => setActive1(false);
  const handleShow1 = () => setActive1(true);

  return (
    <AppContainer title="Unit Profile">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="row">
        <div className="col-md-9">
          <div className="card custom-card">
            {/* <button className='mb-4' style={{position:'fixed',top:'0'}}>Edit</button> */}

            <UnitSlider id={id} />

            {active1 ? (
              <form
                className="p-3"
                action=""
                onSubmit={(e) => handleUnitUpdate(e)}
                method="post">
                <div className="row mt-4">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="" className="">
                      Unit Name:<span class="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="">
                      Unit Description:<span class="tx-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      defaultValue={description}
                      onChange={(e) => setDescription(e.target.value)}
                      aria-label="With textarea"></textarea>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="">
                      Rent amount:<span class="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      defaultValue={rent_amount}
                      min={0}
                      onChange={(e) => setRentAmount(e.target.value)}
                      className="form-control"></input>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="">
                      Number of Bedrooms:<span class="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      defaultValue={bedrooms_no}
                      min={0}
                      onChange={(e) => setBedroomsNo(e.target.value)}
                      className="form-control"></input>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="">
                      Number of Bathrooms:<span class="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      defaultValue={baths_no}
                      min={0}
                      onChange={(e) => setBathsNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="">
                      Number of Kitchens:<span class="tx-danger">*</span>
                    </label>
                    <input
                      type="number"
                      defaultValue={kitchen_no}
                      min={0}
                      onChange={(e) => setKitchenNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-12">
                    <button
                      style={{float: "right"}}
                      className="btn btn-secondary ml-3 mb-3"
                      onClick={handleClose1}>
                      Close
                    </button>
                    <button
                      type="submit"
                      style={{float: "right"}}
                      className="btn btn-success"
                      value="Update Profile">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className="card-body mt-1">
                <h4 className="card-title">
                  <span className="mr-2" style={{fontSize: "16px"}}>
                    <i className="fa fa-home"></i>{" "}
                    <strong>
                      <b> Unit {unitProfile.name} </b>
                    </strong>
                  </span>
                  {unitProfile.status * 1 === 0 && (
                    <span class="badge badge-pill badge-success">Vacant</span>
                  )}
                  {unitProfile.status * 1 === 1 && (
                    <span class="badge badge-pill badge-danger">Occupied</span>
                  )}
                  <button
                    style={{float: "right"}}
                    class="btn ripple btn-outline-warning btn-rounded"
                    onClick={setUnitUpdate}>
                    <i className="fa fa-pen"></i> Edit
                  </button>
                </h4>
                <h5 className="card-title">
                  <strong>Description</strong>
                </h5>

                <p className="tx-gray-500">{unitProfile.description}</p>

                <h5 className="card-title">
                  <strong>Unit Details</strong>
                </h5>

                <span className="ml-2 mr-2">
                  <i className="fa fa-bed"></i> {unitProfile.bedrooms_no}{" "}
                  Bedroom(s)
                </span>
                <span
                  style={{borderRight: "1px solid #fff", height: "500px"}}
                  class="vr"></span>

                <span className="ml-2 mr-2">
                  <i className="fa fa-bath"></i> {unitProfile.baths_no}{" "}
                  Bathroom(s)
                </span>

                <span
                  style={{borderRight: "1px solid #fff", height: "500px"}}
                  class="vr"></span>

                <span className="ml-2 mr-2">
                  <i className="fa fa-utensils"></i> {unitProfile.kitchen_no}{" "}
                  Kitchen(s)
                </span>

                <span
                  style={{borderRight: "1px solid #fff", height: "500px"}}
                  class="vr"></span>

                <span className=" ml-2 mr-2">
                  <i className="fas fa-dollar-sign"></i>{" "}
                  {unitProfile.rent_amount} UGX
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <TenantHistory
            unit_id={id}
            getUnitHistory={getUnitPastTenants}
            pastTenants={unitPastTenants}
            unitProfile={unitProfile}
            unit={unitProfile.name}
            fetchUnitProfile={getUnitProfile}
          />
        </div>
      </div>
    </AppContainer>
  );
}
