import React, {useContext, useEffect, useState} from "react";
import ajaxUser from "../../util/remote/ajaxUser";
import Card from "../Common/Card";
import AuthContext from "../../Context/AuthContext";
import functions from "../../util/functions";

export default function ManagersCard(props) {
  // const {user} = useContext(AuthContext)
  const user = functions.sessionGuard();
  useEffect(() => {
    managerCount();
  }, []);
  const [countManager, setCountManager] = useState(false);

  const managerCount = async () => {
    const server_response = await ajaxUser.countManager(user);
    if (server_response.status === "OK") {
      setCountManager(server_response.details);
    }
  };

  return (
    <Card
      title="Total Managers"
      value={countManager ? countManager.total_p : "..."}
      icon="fa fa-user"
      color="#FFC107"
      link="#"
    />
  );
}
