import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import PageHeader from "./PageHeader";

export default function AppContainer(props) {
  return (
    <div class="page">
      <Sidebar />
      <div class="main-content side-content pt-0">
        <Header />
        <div class="container-fluid">
          <PageHeader title={props.title} />
          {props.children}
        </div>
      </div>
    </div>
  );
}
