import React, {useState} from "react";
import SystemModal from "../Common/SystemModal";
import ajaxProperty from "../../util/remote/ajaxProperty";
import {toast} from "react-hot-toast";

export default function CreateAmenity(props) {
  const [amenity, setAmenity] = useState("");
  const [loading, setLoading] = useState(false);

  const createAmenity = async () => {
    const server_response = await ajaxProperty.createPropertyAmenity(
      props.property_id,
      amenity
    );
    if (server_response.status === "OK") {
      setAmenity("");
      toast.success(server_response.message);
    }
  };

  const RenderFooter = (controls) => {
    return (
      <>
        <button
          onClick={createAmenity}
          type="submit"
          className="btn btn-success">
          Save
        </button>
        <button className="btn btn-secondary" onClick={controls.close}>
          Close
        </button>
      </>
    );
  };

  return (
    <SystemModal title="Add amenity" footer={RenderFooter}>
      <form>
        <div className="form-group">
          <label>Amenity</label>
          <input
            className="form-control"
            placeholder="Enter amenity e.g. wifi,swimming pool,balcony etc"
            value={amenity}
            onChange={(e) => setAmenity(e.target.value)}
          />
        </div>
      </form>
    </SystemModal>
  );
}
