import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";
import ajaxProperty from "../../util/remote/ajaxProperty";
import functions from "../../util/functions";

export default function PropertiesTable() {
  const [propertyList, setPropertyList] = useState(false);
  const [loading, setLoading] = useState(false);
  const id = functions.sessionGuard();
  const [page, setPage] = useState(1);
  const getPropertyByLandlord = async () => {
    setLoading(true);
    const server_response = await ajaxProperty.fetchPropertyList(id, page);
    setLoading(false);
    if (server_response.status === "OK") {
      setPropertyList(server_response.details.list);
    }
  };

  useEffect(() => {
    getPropertyByLandlord();
  }, [id, page]);

  return (
    <div className="card custom-card">
      <div className="card-body map-card">
        <TableHeader
          title="Recent Properties"
          subtitle="Displays properties according to the most recently added"
          view="View All"
          href="/properties"
        />

        <div className="border-top mt-3"></div>
        <div className="user-manager scroll-widget border-top">
          <div class="table-responsive">
            <table className="table table-hover text-nowrap mg-b-0 ">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Landlord</th>
                  <th>Number of Units</th>
                  <th>Square metres</th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <Loader />
                ) : propertyList ? (
                  Array.isArray(propertyList) &&
                  propertyList.map((item, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        {" "}
                        <Link to={`/properties/property-profile/${item.id}`}>
                          {item.name}
                        </Link>
                      </td>
                      <td>
                        {item.landlord.first_name} {item.landlord.last_name}
                      </td>
                      <td>{item.units_number}</td>
                      <td>{item.sq_metres} </td>
                    </tr>
                  ))
                ) : (
                  <h5>No properties found</h5>
                )}
              </tbody>
            </table>
            {/* {!propertyList && <Loader/>} */}
          </div>
        </div>
      </div>
    </div>
  );
}
