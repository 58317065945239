import React, {useEffect, useState} from "react";
import Card from "../Common/Card";
import ajaxTenant from "../../util/remote/ajaxTenant";

export default function TenantsCard(props) {
  const [tenantNumber, setTenantNumber] = useState(false);

  const getTenantsNumber = async () => {
    const server_response = await ajaxTenant.fetchTenantNumber(props.id);

    if (server_response.status === "OK") {
      //store results
      setTenantNumber(server_response.details);
    } else {
      //communicate error
      setTenantNumber("404");
    }
  };

  useEffect(() => {
    getTenantsNumber();
  }, [props.id]);

  return (
    <Card
      title="Total Tenants"
      value={tenantNumber ? tenantNumber.total_p : "..."}
      icon="fa fa-users"
      color="#8760FB"
      link="#"
      bottomValue={3}
    />
  );
}
