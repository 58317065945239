import React, {useContext, useEffect, useState} from "react";
import ModalComponent from "../Common/Modal";
import Select from "react-select";
import {Toaster, toast} from "react-hot-toast";
import ajaxTenantUnit from "../../util/remote/ajaxTenantUnit";
import TenantContext from "../../Context/TenantContext";
import ajaxTenant from "../../util/remote/ajaxTenant";
import functions from "../../util/functions";
export default function CreateTenantUnit(props) {
  const [tenant, setTenant] = useState("");
  const [billing_date, setBillingDate] = useState("");
  const [agreed_amount, setAgreedAmount] = useState("");
  const [page, setPage] = useState(1);

  const id = functions.sessionGuard();
  const [tenantLists, setTenantList] = useState(false);
  useEffect(() => {
    getTenants();
  }, [page]);

  const getTenants = async () => {
    let data = {
      user_id: id,
      page: page,
    };
    const server_response = await ajaxTenant.fetchTenantList(data);
    if (server_response.status === "OK") {
      setTenantList(server_response.details.list);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };
  console.log(tenantLists);
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    if (props.unit_id > 0 && agreed_amount.length > 0 && tenant > 0) {
      const server_response = await ajaxTenantUnit.createTenantUnit(
        tenant,
        props.unit_id,
        agreed_amount,
        billing_date
      );
      // console.log(server_response)

      if (server_response.status === "OK") {
        window.location.reload();
        setTenant("");
        setAgreedAmount("");
        setBillingDate("");
        props.getUnitHistory();
        props.fetchUnitProfile();

        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };
  return (
    <ModalComponent
      color={props.color}
      name="Assign Tenant"
      title={`Assign Tenant to unit ${props.unit}`}
      id={props.id}
      icon="fa fa-plus"
      size="sm"
      style={{float: "right"}}>
      <Toaster />
      <form onSubmit={(e) => handleAdd(e)} method="post">
        <div className="mb-3">
          <label htmlFor="">
            Select Tenant:<span class="tx-danger">*</span>
          </label>
          {/* {console.log(tenant)} */}
          <Select
            onChange={(e) => setTenant(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            styles={customStyles}
            options={Array.isArray(tenantLists) ? tenantLists : []}
            value={
              Array.isArray(tenantLists) &&
              tenantLists.find((value) => value.id === tenant)
            }
          />
        </div>
        <div className="mb-3">
          <label htmlFor="">
            Agreed Amount:<span class="tx-danger">*</span>
          </label>
          <input
            type="number"
            value={agreed_amount}
            onChange={(e) => setAgreedAmount(e.target.value)}
            className="form-control"></input>
        </div>
        <div className="mb-3">
          <label htmlFor="">
            Billing Date:<span class="tx-danger">*</span>
          </label>
          <input
            value={billing_date}
            onChange={(e) => setBillingDate(e.target.value)}
            class="form-control"
            required=""
            type="date"
          />
        </div>

        <div className="mb-3">
          <input
            type="submit"
            style={{float: "right"}}
            className="btn btn-success"
            value="Save"
          />
        </div>
      </form>
    </ModalComponent>
  );
}
