import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchTenantUnitInformation(tenant_id) {
    let data = {
      tenant_id: tenant_id,
    };
    let response = await apiCall("tenant/unit/gettenantInfo", data);

    return response;
  },
  async fetchPastTenants(unit_id) {
    let data = {
      unit_id: unit_id,
    };
    let response = await apiCall("tenant/unit/history", data);

    return response;
  },
  async createTenantUnit(
    tenant_id,
    unit_id,
    agreed_amount,
    billing_date
  ) {
    let data = {
      tenant_id: tenant_id,
      unit_id: unit_id,
      agreed_amount: agreed_amount,
      billing_date: billing_date
    };

    let response = await apiCall("tenant/unit/create", data);
    return response;
  },
  async updateTenancy(
    tenant_unit_id,
    unit_id,
    status
  ) {
    let data = {
      tenant_unit_id: tenant_unit_id,
      unit_id:unit_id,
      status: status
    };

    let response = await apiCall("tenancy/unit/update", data);
    return response;
  }
};
