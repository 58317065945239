import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async addPropertyManager(property_id, manager_id) {
    let data = {
      property_id: property_id,
      manager_id: manager_id,
    };
    let response = await apiCall("property/manager/create", data);
    return response;
  },
  async UpdatePropertyManager(data) {
    let response = await apiCall("property/manager/update", data);
    return response;
  },
};
