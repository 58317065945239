import React, {useEffect, useState} from "react";
import ajaxUnit from "../../util/remote/ajaxUnit";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function RecentUnits(props) {
  const [propertyUnits, setPropertyUnits] = useState(false);
  const [unitImage, setUnitImage] = useState(false);
  const [ActiveUnit, setActiveUnit] = useState(true);
  const handleUnitShow = () => setActiveUnit(false);
  const handleUnitHide = () => setActiveUnit(true);
  const [unitProfile, setUnitProfile] = useState(false);

  const getUnitProfile = async () => {
    const server_response = await ajaxUnit.fetchUnitProfile(props.id);

    if (server_response.status === "OK") {
      //store results
      setUnitProfile(server_response.details);
    } else {
      //communicate error
      setUnitProfile("404");
    }
  };

  const left = () => {
    const div = document.getElementsByClassName("active");

    if (div[1].previousSibling != null) {
      // div[1].classList.add('carousel-item-right')

      //     div[1].previousSibling.classList.add('carousel-item-prev')
      setTimeout(() => {
        // div[1].classList.remove('carousel-item-right')
        // div[1].previousSibling.classList.remove('carousel-item-prev')
        div[1].previousSibling.classList.add("active");
        div[1].classList.remove("active");
      }, 10);
    } else {
      // console.log("no previous");
    }
  };

  const right = () => {
    const div = document.getElementsByClassName("active");

    if (div[1].nextSibling != null) {
      div[1].classList.add("carousel-item-left");

      div[1].nextSibling.classList.add("carousel-item-next");

      setTimeout(() => {
        div[1].classList.remove("carousel-item-left");
        div[1].nextSibling.classList.remove("carousel-item-next");
        div[1].nextSibling.classList.add("active");
        div[1].classList.remove("active");
      }, 10);
    } else {
    }
  };

  useEffect(() => {
    getPropertyUnits();
    getUnitProfile();
  }, [props.id]);

  const getPropertyUnits = async () => {
    let data = {
      user_id: functions.sessionGuard(),
      property_id: props.id,
    };
    const server_response = await ajaxUnit.fetchUnitList(data);

    if (server_response.status === "OK") {
      //store results
      setPropertyUnits(server_response.details);
    } else {
      //communicate error
      setPropertyUnits("404");
    }
  };

  return (
    <div class="card custom-card ">
      <div class="carousel-inner" style={{backgroundColor: "white !important"}}>
        <div className="card-body">
          {Array.isArray(propertyUnits) &&
            propertyUnits.map((item, key) => (
              <div class={`carousel-item ${key === 0 ? "active" : ""}`}>
                <div
                  class="text-center"
                  style={{
                    background: "white",
                    backgroundColor: "white !important",
                  }}>
                  {Array.isArray(item.images) &&
                    item.images.map((item2) => (
                      <img
                        class="img-thumbnail text-center w-100"
                        style={{
                          background: "white",
                          backgroundColor: "white !important",
                        }}
                        alt="Product"
                        src={dictionary.apiHost + item2.photo}
                      />
                    ))}
                </div>

                <div class="carouselproduct mt-4 ">
                  <h4 class="">
                    <a href="product-details-2.html">{item.name}</a>
                    {item.status === "0" ? (
                      <span
                        style={{fontSize: "10px"}}
                        className="badge badge-pill badge-success">
                        Vacant
                      </span>
                    ) : (
                      <span
                        style={{fontSize: "10px"}}
                        className="badge badge-pill badge-danger"></span>
                    )}
                  </h4>
                </div>
                <div className="p-2">
                  <span
                    style={{fontSize: "18px"}}
                    className="badge badge-pill badge-light mr-4">
                    <i className="fa fa-bed" style={{color: "#8760FB"}}></i>{" "}
                    {item.bedrooms_no}
                  </span>
                  <span
                    style={{fontSize: "18px"}}
                    className="badge badge-pill badge-light mr-4">
                    <i className="fa fa-shower" style={{color: "#8760FB"}}></i>{" "}
                    {item.baths_no}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="card-footer">
        <span style={{float: "left"}}>
          <i onClick={left} className="fa fa-arrow-left"></i>
        </span>
        <span style={{float: "right"}}>
          <i onClick={right} className="fa fa-arrow-right"></i>
        </span>
      </div>
    </div>
  );
}
