import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../Structure/AppContainer";
import UserContext from "../../Context/UserContext";
import RoleContext from "../../Context/RoleContext";
import ajaxUser from "../../util/remote/ajaxUser";
import {Toaster, toast} from "react-hot-toast";
import Alert from "../Common/Alert";
import Select from "react-select";
export default function CreateLandlords() {
  const {userList, getUserList} = useContext(UserContext);
  const {roleList} = useContext(RoleContext);
  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [roleID, setRoleID] = useState("3");
  const [userID, setUserID] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [image, setImage] = useState("");
  const [info, setInfo] = useState("");
  const [password, setPassword] = useState("");
  // console.log(roleList);
  console.log(roleID);
  const changeImage = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setImage(e.target.result);
    };
  };

  //USER CREATE FUNCTION
  const createUser = async (e) => {
    e.preventDefault();
    console.log(phone_number);
    if (
      userID > 0 ||
      roleID > 0 ||
      first_name.length > 0 ||
      last_name.length > 0 ||
      email.length > 0 ||
      username.length > 0 ||
      image.length > 0 ||
      phone_number.length > 0 ||
      age.length > 0 ||
      password.length > 0
    ) {
      let data = {
        username: username,
        password: password,
        first_name: first_name,
        last_name: last_name,
        role_id: roleID,
        landlord_id: userID,
        email: email,
        age: age,
        phone_number: phone_number,
        photo: image,
      };
      const server_response = await ajaxUser.createUser(data);

      if (server_response.status === "OK") {
        setUsername("");
        setFirstName("");
        setLastName("");
        setImage("");
        setRoleID("");
        setUserID("");
        setPassword("");
        setPhoneNumber("");
        setAge("");
        setInfo(
          <Alert
            type="success"
            message={server_response.message}
            icon={"fe-check-circle"}
          />
        );
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };
  return (
    <AppContainer title="Add Landlord">
      <div className="row">
        <div class="col-lg-12 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              <div>
                <h6 class="card-title mb-4">Add New Landlord </h6>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12">
                  <div class="">
                    {info}
                    <Toaster />
                    <form onSubmit={createUser}>
                      <div class="form-group">
                        <div class="row row-sm">
                          <div class="col-sm-6 mb-3">
                            <label class="">First Name</label>
                            <input
                              value={first_name}
                              onChange={(e) => setFirstName(e.target.value)}
                              class="form-control"
                              required
                              type="text"
                            />
                          </div>

                          <div class="col-sm-6 mb-3">
                            <label class="">Last Name</label>
                            <input
                              value={last_name}
                              onChange={(e) => setLastName(e.target.value)}
                              class="form-control"
                              required
                              type="text"
                            />
                          </div>

                          <div class="col-sm-6">
                            <label class="">Username</label>
                            <input
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              class="form-control"
                              required
                              type="text"
                            />
                          </div>
                          <div class="col-sm-6">
                            <label class="">Password</label>
                            <input
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              class="form-control"
                              required
                              type="password"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="">Role</label>
                        <Select
                          styles={customStyles}
                          onChange={(selectedOption) =>
                            setRoleID(selectedOption.role_id)
                          }
                          isDisabled
                          getOptionLabel={(option) => option.role_name}
                          getOptionValue={(option) => option.role_id}
                          isSearchable
                          options={Array.isArray(roleList) ? roleList : []}
                          value={
                            Array.isArray(roleList) &&
                            roleList.find((value) => value.role_id === roleID)
                          }
                        />
                      </div>
                      <div class="form-group">
                        <div class="row row-sm">
                          <div class="col-sm-4">
                            <label class="">Age</label>
                            <input
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                              class="form-control"
                              required
                              type="number"
                              min={0}
                            />
                          </div>

                          <div class="col-sm-4">
                            <label class="">Phone Number</label>
                            <input
                              value={phone_number}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              placeholder="e.g. 0701234567"
                              required
                              class="form-control"
                              type="tel"
                            />
                            <span class="validity"></span>
                          </div>

                          <div class="col-sm-4">
                            <label class="">Email</label>
                            <input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              class="form-control"
                              required
                              type="email"
                            />
                            <small style={{color: "red"}}></small>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="row row-sm">
                          {/* {roleID === "4" ? (
                            <div class="col-sm-6">
                              <label class="">Landlord</label>

                              <select
                                value={userID}
                                onChange={(e) => setUserID(e.target.value)}
                                class="form-control w-100 select2"
                                data-parsley-class-handler="#slWrapper"
                                data-parsley-errors-container="#slErrorContainer"
                                data-placeholder="Choose one"
                                required>
                                <option label="Select a landlord..."></option>
                                {Array.isArray(userList) &&
                                  userList.map((item) => (
                                    <option value={item.id}>
                                      {item.first_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          ) : (
                            ""
                          )} */}
                          <div class="col-sm-6">
                            <label>Upload image</label>
                            <div class="custom-file">
                              <input
                                onChange={changeImage}
                                class="custom-file-input"
                                id="customFile"
                                type="file"
                              />{" "}
                              <label class="custom-file-label" for="customFile">
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button class="btn ripple btn-main-primary btn-block">
                        Register User
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
