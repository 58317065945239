import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import {Link} from "react-router-dom";
import Loader from "../Components/Common/Loader";
import PropertiesCard from "../Components/Dashboard/PropertiesCard";
import TenantsCard from "../Components/Dashboard/TenantsCard";
import TenantContext from "../Context/TenantContext";
import CountUp from "react-countup";
import {slice} from "lodash";
import ImageSlider from "../Components/Common/ImageSlider";
import UsersCard from "../Components/Dashboard/UsersCard";
import PropertiesTable from "../Components/Dashboard/PropertiesTable";
import NewProperty from "../Components/Property/NewProperty";
import functions from "../util/functions";
import ActivityLoader from "../Components/Common/ActivityLoader";
import useStateCallback from "../util/customHooks/useStateCallback";
import UnitsCard from "../Components/Dashboard/UnitsCard";
import LandlordsCard from "../Components/Dashboard/LandlordsCard";
import ManagersCard from "../Components/Dashboard/ManagersCard";
import ListRecentTenants from "../Components/Tenants/ListRecentTenants";
import AuthContext from "../Context/AuthContext";
import {RenderSecure} from "../util/script/RenderSecure";
import Alert from "../Components/Common/Alert";
import UnitsOccupied from "../Components/Dashboard/UnitsOccupied";

export default function DashboardPage() {
  const {user, userId} = useContext(AuthContext);
  const {tenantLists, getTenants} = useContext(TenantContext);
  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(5);
  const [modal, setModal] = useStateCallback(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      localStorage.setItem("myrentals@welcomemessage", "notFirstLogin");
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "id",
      user?.landlord_id?.id ??
        (user?.role?.role_name === "Landlord" ? user.id : "")
    );
  }, [
    user?.landlord_id?.id ??
      (user?.role?.role_name === "Landlord" ? user.id : ""),
  ]);

  const loadWelcomeMessage = () => {
    return;
  };

  const loadMore = () => {
    // setLoadMore(true)
    // setTimeout(() => {
    // 	setLoadMore(false)
    // 	setIndex(index + 5)
    // console.log(index)
    // if (index >= tenantLists.length) {
    //   setIsCompleted(true)
    // } else {
    //   setIsCompleted(false)
    // }
    // }, 1000);
  };

  const handleModal = () => {
    setModal(false, () => setModal(<NewProperty isOpen={true} />));
  };

  // console.log(
  //   user.landlord_id
  //     ? user.landlord_id.id
  //     : user.role?.role_name === "Landlord"
  //     ? user.id
  //     : ""
  // );

  return (
    <AppContainer title="Dashboard">
      {modal}
      {loadWelcomeMessage()}
      {isVisible && (
        <div className="hidden-text">
          {localStorage.getItem("myrentals@welcomemessage") === "firstLogin"
            ? user && (
                <Alert
                  type="success"
                  style={{fontSize: "20px"}}
                  message={`Welcome back, ` + user.last_name}
                />
              )
            : ""}
        </div>
      )}
      {/* <button onClick={handleModal}>New Proprty</button> */}
      {loading ? (
        <Loader />
      ) : (
        // <div>
        <div class="row">
          <div className="col-md-12">
            <div class="row">
              <RenderSecure code="COUNT-LANDLORDS">
                <div className="col-md-4 ">
                  <LandlordsCard />
                </div>
              </RenderSecure>

              <RenderSecure code="COUNT-MANAGERS">
                <div className="col-md-4 ">
                  <ManagersCard
                    id={user.role?.role_name === "Landlord" ? user.id : ""}
                  />
                </div>
              </RenderSecure>

              <RenderSecure code="COUNT-PROPERTIES">
                <div className="col-md-4 ">
                  <PropertiesCard />
                </div>
              </RenderSecure>

              <RenderSecure code="COUNT-PROPERTIES">
                <div className="col-md-4 ">
                  <UnitsCard />
                </div>
              </RenderSecure>
              <RenderSecure code="COUNT-PROPERTIES">
                <div className="col-md-4 ">
                  <UnitsOccupied />
                </div>
              </RenderSecure>

              <RenderSecure code="COUNT-LANDLORDS">
                <div className="col-md-4 ">
                  <TenantsCard id={functions.sessionGuard()} />
                </div>
              </RenderSecure>

              <RenderSecure code="COUNT-USERS">
                <div className="col-md-4 ">
                  <UsersCard />
                </div>
              </RenderSecure>
            </div>
          </div>

          <div class="col-md-8">
            <PropertiesTable />
          </div>
          <div class="col-md-4">
            <ListRecentTenants />
          </div>
        </div>
      )}
    </AppContainer>
  );
}
