import React, {useEffect, useState} from "react";
import ajaxUser from "../util/remote/ajaxUser";
import {Link, useNavigate} from "react-router-dom";
import Alert from "../Components/Common/Alert";
import Loader from "../Components/Common/Loader";

export default function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [info, setInfo] = useState("");

  const [loading, setLoading] = useState(false);

  const loginuser = async (e) => {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      setLoading(true);
      const server_response = await ajaxUser.loginUser(username, password);

      if (server_response.status === "OK") {
        localStorage.setItem("myrentals@user", server_response.details);
        localStorage.setItem("myrentals@welcomemessage", "firstLogin");

        setInfo(<Alert type="success" message={server_response.message} />);
        setTimeout(() => {
          setLoading(false);
          console.log("History Length:", window.history.length);

          // if (window.history.length > 2) {
          //   window.history.go(-2);
          // } else {
          navigate("/");
          // }
        }, 1000);
        window.location.reload();
      } else {
        setTimeout(() => {
          setLoading(false);
          setInfo(<Alert type="danger" message={server_response.message} />);
        }, 500);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div class="page main-signin-wrapper">
      <img
        className="back-img"
        style={{width: "100%", height: "100%", position: "fixed"}}
        src={process.env.PUBLIC_URL + "/rentalsLoginBackground.png"}
      />

      <div class="row text-center pl-0 pr-0 ml-0 mr-0">
        <div class="col-lg-3 d-block mx-auto">
          <div class="card custom-card">
            <div class="text-center mb-2">
              <i className="fa fa-home header-brand-img  theme-logos"></i>
              <img
                style={{height: "100px"}}
                src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
                class="header-brand-img"
                alt="logo"
              />
              <img
                style={{height: "100px"}}
                src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
                class="header-brand-img theme-logos"
                alt="logo"
              />
            </div>
            <div class="card-body">
              <h4 class="text-center mb-2">Login</h4>
              {loading ? <Loader /> : info}
              <form onSubmit={loginuser}>
                <div class="form-group text-left mt-2">
                  <label>Username</label>
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    class="form-control"
                    placeholder="Enter your email"
                    type="text"
                  />
                </div>
                <div class="form-group text-left">
                  <label>Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    class="form-control"
                    placeholder="Enter your password"
                    type="password"
                  />
                </div>
                <button
                  type="submit"
                  class="btn ripple btn-main-primary btn-block">
                  Sign In
                </button>
              </form>
              <div class="mt-3 text-center">
                {/* <p class="mb-1">
                  <a href="#">Forgot password?</a>
                </p> */}
                {/* <p class="mb-0">Don't have an account? <a href="signup.html">Create an Account</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
