import React, {useContext, useEffect} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import RoleContext from "../../Context/RoleContext";

export default function Role() {
  const {roleList, getRoleList} = useContext(RoleContext);

  return (
    <AppContainer title="Roles">
      <div class="col-lg-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="card-title mb-1">Role List</h6>
              {/* <p class="text-muted card-sub-title">To enable a hover state on table rows.</p> */}
            </div>
            <div class="table-responsive">
              <table class="table table-hover mg-b-0">
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(roleList) &&
                    roleList.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{item.role_name}</td>
                        <td>
                          {item.status === "1" ? (
                            <span class="badge badge-success">Active</span>
                          ) : (
                            <span class="badge badge-danger">Deactivated</span>
                          )}
                        </td>
                        <td>{item.created_at.long_date}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
