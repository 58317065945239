import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import {Toaster, toast} from "react-hot-toast";
import ajaxLogs from "../../util/remote/ajaxLogs";
import Loader from "../../Components/Common/Loader";

function TenantInvoicingLogs() {
  const [propertyLog, setPropertyLog] = useState("");
  const [loading, setLoading] = useState(false);

  const invoicingLogs = async () => {
    const server_response = await ajaxLogs.fetchTenantInvoicingLogs();
    if (server_response.status === "OK") {
      setPropertyLog(server_response.details);
    } else if (server_response.status === "Fail") {
      setPropertyLog("404");
    }
  };
  const handlePropertyResend = async () => {
    setLoading(true);
    const server_response = await ajaxLogs.recreateInvoiceOfRent();
    setLoading(false);
    if (server_response.status === "OK") {
      toast.success(server_response.message);
    } else if (server_response.status === "Fail") {
      toast.error(server_response.message);
    }
  };

  useEffect(() => {
    invoicingLogs();
  }, []);

  return (
    <div>
      <AppContainer title="Tenants unit Addition to generate paymentcode erro log">
        <Toaster position="top-center" reverseOrder={false} />
        <div class="row">
          <div class="col-lg-12">{loading ? <Loader /> : ""}</div>
          <div class="col-lg-12">
            <h6 class="card-title mb-4 d-flex justify-content-end">
              <button
                onClick={handlePropertyResend}
                class="btn ripple btn-success btn-with-icon btn-sm">
                <i className="fa fa-file-export" /> Resend Creation Requests
              </button>
            </h6>
            <div class="card custom-card">
              <div class="card-body">
                <div>
                  <h6 class="card-title ">Tenants payments List</h6>
                </div>
                <div class="table-responsive">
                  <table
                    id="example3"
                    class="table table-striped table-hover table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Tenant Unit</th>
                        <th>Tenant name</th>
                        <th>Debit Amount</th>
                        <th>description</th>
                        <th>Date created_at</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Array.isArray(propertyLog) &&
                        propertyLog.map((item, key) => (
                          <>
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{item.tenant_unit_id.unit}</td>
                              <td>{item.tenant_unit_id.tenant}</td>
                              <td>{item.Debit}</td>
                              <td>{item.description}</td>

                              <td>{item.created_at.when}</td>
                              {/* <td>
                                <Link
                                  className="btn btn-outline-success w-100"
                                  to={`/tenants/tenant-profile/1`}>
                                 
                                  View Profile
                                </Link>
                              </td> */}
                            </tr>
                          </>
                        ))}
                      {propertyLog == "404" && (
                        <>
                          <tr>
                            <td colSpan={6} className="text-center text-info">
                              No data available here
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default TenantInvoicingLogs;
