import React, {useEffect, useState} from "react";
import Card from "../Common/Card";
import ajaxUser from "../../util/remote/ajaxUser";

export default function UsersCard() {
  const [userNumber, setUserNumber] = useState(false);

  const getUsersNumber = async () => {
    const server_response = await ajaxUser.fetchUserNumber();

    if (server_response.status === "OK") {
      //store results
      setUserNumber(server_response.details);
    } else {
      //communicate error
      setUserNumber("404");
    }
  };

  useEffect(() => {
    getUsersNumber();
  }, []);

  return (
    <Card
      title="Total Users"
      value={userNumber ? userNumber.total_p : "..."}
      icon="fe fe-users"
      color="#01B8FF"
      link="#"
    />
  );
}
