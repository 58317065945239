import apiCall from "./apiCall";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async fetchRoleList(data) {
       
        let response = await apiCall("user/role/list", data)
        return response;
       
      }




}