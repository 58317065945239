import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import {Link, Outlet, useParams} from "react-router-dom";
import ajaxTenant from "../../util/remote/ajaxTenant";
import ajaxUser from "../../util/remote/ajaxUser";
import AuthContext from "../../Context/AuthContext";
import Loader from "../../Components/Common/Loader";
// import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Alert from "../../Components/Common/Alert";
// import Loader from "../../Components/Common/Loader";
import {Toaster, toast} from "react-hot-toast";
import ajaxTenantUnit from "../../util/remote/ajaxTenantUnit";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function Profile() {
  const {user} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {id} = useParams();

  const [name, setName] = useState("");
  const [DOB, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [nin, setNin] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [manager, setManager] = useState("");
  const [managerList, setManagerList] = useState("");
  const [picture, setPicture] = useState("");
  const [status, setStatus] = useState(0);
  const [tenantProfile, setTenantProfile] = useState(false);
  const [info, setInfo] = useState("");
  const [tenantUnitInfo, setTenantUnitInfo] = useState(false);
  const [imageActive, setImageActive] = useState(false);
  const [isActive, setActive] = useState(false);

  const handleCloseImage = () => setImageActive(false);
  const handleOpenImage = () => setImageActive(true);

  const cancelEdit = () => {
    setActive(false);
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  //get tenant profile
  const getTenantProfile = async (id) => {
    const server_response = await ajaxTenant.fetchTenantProfile(id);

    if (server_response.status === "OK") {
      //store results
      setTenantProfile(server_response.details);
    } else {
      //communicate error
      setTenantProfile("404");
    }
  };

  useEffect(() => {
    getTenantProfile(id);
  }, [id]);

  //get tenant unit details
  const getTenantUnitInformation = async (id) => {
    const server_response = await ajaxTenantUnit.fetchTenantUnitInformation(id);

    if (server_response.status === "OK") {
      setTenantUnitInfo(server_response.details);
    } else {
      setTenantUnitInfo("404");
    }
  };

  useEffect(() => {
    getTenantUnitInformation(id);
  }, [id]);

  const getManagers = async () => {
    const server_response = await ajaxUser.fetchManagers(
      functions.sessionGuard()
    );
    if (server_response.status === "OK") {
      setManagerList(server_response.details);
    } else {
      setManagerList("404");
    }
  };
  useEffect(() => {
    getManagers();
  }, []);

  //set the tenant Update previous values
  const setTenantUpdate = (e, tenantProfile) => {
    e.preventDefault();
    setName(tenantProfile.name);
    setDOB(tenantProfile.DOB);
    setEmail(tenantProfile.email);
    setNin(tenantProfile.nin);
    setPhone_number(tenantProfile.phone_number);
    setPicture(tenantProfile.picture);
    setStatus(tenantProfile.status);
    setManager(tenantProfile.manager_id);
    setActive(true);
  };

  const handleTenantUpdate = async (e) => {
    e.preventDefault();
    if (
      id > 0 ||
      name.length > 0 ||
      DOB.length > 0 ||
      email.length > 0 ||
      nin.length > 0 ||
      picture.length > 0 ||
      phone_number.length > 0 ||
      manager > 0 ||
      status > 0
    ) {
      setLoading(true);
      const server_response = await ajaxTenant.updateTenant(
        id,
        name,
        DOB,
        email,
        nin,
        phone_number,
        manager
      );
      // console.log(server_response);
      if (server_response.status === "OK") {
        setName("");
        setDOB("");
        setEmail("");
        setNin("");
        setPhone_number("");
        setManager("");
        setInfo(<Alert type="success" message={server_response.message} />);
      }
      setTimeout(() => {
        getTenantProfile(id);
        setLoading(false);
      }, 1000);
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again" />
      );
    }
  };

  const uploadPicture = async (e) => {
    e.preventDefault();
    if (id > 0 || picture.length > 0) {
      const server_response = await ajaxTenant.updateTenantImage(id, picture);

      if (server_response.status === "OK") {
        setPicture("");
        toast.success(server_response.message);
        getTenantProfile(id);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete All Fields and Try Again!!");
    }
  };

  const changePicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setPicture(e.target.result);
    };
  };

  return (
    <>
      <AppContainer title="Tenant's Profile">
        <Toaster />
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="card custom-card">
              <div class="card-body text-center">
                {imageActive ? (
                  <div
                    class="main-profile-overview widget-user-image text-center"
                    style={{height: "600px"}}>
                    {/* <div class="main-img-user"> */}
                    <form onSubmit={uploadPicture}>
                      <div class="custom-file">
                        <input
                          onChange={changePicture}
                          class="custom-file-input mb-3"
                          id="customFile"
                          type="file"
                        />{" "}
                        <label class="custom-file-label" for="customFile">
                          Choose file
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <button
                            type="submit"
                            className="btn btn-success btn-sm">
                            Upload
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button
                            onClick={handleCloseImage}
                            className="btn btn-secondary btn-sm">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div
                    class="main-profile-overview widget-user-image text-center"
                    style={{height: "220px"}}>
                    <div
                      class="main-img-user d-sm-block "
                      style={{height: "220px", width: "220px"}}>
                      <img
                        className="rounded-circle"
                        alt="avatar"
                        src={dictionary.apiHost + tenantProfile.picture}
                      />
                    </div>
                  </div>
                )}

                <div class="item-user pro-user mt-4">
                  <h4 class="pro-user-username text-dark mt-2 mb-0">
                    {tenantProfile.name}
                  </h4>
                  <Link
                    to={`/properties/property-profile/${tenantUnitInfo.unit_id?.property.id}/unit-profile/${tenantUnitInfo.unit_id?.id}`}>
                    <p class="pro-user-desc  mb-2">
                      Assigned unit:
                      <span className="ml-1">
                        {tenantUnitInfo.unit_id
                          ? tenantUnitInfo.unit_id?.name
                          : "Not Assigned"}
                      </span>
                    </p>
                  </Link>

                  <Link
                    to="#"
                    onClick={handleOpenImage}
                    class="btn ripple btn-primary btn-sm">
                    <i class="far fa-edit mr-1"></i>Edit Picture
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {isActive ? (
            <div class="col-lg-8 col-md-12">
              <div class="card custom-card main-content-body-profile">
                <nav class="nav main-nav-line">
                  <a
                    class="nav-link active just"
                    data-toggle="tab"
                    href="#tab1over">
                    Tenant Information
                  </a>
                </nav>
                <form onSubmit={(e) => handleTenantUpdate(e)}>
                  <div class="card-body tab-content h-100">
                    <div class="tab-pane active" id="tab1over">
                      <div class="main-content-label tx-13 mg-b-20">
                        Edit Tenant Information
                      </div>
                    </div>

                    <div class="main-profile-contact-list main-profile-work-list">
                      {loading ? <Loader /> : info}
                      <div className="row">
                        <div className="col-md-12 mb-2"></div>
                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fe fe-user"></i>
                            </div>
                            <div class="media-body">
                              <span>Name</span>
                              <div>
                                <div class="form-group">
                                  <input
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fe fe-mail"></i>
                            </div>
                            <div class="media-body">
                              <span>Email Address</span>
                              <div>
                                <div class="form-group">
                                  <input
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <div class="media-body">
                              <span>Date of Birth</span>
                              <div>
                                <div class="form-group">
                                  <input
                                    defaultValue={DOB}
                                    onChange={(e) => setDOB(e.target.value)}
                                    class="form-control"
                                    id="dateMask"
                                    required=""
                                    type="date"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fa fa-id-card"></i>
                            </div>
                            <div class="media-body">
                              <span>NIN</span>
                              <div>
                                <div class="form-group">
                                  <input
                                    defaultValue={nin}
                                    onChange={(e) => setNin(e.target.value)}
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fe fe-phone"></i>
                            </div>
                            <div class="media-body">
                              <span>Phone Number</span>
                              <div>
                                <div class="form-group">
                                  <input
                                    defaultValue={phone_number}
                                    onChange={(e) =>
                                      setPhone_number(e.target.value)
                                    }
                                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    required
                                    class="form-control"
                                    type="text"
                                  />
                                  <span class="validity"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-2">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fa fa-user"></i>
                            </div>

                            {user.role?.role_name === "Manager" ||
                            user.role?.role_name === "Landlord" ? (
                              <div class="media-body">
                                <label htmlFor="">
                                  Name of Tenant Manager:
                                </label>

                                <Select
                                  isDisabled
                                  defaultValue={"empty"}
                                  onChange={(e) => setManager(e.id)}
                                  getOptionLabel={(option) => option.first_name}
                                  getOptionValue={(option) => option.id}
                                  isSearchable
                                  options={
                                    Array.isArray(managerList)
                                      ? managerList
                                      : ""
                                  }
                                  value={
                                    Array.isArray(managerList) &&
                                    managerList.find(
                                      (value) => value.id === user.id
                                    )
                                  }
                                  styles={customStyles}
                                />
                              </div>
                            ) : (
                              <div class="media-body">
                                <label htmlFor="">
                                  Name of Tenant Manager:
                                </label>

                                <Select
                                  onChange={(e) => setManager(e.id)}
                                  getOptionLabel={(option) => option.first_name}
                                  getOptionValue={(option) => option.id}
                                  isSearchable
                                  options={managerList}
                                  value={
                                    Array.isArray(managerList) &&
                                    managerList.find(
                                      (value) => value.id === manager
                                    )
                                  }
                                  styles={customStyles}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer ">
                    <button
                      style={{float: "right"}}
                      type="submit"
                      className="btn btn-md btn-success mb-2">
                      Save
                    </button>
                    <button
                      style={{float: "right"}}
                      onClick={cancelEdit}
                      className="btn btn-secondary mr-4">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <>
              <div class="col-lg-8 col-md-12">
                <div class="card custom-card main-content-body-profile">
                  <nav class="nav main-nav-line">
                    <a
                      class="nav-link active just"
                      data-toggle="tab"
                      href="#tab1over">
                      Tenant Information
                    </a>
                  </nav>
                  <div class="card-body tab-content h-100">
                    <div class="tab-pane active" id="tab1over">
                      <div class="main-content-label tx-13 mg-b-20">
                        Personal Information
                      </div>
                    </div>
                    <div class="main-profile-contact-list main-profile-work-list">
                      <div className="row">
                        <div className="col-md-6 mb-5">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fe fe-mail"></i>
                            </div>
                            <div class="media-body">
                              <span>Email Address</span>
                              <div>{tenantProfile.email}</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-5">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fe fe-smartphone"></i>
                            </div>
                            <div class="media-body">
                              <span>Phone Number</span>
                              <div>{tenantProfile.phone_number}</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-5">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <div class="media-body">
                              <span>Date Of Birth</span>
                              <div>{tenantProfile.DOB}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-5">
                          <div class="media">
                            <div class="media-logo bg-light text-dark">
                              <i class="fa fa-id-card"></i>
                            </div>
                            <div class="media-body">
                              <span>NIN</span>
                              <div>{tenantProfile.nin}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link
                      to="#"
                      onClick={(e) => setTenantUpdate(e, tenantProfile)}
                      style={{float: "right"}}
                      class="btn ripple btn-primary btn-md">
                      <i class="far fa-edit mr-1"></i>Edit
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* ----------------------------------------------------------------- */}
          <div className="col-lg-12 col-md-12">
            <div class="col-lg-12 col-md-12">
              <div class="card custom-card main-content-body-profile">
                <nav class="nav main-nav-line">
                  <a
                    class="nav-link active just"
                    data-toggle="tab"
                    href="#tab1over">
                    Tenant units
                  </a>
                </nav>
                <div class="card-body tab-content h-100">
                  <table
                    id="example3"
                    class="table table-striped table-hover table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th>#No.</th>
                        <th>Unit Name</th>
                        <th>Unit description</th>
                        <th>agreed_amount</th>

                        <th>
                          tenancy Start <br /> Date
                        </th>
                        <th>unit</th>
                        <th>Amount paid</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Array.isArray(tenantUnitInfo) &&
                        tenantUnitInfo.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.unit_id.name}</td>
                            <td>{item.unit_id.description}</td>
                            <td>{item.agreed_amount}</td>
                            <td>{item.agreed_amount}</td>
                            <td>{item.billing_date}</td>
                            <td>{item.unit}</td>
                          </tr>
                        ))}
                      {tenantUnitInfo === "404" && (
                        <>
                          <tr>
                            <td colSpan={7} className="text-center text-info">
                              {" "}
                              No units attached to the Tenant
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
}
