import React, {useEffect, useState} from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxUnit from "../../util/remote/ajaxUnit";
import {Link} from "react-router-dom";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function ListTenantUnits() {
  const [unitList, setUnitList] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  useEffect(() => {
    getUnits();
  }, [page]);

  const user = functions.sessionGuard();
  let data = {
    user_id: user,
    page: page,
  };
  const getUnits = async () => {
    const server_response = await ajaxUnit.fetchTenantsUnits(data);
    if (server_response.status === "OK") {
      // setUnitList(server_response.details);
      setMeta(server_response.details.meta.list_of_pages);
      setUnitList(server_response.details.list);
    }
  };

  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  return (
    <AppContainer title="List of Tenant and Units">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
          <div className="row">
            {Array.isArray(unitList) &&
              unitList.map((item, key) => (
                <div className="col-lg-3 col-md-4 col-xl-3">
                  <div
                    className="card custom-card "
                    style={{
                      height: "30rem",
                      borderTopRightRadius: "25px",
                      borderBottomLeftRadius: "25px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Example shadow values
                    }}>
                    <div className="card-body justify-content-center text-center align-items-center align-content-center">
                      {/* <div class="align-items-start justify-content-center text-center align-items-center align-content-center main-img-user avatar-xxl d-none d-sm-block"> */}

                      <img
                        alt="avatar"
                        class="avatar-xxl rounded-circle"
                        src={dictionary.apiHost + item.tenant_id.picture}
                      />
                      {/* </div> */}
                      <div className="text-center mt-1">
                        <h5>
                          <span>tenant: </span> {item.tenant_id.name}
                        </h5>

                        <p className="tx tx-gray-500">
                          <span>phone : </span> &nbsp;
                          <span> {item.tenant_id.phone_number}</span>
                        </p>

                        <label
                          style={{
                            fontSize: "1.5rem",
                            textDecoration: "underline",
                          }}>
                          unit_details
                        </label>

                        <p className="tx tx-gray-500">
                          <span>Unit Name : </span> &nbsp;
                          <span>{item.unit_id.name}</span>
                        </p>
                        <p className="tx tx-gray-500">
                          <span>Unit Description : </span> &nbsp;
                          <span>{item.unit_id.description}</span>
                        </p>
                        <p className="tx tx-gray-500">
                          <span>Unit Agreed_amount : </span> &nbsp;
                          <span> UGx {item.agreed_amount}</span>
                        </p>
                        <p className="tx tx-gray-500">
                          <span>Entry day : </span> &nbsp;
                          <span>{item.billing_date}</span>
                        </p>

                        <Link
                          className="btn btn-outline-success w-100"
                          to={`/tenants/tenant-profile/${item.id}`}>
                          View Tenant Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12 align-items-center">
          <div className="pos-absolute " style={{left: "50%"}}>
            <button
              className="btn btn-dark"
              style={{borderRight: "1px solid yellow"}}
              onClick={setPreviousPageNumber}>
              <i className="fa fa-angle-left mr-2"></i> Prev
            </button>
            {Array.isArray(meta) &&
              meta.map((item) =>
                page === item ? (
                  <button
                    style={{borderRight: "1px solid yellow"}}
                    className="btn btn-primary">
                    {item}
                  </button>
                ) : (
                  <button
                    onClick={(e) => setPageNumber(e, item)}
                    style={{borderRight: "1px solid yellow"}}
                    className="btn btn-dark">
                    {item}
                  </button>
                )
              )}

            <button
              style={{borderRight: "1px solid yellow"}}
              className="btn btn-dark"
              onClick={setNextPageNumber}>
              Next<i className="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
