import React, {useState, useEffect} from "react";
// import '@coreui/coreui/dist/css/coreui.min.css'
// import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import Carousel from "react-bootstrap/Carousel";
import ajaxProperty from "../../util/remote/ajaxProperty";
import dictionary from "../../util/dictionary";

function PropertyImageSlider(props) {
  const [location, setLocation] = useState(null);
  const [propertyImages, setPropertyImages] = useState(false);

  useEffect(() => {
    getPropertyImage();
  }, [props.id]);

  useEffect(() => {
    getLocation();
  }, []);

  const getPropertyImage = async () => {
    const server_response = await ajaxProperty.fetchPropertyImage(props.id);

    if (server_response.status === "OK") {
      //store results
      setPropertyImages(server_response.details);
    } else {
      //communicate error
      setPropertyImages("404");
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {latitude, longitude} = position.coords;
          setLocation({latitude, longitude});
        },
        (error) => {
          console.log(error);
          // Handle error cases here
        }
      );
    } else {
      // Geolocation is not supported by the browser
      // Handle unsupported case here
    }
  }

  return (
    <Carousel>
      {Array.isArray(propertyImages) &&
        propertyImages.map((item, key) => (
          <Carousel.Item>
            <img
              style={{height: "400px"}}
              // onClick={(e) => toggleModal(e, item)}
              alt={item.description}
              class="img-thumbnail d-block w-100"
              src={dictionary.apiHost + item.photo}
            />

            {/* <Carousel.Caption>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </Carousel.Caption> */}
          </Carousel.Item>
        ))}
    </Carousel>
  );
}

export default PropertyImageSlider;
