import "./App.css";
import React, {useEffect, useState} from "react";
import {Toaster, toast} from "react-hot-toast";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import DashboardPage from "./Pages/DashboardPage";
import PropertiesPage from "./Pages/PropertiesPage";
import SuperProvider from "./Context/SuperProvider";
import TenantsPage from "./Pages/Tenants/TenantsPage";
import UsersPage from "./Pages/Users/UsersPage";
import Permissions from "./Pages/Users/Permissions";
import Role from "./Pages/Users/Role";
import PropertyProfile from "./Pages/PropertyProfile";
import UnitProfile from "./Pages/UnitProfile";
import Profile from "./Pages/Tenants/Profile";
import CreateTenant from "./Components/Tenants/CreateTenant";
import UserProfile from "./Pages/Users/UserProfile";
import Home from "./Pages/Maps";
import NewProperty from "./Components/Property/NewProperty";
import ListLandlords from "./Components/Landlord/ListLandlords";
import CreateLandlords from "./Components/Landlord/CreateLandlords";
import NewManager from "./Components/Manager/NewManager";
import ListManagers from "./Components/Manager/ListManagers";
import AddUnit from "./Components/Units/AddUnit";
import ListUnits from "./Components/Units/ListUnits";
import CreateUnit from "./Components/Units/CreateUnit";
import AssignUnit from "./Components/Tenants/AssignUnit";
import Costing from "./Pages/Costing";
import AddMultiUnit from "./Components/Units/AddUnit";
import CreateMultiUnits from "./Components/Units/CreateMultiUnits";
import OccupiedUnits from "./Components/Units/OccupiedUnits";
import UnOccupiedUnits from "./Components/Units/UnOccupiedUnits";
import PaymentsMade from "./Pages/paymentsReports/PaymentsMade";
import PendingPayments from "./Pages/paymentsReports/PendingPayments";
import ListTenantUnits from "./Components/Tenants/ListTenantUnits";
import TenantUnitsLogs from "./Pages/ErrorLogs/TenantUnitsLogs";
import TenantInvoicingLogs from "./Pages/ErrorLogs/TenantInvoicingLogs";
import PropertyCreationErrorLog from "./Pages/ErrorLogs/PropertyCreationErrorLog";

function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  function checkLogin() {
    if (!window.localStorage.getItem("myrentals@user")) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }
  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    let logoutTimer;

    const startLogoutTimer = () => {
      logoutTimer = setTimeout(() => {
        logoutUser();
        toast.error("You have been logged out due to inactivity.");
        window.location.reload();
      }, 600000); // 10 minutes in milliseconds
    };
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      startLogoutTimer();
    };

    startLogoutTimer();

    // Add event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer); // Add click event listener
    // console.log(logoutTimer);
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer); // Remove click event listener
      clearTimeout(logoutTimer); // Clear timeout on unmount
    };
  }, []);

  const logoutUser = () => {
    setLoggedIn(false);
    localStorage.removeItem("myrentals@user");
  };

  return (
    <SuperProvider>
      <Toaster />
      <Router forceRefresh={false}>
        <Switch>
          {!loggedIn && (
            <>
              <Route
                path="/"
                element={
                  !loggedIn ? (
                    <Navigate replace to="/login" />
                  ) : (
                    <DashboardPage />
                  )
                }
              />
              <Route path="*" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
            </>
          )}

          {loggedIn && (
            <>
              <Route
                path="/"
                element={
                  !loggedIn ? (
                    <Navigate replace to="/login" />
                  ) : (
                    <DashboardPage />
                  )
                }
              />
              <Route
                path="/login"
                element={loggedIn ? <Navigate replace to="/" /> : <LoginPage />}
              />
              <Route path="*" element={<DashboardPage />} />

              <Route path="/" element={<DashboardPage />} />

              {/* START PERMISSIONS,ROLES,USERS */}
              <Route path="/roles" element={<Role />} />
              <Route path="/permissions" element={<Permissions />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/profile" element={<UserProfile />} />
              {/* END PERMISSIONS,ROLES,USERS */}

              {/* START PROPERTIES */}
              <Route path="/properties" element={<PropertiesPage />} />
              <Route
                path="/properties/property-profile/:id"
                element={<PropertyProfile />}
              />
              <Route
                path="/properties/property-profile/:id/unit-profile/:id"
                element={<UnitProfile />}
              />
              <Route path="/properties" element={<PropertiesPage />} />
              <Route path="/property/create" element={<NewProperty />} />
              <Route
                path="/properties/property-profile/:id"
                element={<PropertyProfile />}
              />
              {/* END PROPERTIES */}

              {/* START UNITS */}
              <Route path="/unit/create" element={<CreateUnit />} />
              <Route path="/units" element={<ListUnits />} />
              <Route path="/units/Occupied" element={<OccupiedUnits />} />
              <Route path="/units/NotOccupied" element={<UnOccupiedUnits />} />
              <Route path="/unit/create/mult" element={<CreateMultiUnits />} />
              <Route
                path="/properties/property-profile/:id/unit-profile/:id"
                element={<UnitProfile />}
              />
              {/* END UNITS */}

              {/* START TENANTS */}
              <Route path="/tenants" element={<TenantsPage />} />
              <Route path="/tenants/create-new" element={<CreateTenant />} />
              <Route path="/tenants/tenant-profile/:id" element={<Profile />} />

              {/* END TENANTS */}
              {/* START TENANTS  units*/}

              <Route path="/tenants/units/assign" element={<AssignUnit />} />
              <Route path="/tenants/units/view" element={<ListTenantUnits />} />
              <Route
                path="/tenants/units/assign"
                element={<ListTenantUnits />}
              />
              {/* END TENANTS */}

              {/* LANDLORD AND MANAGER ROUTES */}
              <Route path="/landlords" element={<ListLandlords />} />
              <Route path="/landlord/create" element={<CreateLandlords />} />
              <Route path="/manager/create" element={<NewManager />} />
              <Route path="/managers" element={<ListManagers />} />
              {/* END LANDLORD AND MANAGER ROUTES */}

              {/* REPORTS ROUTES */}
              <Route
                path="/payments/tentants/payments"
                element={<PaymentsMade />}
              />
              <Route
                path="/payments/tentants/debts"
                element={<PendingPayments />}
              />
              {/* END REPORTS ROUTES  */}
              {/* ERROR LOGS ROUTES */}
              <Route
                path="/logs/Tenant_Units_logs"
                element={<TenantUnitsLogs />}
              />
              <Route
                path="/logs/tenant_invicing"
                element={<TenantInvoicingLogs />}
              />
              <Route
                path="/logs/properties_logs"
                element={<PropertyCreationErrorLog />}
              />

              {/* ERROR LOGS ROUTES  */}

              <Route path="/maps" element={<Home />} />

              <Route path="/costing" element={<Costing />} />
            </>
          )}
        </Switch>
      </Router>
    </SuperProvider>
  );
}

export default App;
