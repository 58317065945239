import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import UserContext from "../../Context/UserContext";
import {set} from "lodash";
import RoleContext from "../../Context/RoleContext";
import ajaxUser from "../../util/remote/ajaxUser";
import {Toaster, toast} from "react-hot-toast";
import Alert from "../../Components/Common/Alert";
import {Link} from "react-router-dom";

export default function PaymentsMade() {
  const {userList, getUserList, page, setPage, setMeta, meta} =
    useContext(UserContext);

  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  return (
    <AppContainer title="Tenants payments">
      <Toaster position="top-center" reverseOrder={false} />
      <div class="row">
        <div class="col-lg-12">
          <h6 class="card-title mb-4 d-flex justify-content-end">
            <button
              // onClick={handleExelExport}
              class="btn ripple btn-primary btn-with-icon btn-sm">
              <i className="fa fa-file-export" /> export to excel
            </button>
          </h6>
          <div class="card custom-card">
            <div class="card-body">
              <div>
                <h6 class="card-title ">Tenants payments List</h6>
              </div>
              <div class="table-responsive">
                <table
                  id="example3"
                  class="table table-striped table-hover table-bordered text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>property</th>
                      <th>unit</th>
                      <th>Amount paid</th>
                      <th>View Tenant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>12/12/1962</td>
                      <td>Desire</td>
                      <td>Nansikombi</td>
                      <td>acacia flats</td>
                      <td>Acac__0102</td>
                      <td className="text-success"> Ugsh 1300,000</td>
                      <td>
                        {/* <button className="btn ripple btn-main-primary btn-block"> */}
                        <Link
                          className="btn btn-outline-success w-100"
                          to={`/tenants/tenant-profile/1`}>
                          {/* to={`/tenants/tenant-profile/${item.id}`}> */}
                          View Profile
                        </Link>
                        {/* </button> */}
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>12/12/1962</td>
                      <td>Desire</td>
                      <td>Mirembe</td>
                      <td>kampala flats</td>
                      <td>kamp__001</td>
                      <td className="text-success">Ugsh 1002,000</td>
                      <td>
                        {/* <button className="btn ripple btn-main-primary btn-block"> */}
                        <Link
                          className="btn btn-outline-success w-100"
                          to={`/tenants/tenant-profile/1`}>
                          {/* to={`/tenants/tenant-profile/${item.id}`}> */}
                          View Profile
                        </Link>
                        {/* </button> */}
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>12/12/1962</td>
                      <td>lucky</td>
                      <td>namirimu</td>
                      <td>kampala flats</td>
                      <td>kamp__004</td>
                      <td className="text-success">Ugsh 1000,000</td>
                      <td>
                        {/* <button className="btn ripple btn-main-primary btn-block"> */}
                        <Link
                          className="btn btn-outline-success w-100"
                          to={`/tenants/tenant-profile/1`}>
                          {/* to={`/tenants/tenant-profile/${item.id}`}> */}
                          View Profile
                        </Link>
                        {/* </button> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer " style={{left: "50%"}}>
              <button
                className="btn btn-dark"
                style={{borderRight: "1px solid yellow"}}
                onClick={setPreviousPageNumber}>
                <i className="fa fa-angle-left mr-2"></i> Prev
              </button>
              {Array.isArray(meta) &&
                meta.map((item) =>
                  page === item ? (
                    <button
                      style={{borderRight: "1px solid yellow"}}
                      className="btn btn-primary">
                      {item}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => setPageNumber(e, item)}
                      style={{borderRight: "1px solid yellow"}}
                      className="btn btn-dark">
                      {item}
                    </button>
                  )
                )}

              <button
                style={{borderRight: "1px solid yellow"}}
                className="btn btn-dark"
                onClick={setNextPageNumber}>
                Next<i className="fa fa-angle-right ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
