import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import AuthContext from "../../Context/AuthContext";
import {Link} from "react-router-dom";
import ajaxUser from "../../util/remote/ajaxUser";
import Alert from "../../Components/Common/Alert";
import {Toaster, toast} from "react-hot-toast";
import ModalComponent from "../../Components/Common/Modal";
import {ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import dictionary from "../../util/dictionary";

export default function UserProfile() {
  const {user, userId, getUserInfo} = useContext(AuthContext);

  useEffect(() => {
    getUserInfo();
  }, [userId]);

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setAge(user.age);
    setPhoneNumber(user.phone_number);
    setUsername(user.username);
    setEmail(user.email);
  }, []);

  const [isActive, setActive] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  // const [href,setHref] = useState("")

  const [imageActive, setImageActive] = useState(false);

  const [password, setPassword] = useState("");
  const [info, setInfo] = useState("");

  const handleCloseImage = () => setImageActive(false);
  const handleOpenImage = () => setImageActive(true);

  const changeTab = (e) => {
    const href = e.target.getAttribute("href").slice(1);
    const ID = document.getElementById(href);

    if (!e.target.classList.contains("active")) {
      e.target.classList.add("active");
      ID.classList.add("active");
      if (
        ID.nextElementSibling === null &&
        e.target.nextElementSibling === null
      ) {
        // console.log("there");

        ID.previousElementSibling.classList.remove("active");
        e.target.previousElementSibling.classList.remove("active");
      } else {
        // console.log("here");
        // console.log(e.target.nextElementSibling);
        // console.log(ID.nextElementSibling);

        e.target.nextElementSibling.classList.remove("active");
        ID.nextElementSibling.classList.remove("active");
      }
    }
  };

  const userProfileUpdate = async (e) => {
    e.preventDefault();
    // console.log(userId);
    if (
      userId > 0 &&
      username.length > 0 &&
      first_name.length > 0 &&
      last_name.length > 0 &&
      email.length > 0 &&
      age.length > 0 &&
      phone_number.length > 0
    ) {
      const server_response = await ajaxUser.updateUserProfile(
        userId,
        username,
        first_name,
        last_name,
        email,
        age,
        phone_number
      );
      // console.log(server_response);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getUserInfo();
      }
    } else {
      setInfo(
        <Alert type="success" message="Complete all fields and try again!!" />
      );
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    // console.log(userId);
    // console.log(password);

    if (userId > 0 && password.length > 0) {
      const server_response = await ajaxUser.updateUserPassword(
        userId,
        password
      );
      // console.log(server_response);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getUserInfo();
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
      toast.error("Complete all fields and try again!!");
    }
  };

  const updateImage = async (e) => {
    e.preventDefault();
    if (userId > 0 && image.length > 0) {
      const server_response = await ajaxUser.updateUserImage(userId, image);
      if (server_response.status === "OK") {
        setInfo("");
        toast.success(server_response.message);
        getUserInfo();
      }
    } else {
      toast.error("Please upload an image!!");
    }
  };

  const changeImage = (e) => {
    e.preventDefault();
    // console.log(e.target.files);
    let files = e.target.files;
    let reader = new FileReader();
    // console.log(files.length);
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setImage(e.target.result);
      // console.log(e.target.result);
    };
  };

  return (
    <AppContainer title="Profile">
      <Toaster />
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="card custom-card">
            <div class="card-body text-center">
              {imageActive ? (
                <div class="main-profile-overview widget-user-image text-center">
                  <div class="main-img-user">
                    <img alt="avatar" src={dictionary.apiHost + user.photo} />
                  </div>

                  {/* <div class="main-img-user"> */}
                  <form onSubmit={updateImage}>
                    <div class="custom-file">
                      <input
                        onChange={changeImage}
                        class="custom-file-input mb-3"
                        id="customFile"
                        type="file"
                      />{" "}
                      <label class="custom-file-label" for="customFile">
                        Choose file
                      </label>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          className="btn btn-success btn-sm">
                          Upload
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          onClick={handleCloseImage}
                          className="btn btn-secondary btn-sm">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div class="main-profile-overview widget-user-image text-center">
                  <div class="main-img-user">
                    <img alt="avatar" src={dictionary.apiHost + user.photo} />
                  </div>
                </div>
              )}

              <div class="item-user pro-user mt-4">
                <h4 class="pro-user-username text-dark mt-2 mb-0">
                  {user.first_name + " " + user.last_name}
                </h4>
                <p class="pro-user-desc text-muted mb-4">
                  {user.role?.role_name}
                </p>

                <Link
                  to="#"
                  onClick={handleOpenImage}
                  class="btn ripple btn-primary btn-sm">
                  <i class="far fa-edit mr-1"></i>Edit Image
                </Link>
                <ModalComponent
                  name="Change Password"
                  color="btn-primary"
                  size="sm"
                  icon="far fa-edit"
                  title="Change Password">
                  <form onSubmit={updatePassword}>
                    <div class="form-group">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        class="form-control"
                        placeholder="Enter new password"
                        type="password"
                      />
                    </div>
                    <ModalFooter>
                      <button type="submit" className="btn btn-success btn-md">
                        Save
                      </button>
                      {/* <button type="button" className='btn btn-secondary btn-md'>Close</button> */}
                    </ModalFooter>
                  </form>
                </ModalComponent>
              </div>
            </div>
          </div>
        </div>
        {/* {isActive? */}
        <div class="col-lg-8 col-md-12">
          <div class="card custom-card main-content-body-profile">
            <nav class="nav main-nav-line">
              <a
                onClick={(e) => changeTab(e)}
                class="nav-link active just"
                data-toggle="tab"
                href="#tab1over">
                Overview
              </a>
              <a
                onClick={(e) => changeTab(e)}
                class="nav-link"
                data-toggle="tab"
                href="#tab2rev">
                Edit Profile
              </a>
            </nav>

            <div class="card-body tab-content h-100">
              <div class="tab-pane active" id="tab1over">
                <div class="main-content-label tx-13 mg-b-20">
                  Personal Information
                </div>
                <div class="main-profile-contact-list main-profile-work-list">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-user"></i>
                        </div>
                        <div class="media-body">
                          <span>First name</span>
                          <div>{user.first_name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-user"></i>
                        </div>
                        <div class="media-body">
                          <span>Last name</span>
                          <div>{user.last_name}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-5">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-user"></i>
                        </div>
                        <div class="media-body">
                          <span>Username</span>
                          <div>{user.username}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-map-pin"></i>
                        </div>
                        <div class="media-body">
                          <span>Age</span>
                          <div>{user.age}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-phone"></i>
                        </div>
                        <div class="media-body">
                          <span>Phone</span>
                          <div>{user.phone_number}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div class="media">
                        <div class="media-logo bg-light text-dark">
                          <i class="fe fe-mail"></i>
                        </div>
                        <div class="media-body">
                          <span>Email</span>
                          <div>{user.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="tab2rev">
                <div class="main-content-label tx-13 mg-b-20">
                  Edit Information
                </div>
                <div class="main-profile-contact-list main-profile-work-list">
                  <form onSubmit={userProfileUpdate}>
                    <div className="row">
                      <div className="col-md-12 mb-2"></div>
                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-user"></i>
                          </div>
                          <div class="media-body">
                            <span>First name</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={first_name}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-user"></i>
                          </div>
                          <div class="media-body">
                            <span>Last name</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={last_name}
                                  onChange={(e) => setLastName(e.target.value)}
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-user"></i>
                          </div>
                          <div class="media-body">
                            <span>Username</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={username}
                                  onChange={(e) => setUsername(e.target.value)}
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-map-pin"></i>
                          </div>
                          <div class="media-body">
                            <span>Age</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={age}
                                  onChange={(e) => setAge(e.target.value)}
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-phone"></i>
                          </div>
                          <div class="media-body">
                            <span>Phone</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={phone_number}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div class="media">
                          <div class="media-logo bg-light text-dark">
                            <i class="fe fe-mail"></i>
                          </div>
                          <div class="media-body">
                            <span>Email</span>
                            <div>
                              <div class="form-group">
                                <input
                                  defaultValue={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  class="form-control"
                                  placeholder="Enter your username"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      style={{float: "right"}}
                      type="submit"
                      className="btn btn-md btn-success mb-2">
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
