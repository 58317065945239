import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import {Link} from "react-router-dom";
import ajaxProperty from "../util/remote/ajaxProperty";

import ajaxUser from "../util/remote/ajaxUser";

import {Toaster, toast} from "react-hot-toast";
import Loader from "../Components/Common/Loader";
import Select from "react-select";
import AuthContext from "../Context/AuthContext";
import PropertyImageSlider from "../Components/Property/PropertyImageSlider";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import PropertyLocation from "../Components/Property/PropertyLocations";
import dictionary from "../util/dictionary";
import functions from "../util/functions";

export default function PropertiesPage() {
  // const {propertyList,getPropertyList} = useContext(PropertyContext);
  const {user, userId, getUserInfo} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [secondPropertyList, setSecondPropertyList] = useState(false);
  const id = functions.sessionGuard();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  useEffect(() => {
    getPropertyByLandlord();
  }, [page]);
  const getPropertyByLandlord = async () => {
    const server_response = await ajaxProperty.fetchPropertyList(id, page);

    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setSecondPropertyList(server_response.details.list);
    }
  };

  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    console.log(item);
    setPage(item);
  };

  return (
    <AppContainer title="Properties">
      <Toaster position="top-center" reverseOrder={false} />

      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="flex-row mb-4">
              <Nav.Item>
                <Nav.Link size="sm" eventKey="first">
                  Properties List{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Property Locations</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div class="row">
                  {!secondPropertyList ? (
                    <div class="col-lg-6 col-md-6">
                      <h5 className="text-center align-items-center align-content-center justify-content-center">
                        No properties found
                      </h5>
                    </div>
                  ) : (
                    secondPropertyList.map((item, key) => (
                      <div class="col-lg-6 col-md-6">
                        <div
                          class="card card-aside custom-card"
                          style={{width: "100%", height: "40rem !important"}}>
                          {item.main_pic === "upload/" ? (
                            <a
                              href="#"
                              class="card-aside-column  cover-image"
                              data-image-src={
                                dictionary.apiHost + "img/house.png"
                              }
                              style={{
                                background: `url(${
                                  dictionary.apiHost + "img/house.png"
                                }) center center`,
                              }}></a>
                          ) : (
                            <a
                              href="#"
                              class="card-aside-column  cover-image"
                              data-image-src={
                                dictionary.apiHost + item.main_pic
                              }
                              style={{
                                background: `url(${
                                  dictionary.apiHost + item.main_pic
                                }) center center`,
                              }}></a>
                          )}
                          <div
                            class="card-body"
                            style={{color: "white !important"}}>
                            <h4>
                              <Link to={`property-profile/${item.id}`}>
                                {item.name}
                              </Link>
                            </h4>
                            <div class="" style={{color: "black !important"}}>
                              {item.description}
                            </div>
                            <div class="d-flex align-items-center pt-3 mt-auto">
                              <div style={{color: "#8760FB"}}>
                                <small class="d-block mb-1">
                                  <i className="fa fa-expand"></i> Square
                                  Metres: {item.units_number}
                                </small>
                                <small class="d-block">
                                  <i className="fa fa-home"></i> Units:{" "}
                                  {item.units_number}
                                </small>
                              </div>
                              <div class="ml-auto text-muted">
                                {/* <a href="#" class="icon d-none d-md-inline-block ml-3">View Location</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {secondPropertyList ? (
                  <PropertyLocation />
                ) : (
                  <h5>No properties found</h5>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div
        className="align-items-center justify-content-center pos-absolute"
        style={{left: "50%"}}>
        <button
          className="btn btn-dark"
          style={{borderRight: "1px solid yellow"}}
          onClick={setPreviousPageNumber}>
          <i className="fa fa-angle-left mr-2"></i> Prev
        </button>
        {Array.isArray(meta) &&
          meta.map((item) =>
            page === item ? (
              <button
                style={{borderRight: "1px solid yellow"}}
                className="btn btn-primary">
                {item}
              </button>
            ) : (
              <button
                onClick={(e) => setPageNumber(e, item)}
                style={{borderRight: "1px solid yellow"}}
                className="btn btn-dark">
                {item}
              </button>
            )
          )}

        <button
          style={{borderRight: "1px solid yellow"}}
          className="btn btn-dark"
          onClick={setNextPageNumber}>
          Next<i className="fa fa-angle-right ml-2"></i>
        </button>
      </div>
      {/* <button style={{left:'50%'}} className='btn btn-info mt-4 pos-absolute' >Load more...</button> */}
    </AppContainer>
  );
}
