import { useEffect, useMemo, useState } from "react";
import { GoogleMap,useJsApiLoader, useLoadScript, MarkerF } from "@react-google-maps/api";

export default function Maps() {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAIJvIlzd7SAA1rJcedlCidyof2pWgeLE8",
      });
      const center = useMemo(() => ({ lat: 0.313029, lng: 32.5167236 }), []);
const containerStyle = {
    width: '100%',
    height: '20vh'
  };
// useEffect(() => {
//   setMarker(<MarkerF
//     icon={"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"}
//     position={{lat:"0.3397002", lng:"32.5712095"}}
//   />)
// }, [])

    // const [marker,setMarker] = useState()
    
  return (
    <>

   {!isLoaded? <div>Loading...</div>:
    // <h1>maps</h1>
    // <div style={{ height: '100vh', width: '100%' }}>
    <GoogleMap zoom={10} center={center}
     mapContainerStyle={containerStyle}

    >
        <MarkerF
        icon={<i className="fe fe-map-pin"></i>}
        position={{lat:0.3397002, lng:32.5712095}}
      />
      {/* <Marker position={{ lat: 0.3397002, lng: 32.5712095 }} /> */}
    </GoogleMap>
    // </div>
    // <h1>maps</h1>
  }
    </>
  );
}