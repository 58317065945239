import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import ajaxUser from "../../util/remote/ajaxUser";
import Loader from "../Common/Loader";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function Header() {
  const {user} = useContext(AuthContext);
  const [searchTenantResults, setSearchTenantResults] = useState(false);
  const [searchPropertyResults, setSearchPropertyResults] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );
  const toggleTheme = (e) => {
    // console.log(localStorage.getItem('theme'))

    if (e.target.getAttribute("id") === "dark") {
      setTheme("dark-theme");
      //    console.log(document.getElementById("pin-div").firstElementChild.)
      document
        .getElementsByClassName("main-body")[0]
        .classList.remove("light-theme");
    } else {
      setTheme("light-theme");
      document
        .getElementsByClassName("main-body")[0]
        .classList.remove("dark-theme");
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.getElementsByClassName("main-body")[0].classList.add(theme);
  }, [theme]);

  const nav = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("myrentals@user");
    localStorage.removeItem("myrentals@welcomemessage");
    nav("/login");
    window.location.reload();
  };

  const toggleSearch = (e) => {
    const profile = document.getElementById("profile");
    const notification = document.getElementById("notification");
    document.getElementById("searchInput").focus();
    if (
      profile.classList.contains("show") ||
      notification.classList.contains("show")
    ) {
      profile.classList.remove("show");
      notification.classList.remove("show");
    }
    if (e.target.parentElement.parentElement.classList.contains("show")) {
      e.target.parentElement.parentElement.classList.remove("show");
    } else {
      e.target.parentElement.parentElement.classList.add("show");
    }
  };

  const dropdown = (e) => {
    e.preventDefault();
    const search = document.getElementById("search");
    if (search.classList.contains("show")) {
      search.classList.remove("show");
    }
    if (!(e.target.offsetParent.parentElement.classList[2] === "show")) {
      e.target.offsetParent.parentElement.classList.add("show");
      e.target.offsetParent.parentElement.previousElementSibling.classList.remove(
        "show"
      );
      e.target.offsetParent.parentElement?.nextElementSibling?.classList.remove(
        "show"
      );
    } else {
      e.target.offsetParent.parentElement.classList.remove("show");
    }
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    let body = document.getElementsByTagName("body");
    if (body[0].classList.contains("main-sidebar-hide")) {
      body[0].classList.remove("main-sidebar-hide");
    } else {
      body[0].classList.add("main-sidebar-hide");
    }
  };

  const search = async (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    // console.log(e.target.value)
    if (e.target.value.length > 0) {
      setLoading(true);
      const server_response = await ajaxUser.search(
        e.target.value,
        functions.sessionGuard()
      );
      if (server_response.status === "OK") {
        // console.log(server_response.details.property);
        setTimeout(() => {
          setSearchPropertyResults(server_response.details.property);
          setSearchTenantResults(server_response.details.tenants);
          setLoading(false);
        }, 1000);
      }
    }
  };

  const changeTab = (e) => {
    const href = e.target.getAttribute("id").slice(1);
    console.log(href);
    const ID = document.getElementById(href);
    console.log(ID);

    if (!e.target.classList.contains("active")) {
      e.target.classList.add("active");
      ID.classList.add("active");
      if (
        ID.nextElementSibling === null &&
        e.target.nextElementSibling === null
      ) {
        ID.previousElementSibling.classList.remove("active");
        e.target.previousElementSibling.classList.remove("active");
      } else {
        e.target.nextElementSibling.classList.remove("active");
        ID.nextElementSibling.classList.remove("active");
      }
    }
  };
  const imageUrl = dictionary.apiHost;

  return (
    <div class="main-header side-header sticky">
      <div class="container-fluid">
        <div class="main-header-left">
          <Link class="main-logo d-lg-none" href="index-2.html">
            <img
              style={{height: "70px"}}
              src={
                process.env.PUBLIC_URL + "/template/assets/img/brand/logo.png"
              }
              class="header-brand-img desktop-logo"
              alt="logo "
            />
            <img
              style={{height: "70px"}}
              src={
                process.env.PUBLIC_URL + "/template/assets/img/brand/icon.png"
              }
              class="header-brand-img icon-logo"
              alt="logo "
            />
            <img
              style={{height: "70px"}}
              src={
                process.env.PUBLIC_URL +
                "/template/assets/img/brand/logo-light.png"
              }
              class="header-brand-img desktop-logo theme-logo"
              alt="logo "
            />
          </Link>
          <Link
            class="main-header-menu-icon"
            href="#"
            id="mainSidebarToggle"
            onClick={toggleSidebar}>
            <span></span>
          </Link>
        </div>
        <div class="main-header-right">
          <div className="change-theme">
            {theme === "light-theme" ? (
              <i
                id="dark"
                data-placement="top"
                data-toggle="tooltip-primary"
                title="Switch to dark theme"
                onClick={toggleTheme}
                style={{fontSize: "16px", float: "left"}}
                className="fa fa-moon"></i>
            ) : (
              <i
                id="light"
                data-placement="top"
                data-toggle="tooltip-light"
                title="Switch to light theme"
                onClick={toggleTheme}
                style={{fontSize: "16px"}}
                className="fe fe-sun bg-light"></i>
            )}
          </div>
          <div class="dropdown d-md-flex header-search" id="search">
            <Link class="nav-link icon header-search" onClick={toggleSearch}>
              <i class="fe fe-search"></i>
            </Link>
            <div class="dropdown-menu" style={{width: "700px"}}>
              <div class="main-form-search p-2">
                <input
                  onChange={search}
                  id="searchInput"
                  class="form-control"
                  placeholder="Search"
                  type="search"
                />
                <button class="btn">
                  <i class="fe fe-search"></i>
                </button>
              </div>
              {searchData === "" ? (
                ""
              ) : loading ? (
                <div class="text-center">
                  <div class="lds-circle">
                    <div></div>
                  </div>
                </div>
              ) : (
                <div class="border">
                  <div class="card-body ">
                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="card-title">
                          <i className="fa fa-hotel"></i> Properties
                        </h5>

                        <div class="user-manager scroll-widget border-top">
                          <div class="table-responsive">
                            <table class="table mg-b-0  table-hover">
                              <tbody>
                                {Array.isArray(searchPropertyResults)
                                  ? Array.isArray(searchPropertyResults) &&
                                    searchPropertyResults.map((item, key) => (
                                      <tr
                                        className="border-top border-bottom mb-1 mt-1"
                                        key={key + 1}
                                        style={{
                                          border: "1px solid yellow !important",
                                        }}>
                                        <div class="thumb ">
                                          <img
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                            alt="avatar"
                                            src={
                                              dictionary.apiHost + item.main_pic
                                            }
                                          />
                                        </div>
                                        <td class="">
                                          <Link
                                            to={`/properties/property-profile/${item.id}`}>
                                            <h6 class="mg-b-0 mb-1">
                                              {item.name}
                                            </h6>
                                            <small class="tx-11 tx-gray-500">
                                              <i className="fa fa-expand"></i>{" "}
                                              Square metres: {item.sq_metres}
                                            </small>
                                            <br />
                                            <small class="tx-11 tx-gray-500">
                                              <i className="fa fa-home"></i>{" "}
                                              Units: {item.units_number}
                                            </small>
                                            <br />
                                          </Link>
                                        </td>
                                      </tr>
                                    ))
                                  : searchPropertyResults}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h5 className="card-title">
                          <i className="fa fa-users"></i> Tenants
                        </h5>
                        <div
                          class="user-manager scroll-widget border-top "
                          style={{overflowY: "auto"}}>
                          <div class="table-responsive">
                            <table class="table mg-b-0 table-hover">
                              <tbody style={{overflowY: "auto"}}>
                                {Array.isArray(searchTenantResults)
                                  ? Array.isArray(searchTenantResults) &&
                                    searchTenantResults.map((item, key) => (
                                      <tr
                                        className="border-top border-bottom hover"
                                        key={key + 1}
                                        style={{
                                          border: "1px solid yellow !important",
                                        }}>
                                        <div class="thumb">
                                          <img
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                            alt="avatar"
                                            src={
                                              imageUrl +
                                              "uploads/" +
                                              item.picture
                                            }
                                          />
                                        </div>
                                        <td class="">
                                          <Link
                                            to={`/tenants/tenant-profile/${item.id}`}>
                                            <h6 class="mg-b-0 mb-1">
                                              {" "}
                                              {item.name}
                                            </h6>
                                            <small class="tx-17 tx-gray-500">
                                              <i className="fa fa-phone"></i>{" "}
                                              {item.phone_number}
                                            </small>
                                            <br />
                                            <small class="tx-white tx-11 tx-gray-500">
                                              <i className="fa fa-envelope"></i>{" "}
                                              {item.email}
                                            </small>
                                          </Link>
                                        </td>
                                      </tr>
                                    ))
                                  : searchTenantResults}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={dropdown}
            id="notification"
            class="dropdown main-header-notification">
            <Link class="nav-link icon" href="#">
              <i class="fe fe-bell"></i>
              <span class="pulse bg-danger"></span>
            </Link>
            <div class="dropdown-menu">
              <div class="header-navheading">
                <p class="main-notification-text">
                  You have 1 unread notification
                  <span class="badge badge-pill badge-primary ml-3">
                    View all
                  </span>
                </p>
              </div>
              <div class="main-notification-list">
                {/* <div class="media new">
                  <div class="main-img-user online">
                    <img alt="avatar" src="assets/img/users/5.jpg" />
                  </div>
                  <div class="media-body">
                    <p>
                      Congratulate <strong>Olivia James</strong> for New
                      template start
                    </p>
                    <span>Oct 15 12:32pm</span>
                  </div>
                </div>
                <div class="media">
                  <div class="main-img-user">
                    <img alt="avatar" src="assets/img/users/2.jpg" />
                  </div>
                  <div class="media-body">
                    <p>
                      <strong>Joshua Gray</strong> New Message Received
                    </p>
                    <span>Oct 13 02:56am</span>
                  </div>
                </div>
                <div class="media">
                  <div class="main-img-user online">
                    <img alt="avatar" src="assets/img/users/3.jpg" />
                  </div>
                  <div class="media-body">
                    <p>
                      <strong>Elizabeth Lewis</strong> added new schedule
                      realease
                    </p>
                    <span>Oct 12 10:40pm</span>
                  </div>
                </div> */}
              </div>
              <div class="dropdown-footer">
                <Link href="#">View All Notifications</Link>
              </div>
            </div>
          </div>
          <div class="dropdown main-profile-menu" id="profile">
            <Link onClick={dropdown} class="main-img-user" href="#">
              <img
                alt="avatar"
                // src={"http://localhost/MY-Rentals/img/avatar.png"}
                src={imageUrl + user.photo}
              />
            </Link>
            <div class="dropdown-menu">
              <div class="header-navheading">
                {
                  <>
                    <h6 class="main-notification-title">
                      {user.first_name + " " + user.last_name}
                    </h6>
                    <p class="main-notification-text">{user.role?.role_name}</p>
                  </>
                }
              </div>
              <Link class="dropdown-item border-top" to="/profile">
                <i class="fe fe-user"></i> My Profile
              </Link>

              <Link class="dropdown-item" to="" onClick={onLogout}>
                <i class="fe fe-power"></i> Sign Out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
