import React, { useState } from 'react'
import TextInput from '../Common/TextInput';
import SystemModal from '../Common/SystemModal';
import ActivityLoader from '../Common/ActivityLoader';
import { Toaster, toast } from 'react-hot-toast';
import ajaxUnit from '../../util/remote/ajaxUnit';

export default function UpdateImageModal(props) {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [unitImage, setUnitImage] = useState("");
    const [description, setDescription] = useState(props.description);
    const [location, setLocation] = useState("");

    const updateImage = async(e) =>{
        e.preventDefault()
        if(props.id>0 && props.unit_id>0 && unitImage.length>0 && description.length>0){
          const server_response = await ajaxUnit.updateUnitImage(props.id,props.unit_id,unitImage,description)
          if(server_response.status==="OK"){
              setDescription("")
              setUnitImage("")
              props.getUnitImage()
              toast.success(server_response.message)
          }
        }
        else{
          toast.error("Complete All Fields And Try Again !!")
        }

        
    }


    const changeImage = (e) => {
        e.preventDefault();
        // setMainPicName(e.target.value)
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
    
        reader.onload = (e) => {
          const newItem = { file: e.target.result };
    
          setUnitImage(e.target.result);
          // console.log(e.target.result);
        };
        // console.log(image);
      };

      const RenderFooter=(controls)=>{

        if(loading){
            return <ActivityLoader/>
        }else{
    
            return <> 
                    <button className="btn ripple btn-dark" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn ripple btn-success`} 
                        onClick={updateImage}>Upload Image</button>
                    </>
        }
    }

  return (
    <>
    <div>
        <Toaster />
    </div>
    <SystemModal
            title="Edit current unit image"
            id="model-new-property"
            size="md"
            footer={RenderFooter}
        >
              <TextInput
                label="Unit Image"
                type="file"
                classname="form-control"
                onChange={(e)=>changeImage(e)}
            />

             <TextInput
                label="Image Description"
                value={description}
                type="textarea"
                placeholder="Enter image description"
                onChange={(e)=>setDescription(e.target.value)}
            />

            {/* {RenderLocation()} */}


        </SystemModal>
    </>
  )
}
