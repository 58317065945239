import React, {useEffect, useState} from "react";
import ajaxUnit from "../../util/remote/ajaxUnit";
import Card from "../Common/Card";
import functions from "../../util/functions";

export default function UnitsOccupied() {
  useEffect(() => {
    occupiedUnit();
  }, []);

  const [unitOccupied, setUnitOccupied] = useState(false);

  const occupiedUnit = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxUnit.unitOccupied(data);
    if (server_response.status === "OK") {
      setUnitOccupied(server_response.details);
    }
  };

  return (
    <>
      <Card
        title="Total units Occupied"
        value={unitOccupied ? unitOccupied.total_p : "..."}
        icon="fa fa-users"
        color="#8760FB"
        link="#"
        bottomValue={3}
      />
    </>
  );
}
