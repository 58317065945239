import React, {useEffect, useState} from "react";
import ajaxProperty from "../../util/remote/ajaxProperty";
import dictionary from "../../util/dictionary";

export default function ImageSlider(props) {
  const [propertyImages, setPropertyImages] = useState(false);

  useEffect(() => {
    getPropertyImage();
  }, [props.id]);

  const getPropertyImage = async () => {
    const server_response = await ajaxProperty.fetchPropertyImage(props.id);
    if (server_response.status === "OK") {
      setPropertyImages(server_response.details);
    } else {
      setPropertyImages("404");
    }
  };

  const showImage = (e) => {
    const imageValue = e.target.parentElement.getAttribute("data-slide-to");
    const carousel = document.getElementById(imageValue);
    if (e.target.parentElement.classList.contains("active")) {
    } else {
      Object.values(carousel.parentElement.children).map((value, index) => {
        if (value.classList.contains("active")) {
          // setTimeout(() => {
          // 	value.classList.add("carousel-item-right")
          // 	carousel.classList.add("carousel-item-prev")
          // 	carousel.classList.add("carousel-item-left")

          // }, 500);
          // value.classList.remove("carousel-item-right")
          // carousel.classList.remove("carousel-item-prev")
          // carousel.classList.remove("carousel-item-left")
          value.classList.remove("active");
          // e.target.parentElement.classList.add("active")
          carousel.classList.add("active");
        }
      });
    }

    // {Object.values(carousel.parentElement.children).map((value, index)=>{
    // if(value.classList.contains('active')){
    // 	console.log(value)
    // 	console.log(e.target.offsetParent)
    // }

    // if(carousel.parentElement.children.length===index){
    // console.log(carousel.parentElement.children.length===index)
    // 		}
    // 		else{
    // 			console.log("first")
    // 		}

    // }) }
  };

  // {Array.isArray(propertyImages) &&
  //     propertyImages.map((item, key) => (
  //       <div>
  //         <img
  //           style={{ height: "350px" }}
  //           onClick={(e) => toggleModal(e, item)}
  //           alt={item.description}
  //           class="img-thumbnail w-100"
  //           src={
  //            dictionary.apiHost +
  //             item.photo
  //           }
  //         />
  //       </div>
  //     ))}
  return (
    <div class="col-lg-12 col-md-12">
      <div class="card custom-card productdesc">
        <div class="card-body h-100">
          <div class="productdec text-center">
            <div class="bg-light p-5 text-center">
              <div class="product-carousel">
                <div id="carousel" class="carousel slide" data-ride="false">
                  <div class="carousel-inner">
                    {Array.isArray(propertyImages) &&
                      propertyImages.map((item, key) =>
                        key === 0 ? (
                          <div class="carousel-item active" id={key}>
                            <img
                              src={dictionary.apiHost + item.photo}
                              alt="img"
                              class="wd-sm-350"
                            />
                          </div>
                        ) : (
                          <div class="carousel-item" id={key}>
                            <img
                              src={dictionary.apiHost + item.photo}
                              alt="img"
                              class="wd-sm-350"
                            />
                          </div>
                        )
                      )}
                  </div>
                </div>
                <div class="clearfix carousel-slider">
                  <div
                    id="thumbcarousel"
                    class="carousel slide"
                    data-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        {Array.isArray(propertyImages) &&
                          propertyImages.map((item, key) => (
                            <div
                              onClick={showImage}
                              data-target="#carousel"
                              data-slide-to={key}
                              key={key}
                              class="thumb">
                              <img
                                key={key}
                                src={dictionary.apiHost + item.photo}
                                alt="img"
                                class="wd-sm-350"
                              />
                            </div>
                          ))}
                        {/* <div data-target="#carousel" data-slide-to="1" class="thumb"><img src="https://images.pexels.com/photos/220429/pexels-photo-220429.jpeg?auto=compress&cs=tinysrgb&w=600" alt="img" class="wd-sm-350"/></div>
																<div data-target="#carousel" data-slide-to="2" class="thumb"><img src="https://images.pexels.com/photos/1554613/pexels-photo-1554613.jpeg?auto=compress&cs=tinysrgb&w=600" alt="img" class="wd-sm-350"/></div>
																<div data-target="#carousel" data-slide-to="3" class="thumb"><img src="https://images.pexels.com/photos/33263/highspeed-photography-water-drop-of-water-alien-dance.jpg?auto=compress&cs=tinysrgb&w=600" alt="img" class="wd-sm-350"/></div>
																<div data-target="#carousel" data-slide-to="4" class="thumb"><img src="https://images.pexels.com/photos/1121782/pexels-photo-1121782.jpeg?auto=compress&cs=tinysrgb&w=600" alt="img" class="wd-sm-350"/></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
