import React, {useContext, useEffect, useState} from "react";

import ajaxTenant from "../../util/remote/ajaxTenant";

import {Link} from "react-router-dom";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function ListRecentTenants() {
  //   const {recentTenantLists, getTenants} = useContext(TenantContext);

  const [meta, setMeta] = useState("");

  const [recentTenantLists, setRecentTenantList] = useState(false);
  const getRecentTenants = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxTenant.fetchRecentTenantList(data);
    if (server_response.status === "OK") {
      setRecentTenantList(server_response.details);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  useEffect(() => {
    getRecentTenants();
  }, []);

  return (
    // <div class="col-md-4">

    <div class="card custom-card" style={{height: "30rem"}}>
      <div class="card-body p-3">
        <div>
          <h6 class="card-title mb-1">
            Recent Tenants
            {/* (This week) */}
          </h6>
        </div>
      </div>
      <div class="user-manager scroll-widget border-top">
        <div class="table-responsive">
          <table class="table mg-b-0">
            <tbody>
              {Array.isArray(recentTenantLists) &&
                recentTenantLists.map((item, key) => (
                  <>
                    <Link to={`/tenants/tenant-profile/${item.id}`}>
                      <tr>
                        <td class="bd-t-0">
                          <div class="main-img-user">
                            <img
                              alt="avatar"
                              src={dictionary.apiHost + item.picture}
                            />
                          </div>
                        </td>
                        <td class="bd-t-0">
                          <h6 class="mg-b-0">{item.name}</h6>
                          <small class="tx-11 tx-gray-500">
                            {item.phone_number}
                          </small>
                        </td>
                        {/* <td class="bd-t-0">
														<h6 class="mg-b-0 font-weight-bold">34%</h6><small class="tx-11 tx-gray-500">Conversion Rate</small>
													</td> */}
                      </tr>
                    </Link>
                    <br />
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
