import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import UserContext from "../../Context/UserContext";
import { set } from "lodash";
import RoleContext from "../../Context/RoleContext";
import ajaxUser from "../../util/remote/ajaxUser";
import { Toaster, toast } from "react-hot-toast";
import Alert from "../../Components/Common/Alert";

export default function UsersPage() {
  const { userList, getUserList,page,setPage,setMeta,meta } = useContext(UserContext);
  const { roleList } = useContext(RoleContext);
  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [roleID, setRoleID] = useState("");
  const [userID, setUserID] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [image, setImage] = useState("");
  const [info, setInfo] = useState("");
  const [password, setPassword] = useState("");


    console.log(userList)
    const setNextPageNumber = () =>{
      if(meta.length===page){
        
      }
      else{
        setPage(page+1)
      }
      
    }

    const setPreviousPageNumber = () =>{
      if(page===1){
        
      }
      else{
        setPage(page-1)
      }
      
    }
    const setPageNumber = (e,item) =>{
      console.log(item)
      setPage(item)
    }


  const changeImage = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setImage(e.target.result);
     
    };
  
  };

  //USER CREATE FUNCTION
  const createUser = async (e) => {
    e.preventDefault();
 
    if (userID > 0 ||roleID > 0 ||first_name.length > 0 ||last_name.length > 0 ||email.length > 0 ||
      username.length > 0 ||image.length > 0 ||phone_number.length > 0 ||age.length > 0 ||password.length > 0
    ) {
      const server_response = await ajaxUser.createUser(username,password,first_name,last_name,roleID,
                                                        userID,email,age,phone_number,image);
   
      if (server_response.status === "OK") {
        setUsername("");
        setFirstName("");
        setLastName("");
        setImage("");
        setRoleID("");
        setUserID("");
        setPassword("");
        setPhoneNumber("");
        setAge("");
        setInfo(
          <Alert
            type="success"
            message={server_response.message}
            icon={"fe-check-circle"}
          />
        );
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  return (
    <AppContainer title="Users">
      <Toaster position="top-center" reverseOrder={false} />
      <div class="row">
        {active ? (
          <div class="col-lg-12 col-md-12">
            <h6 class="card-title mb-4 d-flex justify-content-end">
              <button
                onClick={handleInActive}
                class="btn ripple btn-secondary btn-with-icon btn-sm"
              >
                Cancel<i class="fe fe-x"></i>
              </button>
            </h6>
            <div class="card custom-card">
              <div class="card-body">
                <div>
                  <h6 class="card-title mb-4">Add New User </h6>
                </div>
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-xl-12">
                    <div class="">
                      {info}
                      <form onSubmit={createUser}>
                        <div class="form-group">
                          <div class="row row-sm">
                            <div class="col-sm-6 mb-3">
                              <label class="">First Name</label>
                              <input
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                class="form-control"
                                required
                                type="text"
                              />
                            </div>

                            <div class="col-sm-6 mb-3">
                              <label class="">Last Name</label>
                              <input
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                class="form-control"
                                required
                                type="text"
                              />
                            </div>

                            <div class="col-sm-6">
                              <label class="">Username</label>
                              <input
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                class="form-control"
                                required
                                type="text"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label class="">Password</label>
                              <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                class="form-control"
                                required
                                type="password"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="">Role</label>

                          <select
                            value={roleID}
                            onChange={(e) => setRoleID(e.target.value)}
                            class="form-control w-100 select2"
                            data-parsley-class-handler="#slWrapper"
                            data-parsley-errors-container="#slErrorContainer"
                            data-placeholder="Choose one"
                            required=""
                          >
                            <option label="Select a role..."></option>
                            {Array.isArray(roleList) &&
                              roleList.map((item) => (
                                <option value={item.role_id}>
                                  {item.role_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div class="form-group">
                          <div class="row row-sm">
                            <div class="col-sm-4">
                              <label class="">Age</label>
                              <input
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                class="form-control"
                                required
                                type="number"
                                min={0}
                              />
                            </div>

                            <div class="col-sm-4">
                              <label class="">Phone Number</label>
                              <input
                                value={phone_number}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="e.g. 0701234567"
                                required
                                class="form-control"
                                type="tel"
                              />
                              <span class="validity"></span>
                            </div>

                            <div class="col-sm-4">
                              <label class="">Email</label>
                              <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                class="form-control"
                                required
                                type="email"
                              />
                              <small style={{ color: "red" }}>
                                
                              </small>
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="row row-sm">
                            {roleID === "4" ? (
                              <div class="col-sm-6">
                                <label class="">Landlord</label>

                                <select
                                  value={userID}
                                  onChange={(e) => setUserID(e.target.value)}
                                  class="form-control w-100 select2"
                                  data-parsley-class-handler="#slWrapper"
                                  data-parsley-errors-container="#slErrorContainer"
                                  data-placeholder="Choose one"
                                  required
                                >
                                  <option label="Select a landlord..."></option>
                                  {Array.isArray(userList) &&
                                    userList.map((item) => (
                                      <option value={item.id}>
                                        {item.first_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : (
                              ""
                            )}
                            <div class="col-sm-6">
                              <label>Upload image</label>
                              <div class="custom-file">
                                <input
                                  onChange={changeImage}
                                  class="custom-file-input"
                                  id="customFile"
                                  type="file"
                                />{" "}
                                <label
                                  class="custom-file-label"
                                  for="customFile"
                                >
                                  Choose file
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button class="btn ripple btn-main-primary btn-block">
                          Register User
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="col-lg-12">
            <h6 class="card-title mb-4 d-flex justify-content-end">
              <button
                onClick={handleActive}
                class="btn ripple btn-primary btn-with-icon btn-sm"
              >
                <i class="fe fe-plus"></i> Add User
              </button>
            </h6>
            <div class="card custom-card">
              <div class="card-body">
                <div>
                  <h6 class="card-title ">User List</h6>
                </div>
                <div class="table-responsive">
                  <table
                    id="example3"
                    class="table table-striped table-bordered text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Age</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(userList) &&
                        userList.map((item, key) => (
                          <tr>
                            <td>
                              <img />
                            </td>
                            <td>{item.first_name}</td>
                            <td>{item.last_name}</td>
                            <td>{item.username}</td>
                            <td>{item.role.role_name}</td>
                            <td>{item.age}</td>
                            <td>{item.phone_number}</td>
                            <td>{item.email}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer ' style={{left:'50%'}}>
      
      
      <button className='btn btn-dark' style={{borderRight:'1px solid yellow'}} onClick={setPreviousPageNumber}><i className='fa fa-angle-left mr-2'></i> Prev</button>
            {Array.isArray(meta) && meta.map((item)=>
            page===item?
            <button  style={{borderRight:'1px solid yellow'}} className='btn btn-primary'>{item}</button>
            :
            <button onClick={(e)=>setPageNumber(e,item)} style={{borderRight:'1px solid yellow'}} className='btn btn-dark'>{item}</button>
            )}
  
  
            <button style={{borderRight:'1px solid yellow'}} className='btn btn-dark' onClick={setNextPageNumber}>Next<i className='fa fa-angle-right ml-2'></i></button>
      </div>
            </div>
            
          </div>
        )}
      </div>
    </AppContainer>
  );
}
