import React, {useContext, useEffect, useState} from "react";

import {Toaster, toast} from "react-hot-toast";
import Select from "react-select";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxProperty from "../../util/remote/ajaxProperty";
import AuthContext from "../../Context/AuthContext";
import AppContainer from "../Structure/AppContainer";
import Loader from "../Common/Loader";

const NewProperty = () => {
  // const [loading, setLoading] = useState(false)
  // const [propertName, setProperyName] = useState("");
  // const [location, setLocation] = useState("");
  const {user, userId, loggedInId} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [secondPropertyList, setSecondPropertyList] = useState(false);
  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [units_number, setUnitsNumber] = useState("");
  const [sq_metres, setSqMetres] = useState("");
  const [lat, setLat] = useState("");
  const [longitude, setLongitude] = useState("");
  const [landlord, setLandlord] = useState(
    user.landlord_id ? user.landlord_id.id : user.id
  );
  const [main_pic, setMainPic] = useState("");
  const [mainPicName, setMainPicName] = useState(
    "Choose main picture for Property..."
  );
  const [landlordList, setLandlordList] = useState("");
  const [management, setManagement] = useState("");

  const changeImage = (e) => {
    e.preventDefault();
    setMainPicName(e.target.value);
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const newItem = {file: e.target.result};
      setMainPic(e.target.result);
    };
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  const getLandlords = async () => {
    const server_response = await ajaxUser.fetchLandlords();

    if (server_response.status === "OK") {
      //store results
      setLandlordList(server_response.details);
    } else {
      //communicate error
      setLandlordList("404");
    }
  };

  useEffect(() => {
    getLandlords();
  }, []);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  useEffect(() => {
    if (!lat || !longitude) {
      getLocation();
    }
  }, [lat, longitude]);

  const handleAdd = async (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      description.length > 0 &&
      units_number > 0 &&
      sq_metres > 0 &&
      lat > 0 &&
      longitude > 0 &&
      landlord > 0 &&
      management.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProperty.createProperty(
        name,
        description,
        units_number,
        sq_metres,
        lat,
        longitude,
        landlord,
        main_pic,
        management
      );

      setTimeout(
        () => setLoading(false),

        1000
      );
      if (server_response.status === "OK") {
        setName("");
        setDescription("");
        setUnitsNumber("");
        setSqMetres("");
        setLat("");
        setLongitude("");
        setMainPic("");
        setMainPicName("Choose main picture for Property...");
        setLandlord("");
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <AppContainer title="Add New Property">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="card-title mb-4">Add New Property </h6>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="">
                  {loading && <Loader />}
                  <form onSubmit={handleAdd}>
                    <div class="form-group">
                      <div class="row row-sm">
                        <div class="col-sm-12">
                          <label htmlFor="">
                            Property Name:<span class="tx-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                          />
                        </div>

                        <div class="col-sm-12 mt-2">
                          <label htmlFor="">
                            Property Description:
                            <span class="tx-danger">*</span>
                          </label>
                          <textarea
                            class="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            aria-label="With textarea"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row row-sm">
                        <div class="col-sm-6">
                          <label htmlFor="">
                            Number of Units:<span class="tx-danger">*</span>
                          </label>
                          <input
                            type="number"
                            value={units_number}
                            onChange={(e) => setUnitsNumber(e.target.value)}
                            min={1}
                            className="form-control"></input>
                        </div>
                        <div class="col-sm-6">
                          <label htmlFor="">
                            Square Metres:<span class="tx-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={sq_metres}
                            onChange={(e) => setSqMetres(e.target.value)}
                            className="form-control"></input>
                        </div>
                        <div class="col-sm-6  mt-2">
                          <label htmlFor="">Latitude:</label>
                          <input
                            type="text"
                            value={lat}
                            onChange={(e) => setLat(e.target.value)}
                            className="form-control"
                            readOnly
                          />
                        </div>
                        <div class="col-sm-6  mt-2">
                          <label htmlFor="">Longitude:</label>
                          <input
                            type="text"
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row row-sm">
                        <div class="col-sm-6">
                          <label htmlFor="">
                            Main Picture:<span class="tx-danger">*</span>
                          </label>
                          <div class="custom-file">
                            <input
                              class="custom-file-input"
                              onChange={(e) => changeImage(e)}
                              id="customFile"
                              type="file"
                            />{" "}
                            <label class="custom-file-label" for="customFile">
                              {mainPicName}
                            </label>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <label htmlFor="">
                            Management Type:<span class="tx-danger">*</span>
                          </label>
                          <select
                            onChange={(e) => setManagement(e.target.value)}
                            value={management}
                            class="form-control w-100 select2"
                            data-parsley-class-handler="#slWrapper"
                            data-parsley-errors-container="#slErrorContainer"
                            data-placeholder="Choose one">
                            <option default selected>
                              Choose management type
                            </option>
                            <option value="Self-Managed">Self Managed</option>
                            <option value="Delegate Management">
                              Delegate Management
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row row-sm">
                        {user.role?.role_name === "Landlord" ||
                        user.role?.role_name === "Manager" ? (
                          <div class="col-md-12 mt-1 mb-3">
                            <label htmlFor="">
                              Name of Landlord:
                              <span class="tx-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => setLandlord(e.id)}
                              getOptionLabel={(option) => option.first_name}
                              getOptionValue={(option) => option.id}
                              isSearchable
                              options={
                                Array.isArray(landlordList) ? landlordList : []
                              }
                              styles={customStyles}
                              isDisabled
                              value={
                                Array.isArray(landlordList) &&
                                landlordList.filter(
                                  (value) =>
                                    value.id ===
                                    (user.landlord_id
                                      ? user.landlord_id.id
                                      : user.id)
                                )
                              }
                            />
                          </div>
                        ) : (
                          <div class="col-md-12 mt-1 mb-3">
                            <label htmlFor="">
                              Name of Landlord:
                              <span class="tx-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => setLandlord(e.id)}
                              getOptionLabel={(option) => option.first_name}
                              getOptionValue={(option) => option.id}
                              isSearchable
                              options={
                                Array.isArray(landlordList) ? landlordList : []
                              }
                              styles={customStyles}
                              value={
                                Array.isArray(landlordList) &&
                                landlordList.find(
                                  (value) => value.id === landlord
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      disabled={loading}
                      class="btn ripple btn-main-primary btn-block">
                      {loading ? "Creating Property....." : "Create Property"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
};

export default NewProperty;
