import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchPropertyErrorLogs() {
    let response = await apiCall("logs/property");
    return response;
  },
  async fetchTenantUnitsErrorLogs() {
    let response = await apiCall("logs/tenant_units");
    return response;
  },
  async fetchTenantInvoicingLogs() {
    let response = await apiCall("accountabillity/get_failed/invoicing");
    return response;
  },
  async recreateInvoiceOfRent() {
    let response = await apiCall("accountabillity/tenatInvoice/recreate");
    return response;
  },
  async reCreatePropertyFromLog() {
    let response = await apiCall("logs/property/recreate");
    return response;
  },
  async reCreateTenantUnitFromLog() {
    let response = await apiCall("logs/tenant_unit/recreate");
    return response;
  },
};
