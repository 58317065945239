import React, {useEffect, useState} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxCosting from "../util/remote/ajaxCosting";
import Select from "react-select";

function Costing() {
  const [steps, setSteps] = useState([]);
  const [material, setMaterial] = useState([]);
  const [activateMaterial, setActivateMaterial] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [activateEquipment, setActivateEquipment] = useState(false);

  useEffect(() => {
    getSteps();
    getMaterial();
    getEquipment(); // Fetch equipment data
  }, []);

  // ... (existing code)

  const getEquipment = async () => {
    const server_response = await ajaxCosting.fetchEquipmentList();
    if (server_response.status === "OK") {
      setEquipment(server_response.details);
    }
  };
  const getSteps = async () => {
    const server_response = await ajaxCosting.fetchStepsList();
    if (server_response.status === "OK") {
      setSteps(server_response.details);
    }
  };

  const getMaterial = async () => {
    const server_response = await ajaxCosting.fetchMaterialList();
    // console.log(server_response);
    if (server_response.status === "OK") {
      setMaterial(server_response.details);
    }
  };

  // Initialize the stepStates array based on the number of steps
  const stepStatesInitial = steps.map(() => ({
    materialValue: [],
    prices: [],
    quantity: [],
    subtotals: [],
    equipmentValue: [],
    equipmentPrices: [],
    equipmentQuantity: [],
    equipmentSubtotals: [],
  }));

  // Create a state variable for stepStates
  const [stepStates, setStepStates] = useState(stepStatesInitial);

  const handleEquipmentSelect = (selectedOption, stepIndex) => {
    const newStepStates = [...stepStates];
    newStepStates[stepIndex].equipmentValue = selectedOption;
    setStepStates(newStepStates);
  };

  const handleEquipmentPriceChange = (index, value, stepIndex) => {
    const newStepStates = [...stepStates];
    newStepStates[stepIndex].equipmentPrices[index] = value;
    setStepStates(newStepStates);
  };

  const handleEquipmentQuantityChange = (index, value, stepIndex) => {
    const newStepStates = [...stepStates];
    newStepStates[stepIndex].equipmentQuantity[index] = value;
    setStepStates(newStepStates);
  };

  // Handle material selection, price change, and quantity change within each step
  const handleMaterialSelect = (selectedOption, stepIndex) => {
    const newStepStates = [...stepStates];

    // Initialize newStepStates[stepIndex] if it's undefined
    if (!newStepStates[stepIndex]) {
      newStepStates[stepIndex] = {
        materialValue: false,
        prices: [],
        quantity: [],
        subtotals: [],
      };
    }

    newStepStates[stepIndex].materialValue = selectedOption;
    setStepStates(newStepStates);
  };

  const handlePriceChange = (index, value, stepIndex) => {
    const newStepStates = [...stepStates];

    // Initialize newStepStates[stepIndex] if it's undefined
    if (!newStepStates[stepIndex]) {
      newStepStates[stepIndex] = {
        materialValue: false,
        prices: [],
        quantity: [],
        subtotals: [],
      };
    }

    newStepStates[stepIndex].prices[index] = value;
    setStepStates(newStepStates);

    // Calculate and update the subtotal for this row
    const subtotal =
      value * (newStepStates[stepIndex].quantity[index] || 0) || 0;
    newStepStates[stepIndex].subtotals[index] = subtotal;
    setStepStates(newStepStates);
  };

  const handleQuantityChange = (index, value, stepIndex) => {
    const newStepStates = [...stepStates];

    // Initialize newStepStates[stepIndex] if it's undefined
    if (!newStepStates[stepIndex]) {
      newStepStates[stepIndex] = {
        materialValue: false,
        prices: [],
        quantity: [],
        subtotals: [],
      };
    }

    newStepStates[stepIndex].quantity[index] = value;
    setStepStates(newStepStates);

    // Calculate and update the subtotal for this row
    const subtotal = value * (newStepStates[stepIndex].prices[index] || 0) || 0;
    newStepStates[stepIndex].subtotals[index] = subtotal;
    setStepStates(newStepStates);
  };

  const equipmentStepSubtotals = stepStates.map((stepState) => {
    const {equipmentPrices, equipmentQuantity} = stepState;
    return equipmentPrices.map(
      (price, index) => price * equipmentQuantity[index] || 0
    );
  });

  // // Calculate equipment totals for each step
  const stepEquipmentTotals = equipmentStepSubtotals.map((subtotals) =>
    subtotals.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
  );

  // Create a state variable for stepStates
  // Calculate the total for each step
  const stepTotals = stepStates.map((stepState) =>
    stepState.subtotals.reduce(
      (accumulator, currentValue) => accumulator * 1 + currentValue * 1,
      0
    )
  );

  //   const stepEquipmentTotals = stepStates.map((stepState) =>
  //   stepState.equipmentSubtotals.reduce((accumulator, currentValue) => (accumulator * 1) + (currentValue * 1), 0)
  // );

  return (
    <AppContainer title="Costing">
      <div className="row">
        <div className="col-md-9">
          <div className="card custom-card">
            {Array.isArray(steps) &&
              steps.map((item, stepIndex) => (
                <>
                  <h4 className="card-header">{item.name}</h4>
                  <div
                    className="card-body"
                    style={{borderBottom: "1px solid red"}}>
                    <Select
                      onChange={(selectedOption) =>
                        handleMaterialSelect(selectedOption, stepIndex)
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      options={material}
                      value={
                        Array.isArray(material) &&
                        material.find((value) => value.id === material)
                      }
                      isMulti
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <table className="table table-bordered table-hover mg-b-0 mt-4">
                      {stepStates[stepIndex] &&
                      stepStates[stepIndex].materialValue ? (
                        <thead>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Subtotal</th>
                        </thead>
                      ) : (
                        ""
                      )}
                      <tbody>
                        {stepStates[stepIndex] &&
                        stepStates[stepIndex].materialValue
                          ? stepStates[stepIndex].materialValue.map(
                              (item, index) => (
                                <tr key={index}>
                                  <th>{item.name}</th>
                                  <td>
                                    <input
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          index,
                                          e.target.value,
                                          stepIndex
                                        )
                                      }
                                      placeholder="Quantity"
                                      type="number"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) =>
                                        handlePriceChange(
                                          index,
                                          e.target.value,
                                          stepIndex
                                        )
                                      }
                                      placeholder="Price"
                                      type="number"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    {stepStates[stepIndex].subtotals[index]
                                      ? `UGX ${stepStates[stepIndex].subtotals[index]}`
                                      : "UGX 0"}
                                  </td>
                                </tr>
                              )
                            )
                          : ""}
                        <tr>
                          <th>
                            <b></b>
                          </th>
                          <td></td>
                          <td>Total</td>
                          <td>UGX {stepTotals[stepIndex]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h4 className="card-header">{item.name}</h4>
                  <div
                    className="card-body"
                    style={{borderBottom: "1px solid red"}}>
                    {/* Equipment selection */}
                    <Select
                      onChange={(selectedOption) =>
                        handleEquipmentSelect(selectedOption, stepIndex)
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      options={equipment}
                      isMulti
                      name="equipment"
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />

                    {/* Equipment table */}
                    <table className="table table-bordered table-hover mg-b-0 mt-4">
                      {stepStates[stepIndex] &&
                      stepStates[stepIndex].equipmentValue ? (
                        <thead>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Subtotal</th>
                        </thead>
                      ) : (
                        ""
                      )}
                      <tbody>
                        {stepStates[stepIndex] &&
                        stepStates[stepIndex].equipmentValue
                          ? stepStates[stepIndex].equipmentValue.map(
                              (item, index) => (
                                <tr key={index}>
                                  <th>{item.name}</th>
                                  <td>
                                    <input
                                      onChange={(e) =>
                                        handleEquipmentQuantityChange(
                                          index,
                                          e.target.value,
                                          stepIndex
                                        )
                                      }
                                      placeholder="Quantity"
                                      type="number"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) =>
                                        handleEquipmentPriceChange(
                                          index,
                                          e.target.value,
                                          stepIndex
                                        )
                                      }
                                      placeholder="Price"
                                      type="number"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    {stepStates[stepIndex].equipmentSubtotals[
                                      index
                                    ]
                                      ? `UGX ${stepStates[stepIndex].equipmentSubtotals[index]}`
                                      : "UGX 0"}
                                  </td>
                                </tr>
                              )
                            )
                          : ""}
                        <tr>
                          <th>
                            <b></b>
                          </th>
                          <td></td>
                          <td>Total</td>
                          <td>UGX {stepEquipmentTotals[stepIndex]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Costing;
