import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import TenantContext from "../../Context/TenantContext";
import CreateTenant from "../../Components/Tenants/CreateTenant";
import {Link, NavLink, Outlet} from "react-router-dom";
import ajaxTenant from "../../util/remote/ajaxTenant";
import Loader from "../../Components/Common/Loader";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";

export default function TenantsPage() {
  // const { tenantLists, meta,page,setMeta,setPage } = useContext(TenantContext);
  const [loading, setLoading] = useState(true);
  const [tenantLists, setTenantList] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const id = functions.sessionGuard();
  useEffect(() => {
    getTenants();
  }, [page]);

  const getTenants = async () => {
    let data = {
      user_id: id,
      page: page,
    };
    const server_response = await ajaxTenant.fetchTenantList(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setTenantList(server_response.details.list);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  return (
    <>
      <AppContainer title="Tenants">
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-3">
            <Link
              to={`/tenants/create-new`}
              style={{float: "right", paddingLeft: "20px"}}>
              <button className="btn ripple btn-primary btn-with-icon btn-sm">
                <i className="fe fe-plus"></i> Add New Tenant
              </button>
            </Link>
          </div>
          <div class="col-lg-12 col-md-12 ">
            <div class="row">
              {!tenantLists && <h4>No tenants here yet</h4>}
              {Array.isArray(tenantLists) &&
                tenantLists.map((item, key) => (
                  <div className="col-md-3">
                    <div
                      className="card custom-card"
                      style={{
                        height: "19rem",
                        borderTopRightRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Example shadow values
                      }}>
                      <h5
                        style={{float: "right !important"}}
                        className="card-title p-2">
                        {item.status * 1 === 1 ? (
                          <span className="badge badge-pill badge-success ">
                            Active
                          </span>
                        ) : (
                          <span className="badge badge-pill badge-success ">
                            Inactive
                          </span>
                        )}
                      </h5>
                      <div className="card-body justify-content-center text-center align-items-center align-content-center">
                        {/* <div class="align-items-start justify-content-center text-center align-items-center align-content-center main-img-user avatar-xxl d-none d-sm-block"> */}
                        <img
                          alt="avatar"
                          class="avatar-xxl rounded-circle"
                          src={dictionary.apiHost + item.picture}
                        />
                        {/* </div> */}
                        <div className="text-center mt-1">
                          <h5>{item.name}</h5>
                          <p className="tx tx-gray-500">{item.phone_number}</p>

                          <Link
                            className="btn btn-outline-success w-100"
                            to={`/tenants/tenant-profile/${item.id}`}>
                            View Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="pos-absolute " style={{left: "50%"}}>
              <button
                className="btn btn-dark"
                style={{borderRight: "1px solid yellow"}}
                onClick={setPreviousPageNumber}>
                <i className="fa fa-angle-left mr-2"></i> Prev
              </button>
              {Array.isArray(meta) &&
                meta.map((item) =>
                  page === item ? (
                    <button
                      style={{borderRight: "1px solid yellow"}}
                      className="btn btn-primary">
                      {item}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => setPageNumber(e, item)}
                      style={{borderRight: "1px solid yellow"}}
                      className="btn btn-dark">
                      {item}
                    </button>
                  )
                )}

              <button
                style={{borderRight: "1px solid yellow"}}
                className="btn btn-dark"
                onClick={setNextPageNumber}>
                Next<i className="fa fa-angle-right ml-2"></i>
              </button>
            </div>{" "}
          </div>
        </div>
      </AppContainer>
    </>
  );
}
