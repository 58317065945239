import React, {useContext, useEffect, useState} from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxTenant from "../../util/remote/ajaxTenant";
import ajaxUser from "../../util/remote/ajaxUser";
import Select from "react-select";
import Alert from "../Common/Alert";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";
import {Toaster, toast} from "react-hot-toast";

export default function CreateTenant(props) {
  const {user, userId} = useContext(AuthContext);

  useEffect(() => {
    setUserR(user);
    setLandlord_id(
      userR?.landlord_id
        ? userR.landlord_id?.id
        : userR.role?.role_name === "Landlord"
        ? userR.id
        : ""
    );
  }, [user]);
  const [userR, setUserR] = useState(user ? user : "");

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");

  const [name, setName] = useState("");
  const [DOB, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [nin, setNin] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [landlord_id, setLandlord_id] = useState(
    userR?.landlord_id
      ? userR.landlord_id?.id
      : userR.role?.role_name === "Landlord"
      ? userR.id
      : ""
  );
  const [Landlord_list, setLandlord_list] = useState("");
  const [picture, setPicture] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  const getLandLoards = async () => {
    const server_response = await ajaxUser.fetchLandlords();
    if (server_response.status === "OK") {
      //store results
      setLandlord_list(server_response.details);
    } else {
      //communicate error
      setLandlord_list("404");
    }
  };

  useEffect(() => {
    getLandLoards();
  }, []);

  const createNewTenant = async (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      DOB.length > 0 &&
      email.length > 0 &&
      nin.length > 0 &&
      picture.length > 0 &&
      phone_number.length > 0 &&
      landlord_id > 0
    ) {
      setLoading(true);
      const server_response = await ajaxTenant.createTenant(
        name,
        DOB,
        email,
        nin,
        phone_number,
        picture,
        landlord_id
      );
      if (server_response.status === "OK") {
        window.location.reload();
        setName("");
        setDOB("");
        setEmail("");
        setNin("");
        setPicture("");
        setPhone_number("");
        setLandlord_id("");
        setTimeout(
          () => setLoading(false),
          toast.success(server_response.message),

          1000
        );
      } else {
        setTimeout(
          () => setLoading(false),
          toast.error(server_response.message),

          1000
        );
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const changePicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setPicture(e.target.result);
      // console.log(image)
    };
  };

  return (
    <AppContainer title="Create New Tenant">
      <Toaster />
      <div class="col-lg-12 col-md-12">
        <div class="card custom-card">
          <div class="card-body">
            <div>
              <h6 class="card-title mb-4">Add New Tenant </h6>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="">
                  {loading && <Loader />}
                  <form onSubmit={createNewTenant}>
                    <div class="form-group">
                      <div class="row row-sm">
                        <div class="col-sm-6 mt-2 mb-2">
                          <label class="">Name</label>
                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            class="form-control"
                            required=""
                            type="text"
                          />
                        </div>
                        <div class="col-sm-6 mt-2 mb-2">
                          <label class="">Email Address</label>
                          <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            class="form-control"
                            required=""
                            type="email"
                          />
                        </div>

                        <div class="col-sm-6 mt-2 mb-2">
                          <label class="">Date of Birth</label>
                          <input
                            value={DOB}
                            onChange={(e) => setDOB(e.target.value)}
                            class="form-control"
                            id="dateMask"
                            required=""
                            type="date"
                          />
                        </div>

                        <div class="col-sm-6 mt-2 mb-2">
                          <label class="">NIN</label>
                          <input
                            value={nin}
                            onChange={(e) => setNin(e.target.value)}
                            class="form-control"
                            required=""
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row row-sm">
                        <div class="col-sm-6">
                          <label class="">Phone Number</label>
                          <input
                            value={phone_number}
                            id="phone_number"
                            onChange={(e) => setPhone_number(e.target.value)}
                            required
                            class="form-control"
                            type="tel"
                          />
                          <span class="validity"></span>
                        </div>

                        <div class="col-sm-6">
                          <label>Upload Tenant image</label>
                          <div class="custom-file">
                            <input
                              onChange={changePicture}
                              class="custom-file-input"
                              //className="form-control"
                              type="file"
                              accept="image/*"
                            />
                            <label class="custom-file-label" for="customFile">
                              Choose file
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row row-sm">
                        {userR.role?.role_name === "Manager" ? (
                          <div class="col-sm-12">
                            <label htmlFor="">Landlord</label>

                            <Select
                              isDisabled
                              defaultValue={"empty"}
                              onChange={(e) => setLandlord_id(e.id)}
                              getOptionLabel={(option) => option.first_name}
                              getOptionValue={(option) => option.id}
                              isSearchable
                              options={
                                Array.isArray(Landlord_list)
                                  ? Landlord_list
                                  : []
                              }
                              value={
                                Array.isArray(Landlord_list) &&
                                Landlord_list.find(
                                  (value) => value.id === userR.landlord_id.id
                                )
                              }
                              styles={customStyles}
                            />
                          </div>
                        ) : (
                          <div class="col-sm-12">
                            <label htmlFor="">Landlord</label>
                            <Select
                              onChange={(e) => setLandlord_id(e.id)}
                              getOptionLabel={(option) => option.first_name}
                              getOptionValue={(option) => option.id}
                              isSearchable
                              options={Landlord_list}
                              value={
                                Array.isArray(Landlord_list) &&
                                Landlord_list.find(
                                  (value) => value.id === landlord_id
                                )
                              }
                              styles={customStyles}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <button class="btn ripple btn-main-primary btn-block">
                      Register Tenant
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
