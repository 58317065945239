import React, {useEffect, useState} from "react";
import ajaxProperty from "../util/remote/ajaxProperty";
import {data} from "jquery";
import functions from "../util/functions";

const PropertyContext = React.createContext();

export const PropertyConsumer = PropertyContext.Consumer;

export const PropertyProvider = (props) => {
  const [propertyList, setPropertyList] = useState(false);
  const [data, setData] = useState({page: "1"});

  useEffect(() => {
    getPropertyList();
  }, [data]);

  const getPropertyList = async () => {
    const server_response = await ajaxProperty.fetchPropertyList(
      functions.sessionGuard(),
      data
    );
    //   console.log(server_response)
    if (server_response.status === "OK") {
      //store results
      setPropertyList(server_response.details);
    } else {
      //communicate error
      setPropertyList("404");
    }
  };

  return (
    <PropertyContext.Provider
      value={{
        propertyList,
        setData,
        getPropertyList,
      }}>
      {props.children}
    </PropertyContext.Provider>
  );
};

export default PropertyContext;
