import React, {useEffect, useState} from "react";
import ajaxProperty from "../../util/remote/ajaxProperty";
import {Toaster, toast} from "react-hot-toast";
import Select from "react-select";
import ajaxUser from "../../util/remote/ajaxUser";
export default function UpdateProperty(props) {
  useEffect(() => {
    getLandlords();
  }, []);

  const getLandlords = async () => {
    const server_response = await ajaxUser.fetchLandlords();
    if (server_response.status === "OK") {
      //store results
      setLandlordList(server_response.details);
    } else {
      //communicate error
      setLandlordList("404");
    }
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected ? "#A586E3" : "#252542",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#252542",
      padding: "0px",
      border: "1px solid #252542",
      boxShadow: "1px",
    }),
    singleValue: (defaultStyles) => ({...defaultStyles, color: "#fff"}),
  };

  const [propertyID, setPropertyID] = useState(props.propertyID);
  const [propertyName, setPropertyName] = useState(props.propertyName);
  const [propertyDescription, setPropertyDescription] = useState(
    props.propertyDescription
  );
  const [units_number, setUnitsNumber] = useState(props.units_number);
  const [sq_metres, setSqMetres] = useState(props.sq_metres);
  const [lat, setLat] = useState(props.lat);
  const [longitude, setLongitude] = useState(props.longitude);
  const [landlord, setLandlord] = useState(props.landlord);
  const [main_pic, setMainPic] = useState("");
  const [mainPicName, setMainPicName] = useState(
    "Choose main picture for Property..."
  );

  const [landlordList, setLandlordList] = useState("");

  const [loading, setLoading] = useState(true);
  const handlePropertyUpdate = async (e) => {
    e.preventDefault();

    if (
      propertyID > 0 ||
      propertyName.length > 0 ||
      propertyDescription.length > 0 ||
      units_number > 0 ||
      sq_metres > 0 ||
      landlord > 0
    ) {
      const server_response = await ajaxProperty.updateProperty(
        propertyID,
        propertyName,
        propertyDescription,
        units_number,
        sq_metres,
        lat,
        longitude,
        landlord,
        main_pic
      );

      if (server_response.status === "OK") {
        toast.success(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const changeImage = (e) => {
    e.preventDefault();
    setMainPicName(e.target.value);
    let files = e.target.files;
    let reader = new FileReader();
    console.log(files.length);
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = {file: e.target.result};

      setMainPic(e.target.result);
    };
  };

  return (
    <form action="" onSubmit={(e) => handlePropertyUpdate(e)} method="post">
      <div className="row">
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Property Name:<span class="tx-danger">*</span>
          </label>
          <input
            type="text"
            value={propertyName}
            onChange={(e) => setPropertyName(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Property Description:<span class="tx-danger">*</span>
          </label>
          <textarea
            class="form-control"
            defaultValue={propertyDescription}
            onChange={(e) => setPropertyDescription(e.target.value)}
            aria-label="With textarea"></textarea>
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Number of Units:<span class="tx-danger">*</span>
          </label>
          <input
            type="number"
            defaultValue={units_number}
            min={0}
            onChange={(e) => setUnitsNumber(e.target.value)}
            className="form-control"></input>
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Square Metres:<span class="tx-danger">*</span>
          </label>
          <input
            type="text"
            defaultValue={sq_metres}
            onChange={(e) => setSqMetres(e.target.value)}
            className="form-control"></input>
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">Latitude:</label>
          <input
            type="text"
            defaultValue={lat}
            onChange={(e) => setLat(e.target.value)}
            className="form-control"
            readOnly
          />
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">Longitude:</label>
          <input
            type="text"
            defaultValue={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            className="form-control"
            readOnly
          />
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Main Picture:<span class="tx-danger">*</span>
          </label>
          <div class="custom-file">
            <input
              class="custom-file-input"
              onChange={(e) => changeImage(e)}
              id="customFile"
              type="file"
            />{" "}
            <label class="custom-file-label" for="customFile">
              {mainPicName}
            </label>
          </div>
        </div>
        <div className="mb-3 col-md-6">
          <label htmlFor="">
            Name of Landlord:<span class="tx-danger">*</span>
          </label>
          <Select
            isDisabled
            onChange={(e) => setLandlord(e.id)}
            getOptionLabel={(option) => option.first_name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Array.isArray(landlordList) ? landlordList : []}
            value={
              Array.isArray(landlordList) &&
              landlordList.find((value) => value.id === landlord)
            }
            styles={customStyles}
          />
        </div>
        <div className="mb-3 col-md-12">
          {props.close}
          <button
            className="btn btn-success mr-3"
            style={{float: "right"}}
            type="submit">
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
}
