import React, {useEffect} from "react";
import ajaxTenantUnit from "../../util/remote/ajaxTenantUnit";
import CreateTenantUnit from "../Tenants/CreateTenantUnit";
import {Link} from "react-router-dom";
import UpdateTenancy from "../Tenants/UpdateTenancy";
import useStateCallback from "../../util/customHooks/useStateCallback";
import dictionary from "../../util/dictionary";

export default function TenantHistory(props) {
  const [modal, setModal] = useStateCallback(false);

  // {console.log(props.unit_id)}
  const handleModalUpdateTenancy = (item) => {
    setModal(false, () =>
      setModal(
        <UpdateTenancy
          getUnitHistory={props.getUnitHistory}
          unit_id={props.unit_id}
          name={item.tenant_id.name}
          id={item.id}
          unit={item.unit_id.name}
          isOpen={true}
        />
      )
    );
  };

  return (
    <>
      {modal}
      <div class="card custom-card" style={{height: "24rem"}}>
        <div className="card-header">
          Tenant History
          {props.unitProfile.status * 1 === 0 ? (
            <CreateTenantUnit
              color="btn btn-info btn-sm"
              fetchUnitProfile={props.fetchUnitProfile}
              getUnitHistory={props.getUnitHistory}
              unit_id={props.unit_id}
              unit={props.unit}
            />
          ) : (
            ""
          )}
          {/* {Array.isArray(props.pastTenants) &&
            props.pastTenants.map((item, key) => item.status === "1" && "")}
          {Array.isArray(props.pastTenants) &&
            props.pastTenants.map(
              (item, key) =>
                item.status === "0" && (
                  <CreateTenantUnit
                    unit_id={props.unit_id}
                    color="btn btn-primary"
                  />
                )
            )} */}
        </div>
        <div class="card-body" style={{overflowY: "auto"}}>
          <div class="card-block">
            {Array.isArray(props.pastTenants) &&
              props.pastTenants.map(
                (item, key) =>
                  item.status === "1" && (
                    <div class="row align-items-center justify-content-center">
                      <div class="col-auto user-lock">
                        <img
                          class="task-icon img-fluid rounded-circle"
                          src={dictionary.apiHost + item.tenant_id.picture}
                          alt="avatar"
                        />
                      </div>

                      <div class="col">
                        <Link
                          to={`/tenants/tenant-profile/${item.tenant_id.id}`}>
                          <h6 className="mb-1">{item.tenant_id.name}</h6>

                          <span>{item.tenant_id.phone_number} </span>
                          <br />
                          <span className="badge badge-sm badge-pill badge-info">
                            Current Occupant
                          </span>
                          <br />
                        </Link>
                        <button
                          onClick={() => handleModalUpdateTenancy(item)}
                          className="btn btn-sm btn-danger mt-2">
                          Vacate Tenant
                          <i
                            className="fe fe-user-x ml-1"
                            style={{color: "black", fontSize: "16px"}}></i>
                        </button>
                      </div>
                    </div>
                  )
              )}

            <ul class="task-list">
              {Array.isArray(props.pastTenants) &&
                props.pastTenants.map(
                  (item, key) =>
                    item.status != "1" && (
                      <>
                        <li>
                          <Link
                            to={`/tenants/tenant-profile/${item.tenant_id.id}`}>
                            {/* <div class="col-auto user-lock old-tenants"> */}
                            <img
                              class="task-icon img-fluid rounded-circle"
                              src={dictionary.apiHost + item.tenant_id.picture}
                              alt="avatar"
                            />
                            {/* </div> */}
                            <h6>
                              {item.tenant_id.name}
                              <span class=" text-muted fs-11">
                                <br />
                                From {item.created_at.short_date} to{" "}
                                {item.updated_at.short_date}
                              </span>
                            </h6>
                            <p class="text-muted fs-12">
                              {"+" + item.tenant_id.phone_number}
                            </p>
                          </Link>
                        </li>
                      </>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
