import React from 'react';
import {Watch, ThreeDots, Bars, Hearts, Rings, Audio} from 'react-loader-spinner';

class ActivityLoader extends React.Component {


  render(){

    const height=this.props.height || 50
    const width=this.props.width || 80
    const color=this.props.color || "#FFFFFF"
    const customClass=this.props.customClass || " col-12 text-center "
      return (
        <span className={customClass}>
             <ThreeDots
                color={color}
                height={height}
                width={width}
            />
        </span>

      );


  }
}

export default ActivityLoader