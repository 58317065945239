import React, {useEffect, useState} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import {Link, useParams} from "react-router-dom";
import ajaxProperty from "../util/remote/ajaxProperty";
import ajaxUnit from "../util/remote/ajaxUnit";
import {Toaster, toast} from "react-hot-toast";
import Select from "react-select";
import CreatePropertyImage from "../Components/Property/CreatePropertyImage";
import ajaxUser from "../util/remote/ajaxUser";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import ImageSlider from "../Components/Common/ImageSlider";
import AddUnit from "../Components/Units/AddUnit";
import UpdateProperty from "../Components/Property/UpdateProperty";
import Maps from "./Maps";
import PropertyImageSlider from "../Components/Property/PropertyImageSlider";
import UnitCard from "../Components/Units/UnitCard";
import NewPropertyManager from "../Components/Property/NewPropertyManager";
import useStateCallBack from "../util/customHooks/useStateCallback";
import CreateAmenity from "../Components/Property/CreateAmenity";
import useStateCallback from "../util/customHooks/useStateCallback";
import RecentUnits from "../Components/Units/RecentUnits";
import dictionary from "../util/dictionary";
import UpdatePropertyManger from "../Components/Property/UpdatePropertyManger";

export default function PropertyProfile() {
  const {id} = useParams();
  const [propertyProfile, setPropertyProfile] = useState(false);

  const [propertyImages, setPropertyImages] = useState(false);

  const [propertyID, setPropertyID] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [propertyDescription, setPropertyDescription] = useState("");
  const [units_number, setUnitsNumber] = useState("");
  const [sq_metres, setSqMetres] = useState("");
  const [lat, setLat] = useState("");
  const [longitude, setLongitude] = useState("");
  const [landlord, setLandlord] = useState("");
  const [main_pic, setMainPic] = useState("");
  const [mainPicName, setMainPicName] = useState(
    "Choose main picture for Property..."
  );

  const [modal, setModal] = useStateCallBack(false);

  const [loading, setLoading] = useState(true);

  const propertyid = id;
  const [active, setActive] = useState(false);
  const handleClose1 = () => setActive(false);
  const handleShow1 = () => setActive(true);
  const [modalAmenity, setModalAmenity] = useStateCallback(false);
  const [listAmenity, setListAmenity] = useState(false);

  useEffect(() => {
    getPropertyProfile(id);
    getPropertyImage(id);
  }, []);

  const [modalImage, setModalImage] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const toggleModal = (e, item) => {
    setModalShow(true);
    setModalImage(dictionary.apiHost + item.photo);
  };

  const setPropertyUpdate = (item) => {
    handleShow1();

    setPropertyID(propertyProfile.id);
    setPropertyName(propertyProfile.name);
    setPropertyDescription(propertyProfile.description);
    setUnitsNumber(propertyProfile.units_number);
    setSqMetres(propertyProfile.sq_metres);
    setLat(propertyProfile.lat);
    setLongitude(propertyProfile.longitude);
    setLandlord(propertyProfile.landlord.id);
  };

  const getPropertyProfile = async (id) => {
    const server_response = await ajaxProperty.fetchPropertyProfile(id);
    if (server_response.status === "OK") {
      //store results
      setPropertyProfile(server_response.details);
    } else {
      //communicate error
      setPropertyProfile("404");
    }
  };

  const getPropertyImage = async (id) => {
    const server_response = await ajaxProperty.fetchPropertyImage(id);

    if (server_response.status === "OK") {
      //store results
      setPropertyImages(server_response.details);
    } else {
      //communicate error
      setPropertyImages("404");
    }
  };

  useEffect(() => {
    getAmenity();
  }, []);

  const getAmenity = async () => {
    const server_response = await ajaxProperty.fetchAmenity(id);
    if (server_response.status === "OK") {
      setListAmenity(server_response.details);
    }
  };

  const handleAddManager = (e, name) => {
    setModal(false, () =>
      setModal(<NewPropertyManager propertyDetails={name} isOpen={true} />)
    );
  };

  const [mangerModal, setMangerModal] = useStateCallBack(false);
  const handleManagerUpdate = (e, name) => {
    setMangerModal(false, () =>
      setMangerModal(
        <UpdatePropertyManger
          property_id={id}
          propertyDetails={name}
          isOpen={true}
        />
      )
    );
  };
  const amenityModal = () => {
    setModalAmenity(false, () =>
      setModalAmenity(<CreateAmenity property_id={id} isOpen={true} />)
    );
  };

  return (
    <AppContainer title="Property Profile">
      {modal}
      {modalAmenity}
      {mangerModal}
      {loading ? <Toaster position="top-center" reverseOrder={false} /> : ""}
      <div class="row">
        <div className="col-md-7">
          <div className="card custom-card shadow">
            <div
              className="jumbotron mt-2 ml-2 mr-2"
              style={{
                backgroundImage: `url(${
                  dictionary.apiHost + propertyProfile.main_pic
                })`,
                backgroundPosition: "center",
                height: "300px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}></div>

            <div className="card-body">
              {active ? (
                <UpdateProperty
                  // getPropertyProfile={getPropertyProfile(id)}
                  propertyID={propertyID}
                  propertyName={propertyName}
                  propertyDescription={propertyDescription}
                  units_number={units_number}
                  sq_metres={sq_metres}
                  lat={lat}
                  longitude={longitude}
                  landlord={landlord}
                  close={
                    <button
                      className="btn btn-secondary btn-md"
                      style={{float: "right"}}
                      onClick={handleClose1}>
                      Close
                    </button>
                  }
                />
              ) : (
                propertyProfile && (
                  <>
                    <p
                      style={{
                        bottom: "20%",
                        left: "20%",
                        fontWeight: "900",
                        fontSize: "15px",
                      }}>
                      <b>
                        <strong>
                          <i className="fa fa-hotel mr-1">
                            {" "}
                            {propertyProfile.name}
                          </i>{" "}
                        </strong>
                      </b>
                      <br />
                      <button
                        style={{float: "right"}}
                        class="btn ripple btn-outline-warning btn-rounded"
                        onClick={setPropertyUpdate}>
                        Edit <i className="fa fa-pen"></i>
                      </button>
                    </p>
                    <p>
                      <i className="fe fe-map-pin mr-5">
                        {" "}
                        {propertyProfile.lat},{propertyProfile.longitude}{" "}
                      </i>{" "}
                    </p>

                    <h6>
                      <b>
                        <strong>Property Description </strong>
                      </b>
                    </h6>
                    <p style={{lineHeight: "23px", color: "#737B8B"}}>
                      {propertyProfile.description}
                    </p>
                    <p
                      style={{
                        bottom: "20%",
                        right: "50%",
                        weight: "900",
                        fontSize: "15px",
                      }}>
                      <b>
                        <strong>
                          <i className="fa fa-home mr-5">
                            {" "}
                            {propertyProfile.units_number} Units
                          </i>
                          <i className="fa fa-expand mr-5">
                            {" "}
                            {propertyProfile.sq_metres} Square Metres
                          </i>{" "}
                          <br />
                          <i className="fa fa-user mr-2 mt-4"> Managed by:</i>
                          {propertyProfile.managed_by === "Self-Managed" ? (
                            <span style={{color: "orange"}}>
                              {propertyProfile.managed_by}
                            </span>
                          ) : propertyProfile.manager ? (
                            <Link>
                              <span style={{color: "orange"}}>
                                {`${propertyProfile.manager.first_name} ${propertyProfile.manager.last_name}`}
                              </span>
                            </Link>
                          ) : (
                            <button
                              className="btn btn-sm btn-success btn-outline-success"
                              onClick={(e) =>
                                handleAddManager(e, propertyProfile.name)
                              }>
                              Add Manager
                            </button>
                          )}
                        </strong>
                      </b>
                    </p>
                  </>
                )
              )}
            </div>
          </div>

          <div className="card custom-card">
            <b className="ml-3 mt-2" style={{fontSize: "16px"}}>
              Property Location
            </b>
            <div className="card-body">
              <Maps />
            </div>
          </div>

          <div className="card custom-card shadow">
            <b className="ml-3 mt-2 mb-3" style={{fontSize: "16px"}}>
              Property Gallery{" "}
              <CreatePropertyImage
                // func={getPropertyImage(id)}
                color="btn-primary btn-sm"
                property_id={propertyid}
              />
            </b>
            <PropertyImageSlider id={id} />
            <div className="card-body"></div>
          </div>
          <RecentUnits id={id} />
          <div className="card custom-card ">
            <b className="ml-3 mt-2" style={{fontSize: "16px"}}>
              Property Features
              <span>
                <button
                  className="btn btn-primary btn-sm mr-2"
                  onClick={amenityModal}
                  style={{float: "right"}}>
                  <i className="fa fa-plus"></i> Add feature
                </button>
              </span>
            </b>
            <div className="card-body">
              {!listAmenity && <p>No amenities for this property</p>}
              {Array.isArray(listAmenity) &&
                listAmenity.map((item) => (
                  <p>
                    <i className="fa fa-check-circle"></i>
                    {item.amenity}
                  </p>
                ))}
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="card custom-card ">
            <div className="text-center">
              <img
                alt="avatar"
                className="rounded-circle text-center align-content-center align-items-center avatar-xxl mt-2"
                src={dictionary.apiHost + propertyProfile.landlord?.photo}
              />

              <p className="mt-1">
                <b>
                  {propertyProfile.landlord?.first_name +
                    " " +
                    propertyProfile.landlord?.last_name}
                </b>
                <br />
                <span className=" text-success">OWNER</span>
              </p>
              <a
                href={`tel:${propertyProfile.landlord?.phone_number}`}
                class="btn ripple btn-md w-50 btn-outline-success mb-2">
                <i class="fas fa-phone mr-1"></i>{" "}
                {propertyProfile.landlord?.phone_number}
              </a>
            </div>

            <div className="card-body">
              {/* <b className="ml-3 mt-2" style={{fontSize: "16px"}}>
                {" "}
                Owner History
              </b>

              <ul class="list-group wd-md-100p">
                <li class="list-group-item d-flex align-items-center">
                  <img
                    alt="avatar"
                    class="wd-30 rounded-circle mg-r-15"
                    src={dictionary.apiHost + "img/avatar.png"}
                  />
                  <div>
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                      Adrian Monino
                    </h6>
                    <span class="d-block tx-11 text-muted">0755323131</span>
                  </div>
                </li>
                <li class="list-group-item d-flex align-items-center">
                  <img
                    alt="avatar"
                    class="wd-30 rounded-circle mg-r-15"
                    src={dictionary.apiHost + "img/avatar.png"}
                  />
                  <div>
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                      Joel Mendez
                    </h6>
                    <span class="d-block tx-11 text-muted">0755323131</span>
                  </div>
                </li>
                <li class="list-group-item d-flex align-items-center">
                  <img
                    alt="avatar"
                    class="wd-30 rounded-circle mg-r-15"
                    src={dictionary.apiHost + "img/avatar.png"}
                  />
                  <div>
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                      Marianne Audrey
                    </h6>
                    <span class="d-block tx-11 text-muted">0755323131</span>
                  </div>
                </li>
              </ul> */}

              <h5 className="mt-4">Property Manager</h5>
              {propertyProfile.manager ? (
                <>
                  <li class="list-group-item d-flex align-items-center">
                    <img
                      alt="avatar"
                      style={{height: "40px", width: "40px"}}
                      class="wd-30 rounded-circle mg-r-15"
                      src={dictionary.apiHost + propertyProfile.manager.photo}
                    />
                    <div>
                      <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">
                        <Link to="">
                          <Link>
                            <span style={{color: "orange"}}>
                              {`${propertyProfile.manager.first_name} ${propertyProfile.manager.last_name}`}
                            </span>
                          </Link>
                        </Link>
                      </h6>
                      <span class="d-block tx-11 text-muted">
                        {propertyProfile.manager.phone_number}
                      </span>
                    </div>
                  </li>
                  {propertyProfile.managed_by !== "Self-Managed" && (
                    <button
                      className="btn btn-sm btn-outline-secondary mt-3"
                      onClick={handleManagerUpdate}>
                      Change Manager
                    </button>
                  )}
                </>
              ) : (
                <>
                  {propertyProfile.managed_by !== "Self-Managed" && (
                    <button
                      onClick={(e) => handleAddManager(e, propertyProfile)}
                      class="btn btn-sm ripple btn-outline-primary btn-rounded ">
                      Add Manager
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          <UnitCard id={id} />
        </div>
      </div>
      <ImageSlider id={id} />
    </AppContainer>
  );
}
