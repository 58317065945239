import React, {useEffect, useState} from "react";
import ajaxUnit from "../../util/remote/ajaxUnit";
import Card from "../Common/Card";
import functions from "../../util/functions";

export default function UnitsCard() {
  useEffect(() => {
    unitCount();
    occupancyUnit();
    vacantUnit();
    occupiedUnit();
  }, []);

  const [countUnits, setCountUnits] = useState(false);
  const [unitOccupancy, setUnitOccupancy] = useState(false);
  const [unitVacant, setUnitVacant] = useState(false);
  const [unitOccupied, setUnitOccupied] = useState(false);

  const unitCount = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxUnit.countUnit(data);
    if (server_response.status === "OK") {
      setCountUnits(server_response.details);
    }
  };

  const occupancyUnit = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxUnit.unitOccupancy(data);
    if (server_response.status === "OK") {
      setUnitOccupancy(server_response.details);
    }
  };

  const vacantUnit = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxUnit.unitVacant(data);
    if (server_response.status === "OK") {
      setUnitVacant(server_response.details);
    }
  };

  const occupiedUnit = async () => {
    var data = {
      user_id: functions.sessionGuard(),
    };
    const server_response = await ajaxUnit.unitOccupied(data);
    if (server_response.status === "OK") {
      setUnitOccupied(server_response.details);
    }
  };

  return (
    <>
      <div class="card custom-card">
        <div class="card-body">
          <div>
            <h6>Total Units</h6>

            <i
              style={{float: "right", fontSize: "xx-large", color: "#03C895"}}
              class="fa fa-home"></i>

            <h6>
              <span class="fs-30 mr-2">
                {countUnits ? countUnits.total_p : "..."}
              </span>
            </h6>
          </div>

          <>
            <label htmlFor="file">
              unitOccupancy ({unitOccupancy.total_p}%):
            </label>

            <progress id="file" value={unitOccupancy.total_p} max="100">
              {unitOccupancy.total_p}
            </progress>
          </>
        </div>
      </div>
    </>
  );
}
