import React, { useContext, useState } from 'react'
import AppContainer from '../../Components/Structure/AppContainer'
import RoleContext from '../../Context/RoleContext'
import PermissionContext from '../../Context/PermissionContext'

export default function Permissions() {

    const {permissionList,setPage,page,meta,setMeta} = useContext(PermissionContext) 
	
    

  
   

  //  console.log(meta.length)
      const setNextPageNumber = () =>{
        if(meta.length===page){
          
        }
        else{
          setPage(page+1)
        }
        
      }

      const setPreviousPageNumber = () =>{
        if(page===1){
          
        }
        else{
          setPage(page-1)
        }
        
      }
      const setPageNumber = (e,item) =>{
    
        setPage(item)
      }
      
   
  return (
    <AppContainer title="Permissions">
  <div class="col-lg-12">
							<div class="card custom-card">
								<div class="card-body">
									<div>
										<h6 class="card-title mb-1">Permission List</h6>
										{/* <p class="text-muted card-sub-title">To enable a hover state on table rows.</p> */}
									</div>
									<div class="table-responsive">
										<table class="table table-hover mg-b-0">
											<thead>
												<tr>
													<th>ID</th>
													<th>Permission Code</th>
													<th>Permission Description</th>
												</tr>
											</thead>
											<tbody>
                        {Array.isArray(permissionList) && permissionList.map((item,key)=>
                        <tr key={key}>
                        <th scope="row">{key+1}</th>
                        <td>{item.permission_code}</td>
         
                        <td>{item.permission_description}</td>
                      </tr>
                        )}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className='align-items-center justify-content-center pos-absolute' style={{left:'45%'}}>
      
      
	  <button className='btn btn-dark' style={{borderRight:'1px solid yellow'}} onClick={setPreviousPageNumber}><i className='fa fa-angle-left mr-2'></i> Prev</button>
			{Array.isArray(meta.list_of_pages) && meta.list_of_pages.map((item)=>
			page===item?
			<button  style={{borderRight:'1px solid yellow'}} className='btn btn-primary'>{item}</button>
			:
			<button onClick={(e)=>setPageNumber(e,item)} style={{borderRight:'1px solid yellow'}} className='btn btn-dark'>{item}</button>
			)}
  
  
					  <button style={{borderRight:'1px solid yellow'}} className='btn btn-dark mr-2' onClick={setNextPageNumber}>Next<i className='fa fa-angle-right ml-2'></i></button>
					Page {page} of {meta.total_pages}
	  </div>
    </AppContainer>
  )
}
