import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async fetchPropertyList(id, page) {
    let data = {
      landlord_id: id,
      page: page,
    };
    let response = await apiCall("property/list", data);
    return response;
  },
  async fetchAllLandloadProperties(data) {
    let response = await apiCall("property/list_all", data);
    return response;
  },

  async createProperty(
    name,
    description,
    units_number,
    sq_metres,
    lat,
    longitude,
    landlord_id,
    main_pic,
    management
  ) {
    let data = {
      name: name,
      description: description,
      units_number: units_number,
      sq_metres: sq_metres,
      lat: lat,
      longitude: longitude,
      landlord_id: landlord_id,
      main_pic: main_pic,
      managed_by: management,
    };

    let response = await apiCall("property/create", data);
    return response;
  },
  async updateProperty(
    property_id,
    name,
    description,
    units_number,
    sq_metres,
    lat,
    longitude,
    landlord_id,
    main_pic
  ) {
    let data = {
      property_id: property_id,
      name: name,
      description: description,
      units_number: units_number,
      sq_metres: sq_metres,
      lat: lat,
      longitude: longitude,
      landlord_id: landlord_id,
      main_pic: main_pic,
    };

    let response = await apiCall("property/update", data);
    return response;
  },
  async fetchPropertyProfile(property_id) {
    let data = {
      id: property_id,
    };
    let response = await apiCall("property/profile", data);

    return response;
  },
  async fetchPropertyNumber(id) {
    let data = {
      landlord_id: id,
    };

    let response = await apiCall("property/count", data);
    return response;
  },

  async fetchPropertyImage(property_id) {
    let data = {
      property_id: property_id,
    };
    let response = await apiCall("property/image/list", data);

    return response;
  },
  async uploadPropertyImage(property_id, photo, description) {
    let data = {
      property_id: property_id,
      photo: photo,
      description: description,
    };
    let response = await apiCall("property/image/create", data);

    return response;
  },
  async fetchPropertyManager(id) {
    let data = {
      property_id: id,
    };

    let response = await apiCall("property/manager/list", data);
    return response;
  },

  async createPropertyAmenity(property_id, amenity) {
    let data = {
      property_id: property_id,
      amenity: amenity,
    };
    let response = await apiCall("property/amenity/create", data);
    return response;
  },
  async fetchAmenity(property_id) {
    let data = {
      property_id: property_id,
    };
    let response = await apiCall("property/amenity/list", data);
    return response;
  },
};
