import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async loginUser(username, password) {
    let data = {
      username: username,
      password: password,
    };
    let response = await apiCall("login", data);
    return response;
  },
  //Creating
  async createUser(data) {
    let response = await apiCall("user/create", data);
    return response;
  },
  //UPDATES
  async updateUserProfile(
    user_id,
    username,
    first_name,
    last_name,
    email,
    age,
    phone_number
  ) {
    let data = {
      user_id: user_id,
      username: username,
      first_name: first_name,
      last_name: last_name,
      email: email,
      age: age,
      phone_number: phone_number,
    };
    let response = await apiCall("user/update/profile", data);
    return response;
  },
  async updateUserPassword(user_id, password) {
    let data = {
      user_id: user_id,
      password: password,
    };
    let response = await apiCall("user/update/password", data);
    return response;
  },
  async updateUserImage(user_id, photo) {
    let data = {
      user_id: user_id,
      photo: photo,
    };
    let response = await apiCall("user/update/image", data);
    return response;
  },

  //FETCHING/LISTING
  async fetchSingleUser(id) {
    let data = {
      user_id: id,
    };
    let response = await apiCall("user/list/single", data);

    return response;
  },
  async fetchUserList(page) {
    let data = {
      page: page,
    };
    let response = await apiCall("user/list", data);
    return response;
  },
  async fetchLandlords(data) {
    let response = await apiCall("landlords/list", data);

    return response;
  },
  async fetchManagers(landlord_id) {
    let data = {
      landlord_id: landlord_id,
    };
    let response = await apiCall("managers/list", data);

    return response;
  },
  async fetchUserNumber(data) {
    let response = await apiCall("user/count", data);
    return response;
  },

  async getUserPermissionCodes(userId) {
    let data = {
      user_id: userId,
    };

    let response = await apiCall("user/permission/codes", data);
    return response;
  },

  async search(query, landlordId) {
    let data = {
      query: query,
      landlord_id: landlordId,
    };

    let response = await apiCall("tenant/search", data);
    return response;
  },
  async countLandlord(data) {
    let response = await apiCall("user/count/landlord", data);
    return response;
  },
  async countManager(landlord_id) {
    let data = {
      landlord_id: landlord_id,
    };

    let response = await apiCall("user/count/manager", data);
    return response;
  },
};
