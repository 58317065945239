import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {RenderSecure} from "../../util/script/RenderSecure";
import AuthContext from "../../Context/AuthContext";
import CreateAnything from "../CreateAnything/CreateAnything";

export default function Sidebar() {
  const toggleDropdown = (e) => {
    e.preventDefault();
    if (e.target.parentElement.parentElement.classList.contains("show")) {
      e.target.parentElement.parentElement.classList.remove("show");
    } else {
      e.target.parentElement.parentElement.classList.add("show");
    }
  };

  const hoverSidebar = () => {
    const side = document.getElementsByClassName("side-menu");
    let body = document.getElementsByTagName("body");

    if (body[0].classList.contains("main-sidebar-hide")) {
      body[0].classList.add("main-sidebar-open");
    }
  };

  const unHoverSidebar = () => {
    const side = document.getElementsByClassName("side-menu");
    let body = document.getElementsByTagName("body");

    if (body[0].classList.contains("main-sidebar-hide")) {
      body[0].classList.remove("main-sidebar-open");
    }
  };

  return (
    <div
      class="main-sidebar main-sidebar-sticky side-menu"
      onMouseLeave={unHoverSidebar}
      onMouseOver={hoverSidebar}>
      <div class="sidemenu-logo">
        <Link class="main-logo" to="/">
          <img
            style={{height: "70px"}}
            src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
            class="header-brand-img desktop-logo"
            alt="logo image"
          />
          <img
            style={{height: "70px"}}
            src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
            class="header-brand-img icon-logo"
            alt="logo image"
          />
          <img
            style={{height: "70px"}}
            src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
            class="header-brand-img desktop-logo theme-logo"
            alt="logo image"
          />
          <img
            style={{height: "70px"}}
            src={process.env.PUBLIC_URL + "/template/assets/logo/logo.jpg"}
            class="header-brand-img icon-logo theme-logo"
            alt="logo image"
          />
        </Link>
      </div>
      <div class="main-sidebar-body">
        <ul
          class="nav"
          style={{justifyItems: "center", justifyContent: "center"}}>
          <li class="nav-item show">
            <Link class="nav-link" to="/">
              <i class="fe fe-airplay"></i>
              <span class="sidemenu-label">Dashboard</span>
            </Link>
          </li>
          <RenderSecure code="VIEW-LANDLORDS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fa fa-user-tie"></i>
                <span class="sidemenu-label">Landlords</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/landlord/create">
                    <span class="sidemenu-label">Add Landlord</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/landlords">
                    <span class="sidemenu-label">View Landlords</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-MANAGERS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fa fa-user"></i>
                <span class="sidemenu-label">Managers</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/manager/create">
                    <span class="sidemenu-label">Add Manager</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/managers">
                    <span class="sidemenu-label">View Managers</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-PROPERTY">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fa fa-hotel"></i>
                <span class="sidemenu-label">Properties</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/property/create">
                    <span class="sidemenu-label">Add Property</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/properties">
                    <span class="sidemenu-label">View Properties</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-UNITS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fa fa-home"></i>
                <span class="sidemenu-label">Units</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/unit/create">
                    <span class="sidemenu-label">Add Unit</span>
                  </Link>
                </li>
                {/* <li class="nav-item">
                  <Link class="nav-link" to="/unit/create/mult">
                    <span class="sidemenu-label">Add multi_Unit</span>
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link class="nav-link" to="/units/Occupied">
                    <span class="sidemenu-label">View Occupied Units</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/units/NotOccupied">
                    <span class="sidemenu-label"> UnOccupied Units</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/units">
                    <span class="sidemenu-label">View All Units</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>

          <RenderSecure code="VIEW-TENANTS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fe fe-users"></i>
                <span class="sidemenu-label">Tenants</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/tenants/create-new">
                    <span class="sidemenu-label">Add Tenant</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/tenants">
                    <span class="sidemenu-label">View Tenants</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-TENANTS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fa fa-hotel"></i>
                <span class="sidemenu-label">Tenants Unit</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/tenants/units/assign">
                    <span class="sidemenu-label">Assign Unit</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/tenants/units/view">
                    <span class="sidemenu-label">View Tenants units</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-PAYMENTS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i className="fa fa-chart-pie" />
                <span class="sidemenu-label">Payments Reports</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/payments/tentants/payments">
                    <span class="sidemenu-label">Tenants Who paid</span>
                  </Link>
                </li>
                {/* <li class="nav-item">
                  <Link class="nav-link" to="/unit/create/mult">
                    <span class="sidemenu-label">Add multi_Unit</span>
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link class="nav-link" to="/payments/tentants/debts">
                    <span class="sidemenu-label">Tenants With Debts </span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-USERS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i class="fe fe-user-plus"></i>
                <span class="sidemenu-label">Users</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/users">
                    <i class="fe fe-users"></i>
                    <span class="sidemenu-label">View Users</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/users/addUSer/new">
                    <i class="fe fe-users"></i>
                    <span class="sidemenu-label">Add Users</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/roles">
                    <i class="fe fe-settings"></i>
                    <span class="sidemenu-label">View Roles</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/permissions">
                    <i class="fe fe-lock"></i>
                    <span class="sidemenu-label">View Permissions</span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
          <RenderSecure code="VIEW-USERS">
            <li class="nav-item">
              <a onClick={toggleDropdown} class="nav-link with-sub" href="#">
                <i className="fas fa-bomb" />
                <span class="sidemenu-label">Error Logs</span>
                <i class="angle fe fe-chevron-right"></i>
              </a>
              <ul class="nav-sub">
                <li class="nav-item">
                  <Link class="nav-link" to="/logs/tenant_invicing">
                    <i class="fe fe-users"></i>
                    <span class="sidemenu-label">Tenant Invoicing Logs</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/logs/properties_logs">
                    <i class="fe fe-users"></i>
                    <span class="sidemenu-label">Propert creating Logs</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/logs/Tenant_Units_logs">
                    <i class="fe fe-settings"></i>
                    <span class="sidemenu-label">Tenant_Units Logs </span>
                  </Link>
                </li>
              </ul>
            </li>
          </RenderSecure>
        </ul>
      </div>
    </div>
  );
}
