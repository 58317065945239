import React, {useContext, useEffect, useState} from "react";
import Card from "../Common/Card";
import ajaxProperty from "../../util/remote/ajaxProperty";
import functions from "../../util/functions";

export default function PropertiesCard() {
  const [propertyNumber, setPropertyNumber] = useState(false);

  const getPropertyNumber = async () => {
    const server_response = await ajaxProperty.fetchPropertyNumber(
      functions.sessionGuard()
    );

    if (server_response.status === "OK") {
      //store results
      setPropertyNumber(server_response.details);
    } else {
      //communicate error
      setPropertyNumber("404");
    }
  };

  useEffect(() => {
    getPropertyNumber();
  }, []);

  return (
    <Card
      title="Total Properties"
      value={propertyNumber ? propertyNumber.total_p : "..."}
      icon="fa fa-hotel"
      color="#DF6A32"
      link="#"
    />
  );
}
