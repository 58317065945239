import React, {useEffect, useState} from "react";
import ajaxUnit from "../../util/remote/ajaxUnit";
import {Link} from "react-router-dom";
import AddUnit from "./AddUnit";
import {NumericFormat} from "react-number-format";
import CurrencyFormat from "react-currency-format";
import functions from "../../util/functions";
export default function UnitCard(props) {
  useEffect(() => {
    getPropertyUnits();
  }, []);
  const user = functions.sessionGuard();
  const property_id = props.id;

  const [propertyUnits, setPropertyUnits] = useState(false);
  const [unitImage, setUnitImage] = useState(false);
  const [ActiveUnit, setActiveUnit] = useState(true);
  const handleUnitShow = () => setActiveUnit(false);
  const handleUnitHide = () => setActiveUnit(true);
  // var CurrencyFormat = require('react-currency-format');

  const getUnitImage = async (id) => {
    const server_response = await ajaxUnit.fetchUnitImage(id);

    if (server_response.status === "OK") {
      //store results
      setUnitImage(server_response.details);
    } else {
      setUnitImage("404");
    }
  };

  const getPropertyUnits = async () => {
    let data = {
      user_id: user,
      property_id: property_id,
    };
    const server_response = await ajaxUnit.fetchAllPropperty_units(data);

    if (server_response.status === "OK") {
      //store results
      setPropertyUnits(server_response.details);
    } else {
      //communicate error
      setPropertyUnits("404");
    }
  };
  return (
    <>
      {ActiveUnit ? (
        <div class="card custom-card mb-4">
          <div class="card-body">
            {/* <div> */}
            <h6 class="card-title mb-3">
              List of Units
              <button
                className="btn btn-primary btn-sm"
                style={{float: "right"}}
                onClick={() => handleUnitShow()}>
                <i className="fa fa-plus"></i> Add new unit
              </button>
            </h6>
            {/* </div> */}
            <div class="user-manager scroll-widget border-top">
              <div class="table-responsive">
                <table style={{height: "12rem"}} class="table mg-b-0">
                  <tbody>
                    {Array.isArray(propertyUnits) &&
                      propertyUnits.map((item, key) => (
                        <tr style={{color: "yellow"}} id="units" key={key}>
                          <Link
                            to={`/properties/property-profile/${props.id}/unit-profile/${item.id}`}>
                            {/* <td class="bd-t-0">
                                                    <div class="main-img-user"><img alt="avatar" src="assets/img/users/2.jpg"/></div>
                                                </td> */}
                            <td class="bd-t-0">
                              <h6 class="mg-b-0">{item.name}</h6>
                              <small
                                class="tx-11 tx-gray-500"
                                style={{color: "#845EF8"}}>
                                <i
                                  className="fa fa-bed"
                                  style={{color: "#845EF8"}}></i>{" "}
                                {item.bedrooms_no} Bedroom(s)
                                {/* <i className='fa fa-user'></i> {item.bedrooms_no} Bedrooms */}
                              </small>
                            </td>
                            <td class="bd-t-0">
                              <h6 class="mg-b-0 font-weight-bold">
                                {item.status === "0" ? (
                                  <span class="badge badge-success">
                                    Vacant
                                  </span>
                                ) : (
                                  <span class="badge badge-danger">
                                    Occupied
                                  </span>
                                )}
                              </h6>
                              <small
                                style={{color: "#845EF8"}}
                                class="tx-11 tx-white-500">
                                <CurrencyFormat
                                  value={item.rent_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"UGX "}
                                  suffix={" /-"}
                                />
                              </small>
                            </td>
                          </Link>
                          <hr />
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="card custom-card p-2" style={{height: "30rem"}}>
          <div class="card-body">
            <AddUnit
              id={props.id}
              propertyUnits={getPropertyUnits}
              closeUnit={
                <button
                  style={{float: "right"}}
                  onClick={handleUnitHide}
                  class="btn ripple btn-secondary btn-sm">
                  Close<i class="fe fe-x"></i>
                </button>
              }
            />
          </div>
        </div>
      )}
    </>
  );
}
