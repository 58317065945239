import {useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextInput from "../Common/TextInput";
import SystemModal from "../Common/SystemModal";
import ActivityLoader from "../Common/ActivityLoader";
import {Toaster, toast} from "react-hot-toast";
import ajaxUnit from "../../util/remote/ajaxUnit";

function UploadImage(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitImage, setUnitImage] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onConfirm = async (e) => {
    e.preventDefault();
    if (props.id > 0 || unitImage.length > 0 || description.length > 0) {
      // console.log(props.id);
      const server_response = await ajaxUnit.uploadUnitImage(
        props.id,
        unitImage,
        description
      );
      // console.log(server_response);
      if (server_response.status === "OK") {
        setUnitImage("");
        setDescription("");
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete All Fields and Try Again!!");
    }
  };

  const changeImage = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      const newItem = {file: e.target.result};
      setUnitImage(e.target.result);
    };
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <ActivityLoader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={onConfirm}>
            Upload Image
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <SystemModal
        title="Upload new image"
        id="model-new-property"
        size="md"
        footer={RenderFooter}>
        <TextInput
          label="Unit Image"
          type="file"
          classname="form-control"
          onChange={(e) => changeImage(e)}
        />

        <TextInput
          label="Image Description"
          value={description}
          type="textarea"
          placeholder="Enter image description"
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* {RenderLocation()} */}
      </SystemModal>
    </>
  );
}

export default UploadImage;
