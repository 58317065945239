import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
   
    async fetchPermissionList(page) {
      let data ={
        'page':page
      }
        let response = await apiCall("permission/list", data)
        return response;
       
      }




}